import React from "react";
import { Container, Typography, Box, Button } from "@material-ui/core";
import Logo from "../icons/logo";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (window?.Sentry) {
      window.Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        if (window._browsee) {
          scope.setExtra("sessionUrl", window._browsee.getSessionUrl());
        }
      });
      window.Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Container maxWidth="lg">
          <Box
            bgcolor="common.white"
            borderRadius="1rem"
            padding={{ xs: 4, md: 8 }}
            marginTop={{ xs: 4, md: 8 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box width="150px" marginBottom={4}>
              <Logo />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginBottom={{ xs: 2, md: 4 }}
              textAlign="center"
            >
              <Typography variant="h5">
                Sorry, something went wrong there.
              </Typography>
            </Box>
            <Typography
              color="textSecondary"
              style={{ maxWidth: "635px", textAlign: "center" }}
            >
              {`We're working on it and we'll get it fixed as soon as we can. We
            keep track of these errors, but feel free to `}
              <a href="/us/en/support" style={{ color: "#C79600" }}>
                contact us
              </a>
              {` if refeshing
            doesn't fix things.`}
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column", md: "row" }}
              marginTop={6}
            >
              <Box width={{ xs: "100%", md: "250px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#fff"
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reload the page
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}
