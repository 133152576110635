import React, { useEffect, useState } from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { GoogleMapsAutocompletePlaceType } from "../../types/airgraft";

// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
const ONLY_COUNTRIES = ["us", "ca"];
const ONLY_PLACE_TYPE = "address";
const EXCLUDE_PLACE_TYPE = "route";

/**
 * Code copied & modified from the @material-ui/lab documentation example
 * https://material-ui.com/components/autocomplete/#google-maps-place
 */

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "24px !important"
    },
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "none !important"
    },
    "& .MuiAutocomplete-noOptions": {
      display: "none !important"
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      visibility: "visible !important"
    },
    '& input[type="search"]::-webkit-search-cancel-button': {
      "-webkit-appearance": "none",
      display: "none"
    }
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

type Props = {
  className?: string;
  label?: string;
  onChange: (
    event: React.ChangeEvent,
    value: GoogleMapsAutocompletePlaceType
  ) => void;
  error?: boolean;
  defaultValue?: Pick<
    GoogleMapsAutocompletePlaceType,
    "place_id" | "description"
  > &
    Partial<GoogleMapsAutocompletePlaceType>;
  disableClearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  TextFieldProps?: TextFieldProps;
  allowNonAddressPlaces?: boolean;
  PopperComponent?: any;
  noOptionsText?: string;
};

const GoogleMapsAutocompleteField = ({
  className,
  label,
  onChange,
  error,
  defaultValue,
  disableClearable,
  disabled,
  placeholder,
  TextFieldProps,
  allowNonAddressPlaces,
  PopperComponent,
  noOptionsText
}: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(
    defaultValue ? defaultValue.description : ""
  );
  const [options, setOptions] = useState<GoogleMapsAutocompletePlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const fetchAutocompletePredictions = React.useMemo(
    () =>
      throttle(
        (
          input: string,
          callback: (results?: GoogleMapsAutocompletePlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {
              input,
              types: !allowNonAddressPlaces ? [ONLY_PLACE_TYPE] : undefined,
              componentRestrictions: { country: ONLY_COUNTRIES }
            },
            callback
          );
        },
        200
      ),
    []
  );

  /**
   * Fetch autocomplete options when inputValue changes
   */
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue || inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetchAutocompletePredictions(
      inputValue,
      (results?: GoogleMapsAutocompletePlaceType[]) => {
        // Filter only "street_address" or "premise" places
        const filteredResults = (results || []).filter(
          r => !r.types.includes(EXCLUDE_PLACE_TYPE)
        );

        if (active) {
          setOptions(filteredResults);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetchAutocompletePredictions]);

  /**
   * Fill input value if parent changes defaultValue
   */
  useEffect(() => {
    setInputValue(defaultValue?.description || null);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <Autocomplete
        options={options}
        fullWidth
        autoComplete
        openOnFocus={false}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.description
        }
        getOptionSelected={(option, value) => {
          return option.place_id === value.place_id;
        }}
        filterOptions={x => x}
        popupIcon={false}
        onChange={onChange}
        noOptionsText={noOptionsText || "Enter your address"}
        defaultValue={defaultValue}
        disableClearable={disableClearable}
        PopperComponent={PopperComponent}
        renderInput={params => {
          return (
            <TextField
              {...params}
              className={className}
              label={label}
              fullWidth
              onChange={handleChange}
              error={error}
              variant="outlined"
              placeholder={placeholder}
              {...TextFieldProps}
              InputProps={{
                ...params.InputProps,
                type: "search",
                disabled,
                // @ts-ignore
                "data-testid": "googlemaps-autocomplete"
              }}
            />
          );
        }}
        renderOption={option => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length
            ])
          );
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
};

export default GoogleMapsAutocompleteField;
