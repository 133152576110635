import React from "react";

const UsaFlagIcon = ({ size }: { size?: number }) => (
  <svg
    width={size || 31}
    height={size || 31}
    viewBox={`0 0 31 31`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ borderRadius: `${size || 31}px`, overflow: "hidden" }}
  >
    <circle cx="15.5255" cy="15.6056" r="14.7606" fill="white" />
    <mask
      id="mask0_15362_165194"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="31"
    >
      <circle cx="15.5255" cy="15.6056" r="14.7606" fill="#FCFCFC" />
    </mask>
    <g mask="url(#mask0_15362_165194)">
      <rect
        x="5.56177"
        y="27.0449"
        width="20.2959"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="5.56177"
        y="27.0449"
        width="20.2959"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="19.6646"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="19.6646"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="11.9155"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="11.9155"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="4.53516"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="1.13354"
        y="4.53516"
        width="29.1523"
        height="3.69016"
        fill="#D90026"
      />
      <rect
        x="0.764893"
        y="0.844971"
        width="15.1296"
        height="14.7606"
        fill="#0052B5"
      />
      <path
        d="M12.9421 13.207L11.8351 13.7605L12.2041 12.6534L11.4661 11.9154H12.5731L12.9421 10.8083L13.4957 11.9154H14.4182L13.6802 12.6534L14.0492 13.7605L12.9421 13.207Z"
        fill="white"
      />
      <path
        d="M7.77611 13.207L6.66906 13.7605L7.03808 12.6534L6.30005 11.9154H7.4071L7.77611 10.8083L8.32964 11.9154H9.25218L8.51414 12.6534L8.88316 13.7605L7.77611 13.207Z"
        fill="white"
      />
      <path
        d="M2.60961 13.207L1.50256 13.7605L1.87158 12.6534L1.13354 11.9154H2.24059L2.60961 10.8083L3.16313 11.9154H4.08567L3.34764 12.6534L3.71666 13.7605L2.60961 13.207Z"
        fill="white"
      />
      <path
        d="M12.9421 9.14763L11.8351 9.70115L12.2041 8.5941L11.4661 7.85607H12.5731L12.9421 6.74902L13.4957 7.85607H14.4182L13.6802 8.5941L14.0492 9.70115L12.9421 9.14763Z"
        fill="white"
      />
      <path
        d="M12.9421 5.08854L11.8351 5.64207L12.2041 4.53502L11.4661 3.79699H12.5731L12.9421 2.68994L13.4957 3.79699H14.4182L13.6802 4.53502L14.0492 5.64207L12.9421 5.08854Z"
        fill="white"
      />
      <path
        d="M12.9421 1.02946L11.8351 1.58299L12.2041 0.475939L11.4661 -0.262093H12.5731L12.9421 -1.36914L13.4957 -0.262093H14.4182L13.6802 0.475939L14.0492 1.58299L12.9421 1.02946Z"
        fill="white"
      />
      <path
        d="M7.77611 5.08854L6.66906 5.64207L7.03808 4.53502L6.30005 3.79699H7.4071L7.77611 2.68994L8.32964 3.79699H9.25218L8.51414 4.53502L8.88316 5.64207L7.77611 5.08854Z"
        fill="white"
      />
      <path
        d="M7.77611 9.14763L6.66906 9.70115L7.03808 8.5941L6.30005 7.85607H7.4071L7.77611 6.74902L8.32964 7.85607H9.25218L8.51414 8.5941L8.88316 9.70115L7.77611 9.14763Z"
        fill="white"
      />
      <path
        d="M2.60961 9.14763L1.50256 9.70115L1.87158 8.5941L1.13354 7.85607H2.24059L2.60961 6.74902L3.16313 7.85607H4.08567L3.34764 8.5941L3.71666 9.70115L2.60961 9.14763Z"
        fill="white"
      />
    </g>
  </svg>
);

export default UsaFlagIcon;
