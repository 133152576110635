import { Badge, styled, Typography } from "@material-ui/core";

export const MobileMenu = styled("div")(() => ({
  position: `relative`,
  zIndex: 1
}));

export const MobileMenuInner = styled("div")(({ theme }) => ({
  position: `fixed`,
  top: 0,
  left: 0,
  right: 0,
  height: "var(--100vh)",
  overflow: `hidden`,
  pointerEvents: `none`,
  zIndex: 0,
  "&.open": {
    pointerEvents: `all`,
    transitionDelay: `0ms`
  },
  transition: theme.transitions.create([`z-index`], {
    duration: `200ms`,
    easing: `ease`,
    delay: `500ms`
  })
}));

export const MobileMenuBackground = styled("div")(({ theme }) => ({
  position: "relative",
  display: `flex`,
  flex: `1 0 auto`,
  flexDirection: `column`,
  overflowX: `hidden`,
  overflowY: `auto`,
  height: `0px`,
  width: `100%`,
  background: theme.palette.primary.main,
  "&.open": {
    height: `100%`,
    transitionDelay: `0ms`
  },
  transition: theme.transitions.create([`height`], {
    duration: `200ms`,
    easing: `ease`,
    delay: `500ms`
  })
}));

export const MobileMenuContent = styled("div")(({ theme }) => ({
  display: `flex`,
  flex: `1 0 auto`,
  flexDirection: `column`,
  justifyContent: "space-between",
  margin: theme.spacing(6.75, 2.5, 3),
  width: 0,
  transition: "all 300ms ease-in-out 200ms",
  overflow: "hidden",
  "&.open": {
    width: `calc(100vw - ${theme.spacing(5)}px)`
  }
}));

export const MobileMenuItemsList = styled("div")(({ theme }) => ({
  display: `flex`,
  flex: `0 0 auto`,
  flexDirection: `column`,
  position: `relative`,
  width: `calc(100vw - ${theme.spacing(5)}px)`,
  margin: theme.spacing(0),
  paddingTop: theme.spacing(2.5),
  borderTop: `1px solid ${theme.palette.common.black}`,
  "& a": {
    marginBottom: theme.spacing(1)
  }
}));
