import React, { useCallback, useEffect } from "react";
import { UserType } from "../types/airgraft";
import { getToken, getCurrentUser, setToken } from "../services/user";
import { useSetState } from "react-use";
import { logout as logoutUser } from "../services/user";
import { analyticsIdentify, analyticsUnidentify } from "../services/analytics";
import { createContext } from "use-context-selector";
import { useQueryClient } from "react-query";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <AuthenticationProvider>."
  );
};

export interface AuthContextType {
  /**
   * Is the user currently logged in?
   */
  isAuthenticated: boolean;

  /**
   * Current api token inside localstorage
   */
  token: string | null;

  /**
   * Current sign-in user object
   */
  user: UserType | null;

  /**
   * Sign out user and clear token in local storage
   */
  logout: (options?: { redirectPath?: string }) => void;

  /**
   * Update current user authentication
   */
  updateLocalStateUser: (user: UserType) => void;

  refetchUserInfo: () => Promise<UserType>;
}

export const AuthenticationContext = createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  user: null,
  logout: stub,
  updateLocalStateUser: stub,
  refetchUserInfo: stub
});

export default function AuthenticationProvider({ children }) {
  const queryClient = useQueryClient();

  const [state, setState] = useSetState<{
    user: UserType;
    token: string;
  }>({
    token: getToken(),
    user: null
  });

  const logout = useCallback(
    async (options?: { redirectPath?: string }) => {
      setState({ user: null, token: null });
      await logoutUser();
      queryClient.clear();
      analyticsUnidentify();

      if (options?.redirectPath) {
        window.location.href = options.redirectPath;
      } else {
        // Refresh page, to fix any issues with app state (Ex: inside AGX)
        window.location.reload();
      }
    },
    [setState]
  );

  const fetchCurrentUser = useCallback(async () => {
    if (state.token) {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        setState({ user: currentUser });
        analyticsIdentify(currentUser);
        // Update storage to be up to date with token
        setToken(state.token);
      } else {
        // Token is invalid, logout user
        logout();
      }
    }
  }, [setState, setToken, logout]);

  /**
   * On load: Fetch user object using local storage token
   */
  useEffect(() => {
    fetchCurrentUser();
  }, [state.token]);

  const updateLocalStateUser = useCallback(
    async (user: UserType) => {
      if (user.appToken) {
        setToken(user.appToken);
        setState({ user, token: user.appToken });
      } else {
        setState({ user });
      }
    },
    [setToken, setState]
  );

  const refetchUserInfo = useCallback(async () => {
    await fetchCurrentUser();
    return state.user;
  }, [fetchCurrentUser, state.user]);

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: !!state.token,
        token: state.token,
        user: state.user,
        logout,
        updateLocalStateUser,
        refetchUserInfo: refetchUserInfo
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
