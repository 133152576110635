import { CountryType, LangType, LocaleType } from "../types/airgraft";
import COUNTRIES from "../../config/countries";
import LOCALES from "../../config/locales";
import localStorageFallback from "local-storage-fallback";

export function getAllCountryKeys() {
  return Object.keys(COUNTRIES) as CountryType[];
}

export function getSubcountries(country: CountryType) {
  return COUNTRIES[country].subcountries;
}

export function getVisibleSubcountries(country: CountryType) {
  return getSubcountries(country).filter(s => !s.hidden);
}

export function isValidSubcountry(country: CountryType, subcountry: string) {
  const subcountries = getSubcountries(country);
  return !!subcountries.find(s => s.value === subcountry);
}

/**
 * Get region & language from locale string
 */
export function getCountryAndLanguageFromLocale(locale: LocaleType) {
  const localeConfig = LOCALES[locale];

  if (!localeConfig) {
    return { country: null, lang: null };
  }

  return { country: localeConfig.country, lang: localeConfig.lang };
}

/**
 * Get subcounry by label
 * Ex: getSubcountryByLabel('California') => 'CA'
 */
export function getSubcountryByLabel(subcountryLabel: string): string {
  return Object.keys(COUNTRIES).reduce<string>(
    (accumulator, country: CountryType) => {
      const name = COUNTRIES[country].subcountries.reduce<string>(
        (subAccumulator, subcountryConfig) => {
          if (
            subcountryConfig.label?.toLowerCase() ===
            subcountryLabel?.toLowerCase()
          ) {
            return subcountryConfig.value;
          }
          return subAccumulator;
        },
        null
      );
      return name || accumulator;
    },
    null
  );
}

/**
 * Get subcounry labe
 * Ex: getSubcountryLabel('CA') => 'California'
 */
export function getSubcountryLabel(subcountry: string): string {
  return Object.keys(COUNTRIES).reduce<string>(
    (accumulator, country: CountryType) => {
      const name = COUNTRIES[country].subcountries.reduce<string>(
        (subAccumulator, subcountryConfig) => {
          if (
            subcountryConfig.value.toUpperCase() === subcountry.toUpperCase()
          ) {
            return subcountryConfig.label;
          }
          return subAccumulator;
        },
        null
      );
      return name || accumulator;
    },
    null
  );
}

/**
 * Get default subcountry for a given country
 * This is used to decide which content modules to render on the pre-rendered version of pages
 */
export function getDefaultSubcountry(country: CountryType) {
  return (COUNTRIES[country]?.subcountries || []).find(s => !!s.default)?.value;
}

/**
 * Get all available locales for country
 */
export function getAllLanguagesForCountry(country: CountryType): LangType[] {
  return Object.keys(LOCALES).reduce<LangType[]>(
    (accumulator, locale: LocaleType) => {
      const localeConfig = LOCALES[locale];
      if (localeConfig.country === country) {
        return [...accumulator, localeConfig.lang];
      }
      return accumulator;
    },
    []
  );
}

/**
 * Save subcountry inside localstorage for specific country
 */
export function setSubcountryForSpecificCountry(
  country: CountryType,
  subcountry: string
) {
  localStorageFallback.setItem(
    `airgraft-subcountry-${country}`,
    JSON.stringify(subcountry)
  );
}

/**
 * Get product fields to check if a product is available in any of the country subcountries.
 * Ex: getProductVisibilityFieldsForSubcountry('CA') => ['availableCa', 'availableCaAb']
 */
export function getAllProductVisibilityFieldsForCountry(
  country: CountryType
): string[] {
  if (!COUNTRIES[country]) {
    throw new Error(
      `Cannot get product visibility fields for invalid country: ${country}`
    );
  }
  return COUNTRIES[country].subcountries.reduce(
    (accumulator, countryConfig) => {
      return [...accumulator, countryConfig.productVisibilityField];
    },
    []
  );
}

/**
 * Get product field to check if a product is available in a specific subcountry.
 * Ex: getProductVisibilityFieldsForSubcountry('CA', 'ON') => 'availableCa'
 */
export function getProductVisibilityFieldsForSubcountry(
  country: CountryType,
  subcountry: string
): string {
  return (COUNTRIES[country]?.subcountries || []).find(
    s => s.value === subcountry
  )?.productVisibilityField;
}

/**
 * Use Netlify Edge functions to detect country and subcountry based on IP
 */
export async function fetchDetectedCountryAndSubcountry(): Promise<{
  country: CountryType;
  subcountry: string;
} | null> {
  try {
    const response = await fetch(process.env.GATSBY_DETECT_COUNTRY_ENDPOINT);
    if (response.ok) {
      const json = await response.json();
      return json as {
        country: CountryType;
        subcountry: string;
      };
    }
  } catch (e) {
    return null;
  }
  return null;
}

/**
 * Map detected subcountry to our website subcountry.
 */
export function getClosestAvailableSubcountry(
  country: CountryType,
  subcountry: string
): string | null {
  if (isValidSubcountry(country, subcountry)) {
    return subcountry;
  }
  const mapping = {
    US: {
      MI: "OH",
      IN: "OH",
      CO: "OH",
      KY: "OH",
      WV: "OH",
      GA: "OH",
      NM: "CA",
      NV: "CA",
      AZ: "CA",
      OR: "CA",
      UT: "CA"
    },
    CA: {
      BC: "AB",
      SK: "AB",
      QC: "ON",
      MB: "ON"
    }
  };
  return mapping[country][subcountry] || getDefaultSubcountry(country);
}
