import { styled } from "@material-ui/core";
import { Link } from "gatsby";

export const MenuItemLink = styled(Link)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
  marginBottom: `${theme.spacing(1)}px !important`,
  fontSize: `2rem`,
  lineHeight: `2.8rem`,
  fontFamily: "Reckless, Georgia",
  borderRadius: "3px"
}));

export const Separator = styled("div")(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: `rgba(30, 30, 30, 0.2)`,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5)
}));
