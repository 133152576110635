import React from "react";
import { Box, Button, Snackbar, styled, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckmarkCircleIcon from "../../components/icons/checkmark-circle";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  "& > div": {
    color: theme.palette.common.white,
    "& .MuiButton-root": {
      color: theme.palette.common.white
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "512px"
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%"
    }
  },
  "&.MuiSnackbar-anchorOriginBottomCenter": {
    bottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(2),
      left: theme.spacing(2),
      right: theme.spacing(2)
    }
  },
  "& .MuiButton-root": {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(-0.5)
  },
  "&.variant--info > div": {
    backgroundColor: theme.palette.common.black
  },
  "&.variant--success > div": {
    backgroundColor: "#009D43"
  },
  "&.variant--error > div": {
    backgroundColor: "#FF4848"
  }
}));

export type CustomSnackbarType = "info" | "success" | "error";

export default function CustomSnackbar({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters
}) {
  const variant: CustomSnackbarType = customParameters?.variant || "info";

  return (
    <StyledSnackbar {...SnackbarProps} className={`variant--${variant}`}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor="common.black"
        color="common.white"
        borderRadius="8px"
        paddingLeft={2}
        paddingRight={2}
        paddingTop={1.75}
        paddingBottom={1.75}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight={1}
        >
          {variant === "info" && <InfoOutlinedIcon />}
          {variant === "success" && <CheckmarkCircleIcon />}
          {variant === "error" && <ReportProblemOutlinedIcon />}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography variant="body1">{message}</Typography>
          {action != null && (
            <Button
              size="small"
              variant="text"
              {...ButtonProps}
              endIcon={<ArrowForwardIcon />}
            >
              {action}
            </Button>
          )}
        </Box>
      </Box>
    </StyledSnackbar>
  );
}
