import { styled } from "@material-ui/core";
import { Link } from "gatsby";
import MobileHamburgerButton from "../MobileHamburgerButton";

export const CustomMobileMenuButton = styled(MobileHamburgerButton)(
  ({ theme }) => ({
    position: `absolute`,
    top: "9px",
    right: theme.spacing(2)
  })
);

export const MobileMenu = styled("div")(() => ({
  position: `relative`,
  zIndex: 5000
}));

export const MobileMenuInner = styled("div")(({ theme }) => ({
  position: `fixed`,
  top: 0,
  left: 0,
  right: 0,
  height: "var(--100vh)",
  overflow: `hidden`,
  pointerEvents: `none`,
  zIndex: 0,
  "&.open": {
    pointerEvents: `all`,
    transitionDelay: `0ms`
  },
  transition: theme.transitions.create([`z-index`], {
    duration: `50ms`,
    easing: `ease`,
    delay: `100ms`
  })
}));

export const MobileMenuBackground = styled("div")(({ theme }) => ({
  position: "relative",
  display: `flex`,
  flex: `1 0 auto`,
  flexDirection: `column`,
  overflowX: `hidden`,
  overflowY: `auto`,
  height: `0px`,
  width: `100%`,
  background: theme.palette.primary.main,
  "&.open": {
    height: `100%`,
    transitionDelay: `0ms`
  },
  transition: theme.transitions.create([`height`], {
    duration: `50ms`,
    easing: `ease`,
    delay: `100ms`
  })
}));

export const MobileMenuContent = styled("div")(({ theme }) => ({
  display: `flex`,
  flex: `1 0 auto`,
  flexDirection: `column`,
  justifyContent: "space-between",
  margin: theme.spacing(6.75, 2.5, 3),
  width: 0,
  overflow: `hidden`,
  transition: "all 50ms ease-in-out 20ms",
  "&.open": {
    width: `calc(100vw - ${theme.spacing(5)}px)`
  }
}));

export const MobileMenuItemsList = styled("div")(({ theme }) => ({
  display: `flex`,
  flex: `0 0 auto`,
  flexDirection: `column`,
  position: `relative`,
  width: `calc(100vw - ${theme.spacing(5)}px)`,
  margin: theme.spacing(0),
  paddingTop: theme.spacing(2.5),
  borderTop: `1px solid ${theme.palette.common.black}`,
  "& a": {
    marginBottom: theme.spacing(1)
  }
}));

export const MenuItemLink = styled(Link)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(0.5, 0.5, 0.5, 2),
  marginBottom: `${theme.spacing(1)}px !important`,
  fontSize: `2rem`,
  lineHeight: `2.8rem`,
  fontFamily: "Reckless, Georgia",
  borderRadius: "3px"
}));

export const Separator = styled("div")(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(2)}px)`,
  height: "1px",
  backgroundColor: `rgba(30, 30, 30, 0.2)`,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(2)
}));
