import { Box, Button, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  fetchDetectedCountryAndSubcountry,
  getClosestAvailableSubcountry,
  getDefaultSubcountry,
  getSubcountries,
  getVisibleSubcountries,
  isValidSubcountry
} from "../../../helpers/country";
import { getLocalizedPath } from "../../../helpers/path";
import useFeatureFlag from "../../../hooks/use-feature-flag";
import useSubcountry from "../../../hooks/use-subcountry";
import useTranslate from "../../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../../hooks/use-unlocalized-location-path";
import BirthdayInput, { getLegalAgeValidator } from "../../birthday-input";
import ArrowDown from "../../icons/arrow-down";
import CanadaFlagIcon from "../../icons/flag-canada";
import { CustomTextField, SwitchLocaleLink } from "../styles";

export type CanadaFormDataType = {
  province: string;
  birthday: string;
};

type Props = {
  onAccept: (province: string) => void;
};

export default function WelcomeModalCanadaForm({ onAccept }: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const unlocalizedPath = useUnlocalizedLocationPath();
  const { subcountry: previouslySelectedSubcountry } = useSubcountry();

  const { handleSubmit, errors, control, watch, register, setValue } =
    useForm<CanadaFormDataType>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        province: previouslySelectedSubcountry || getDefaultSubcountry("CA")
      }
    });
  const provinceValue = watch("province");

  const onSubmit = (data: CanadaFormDataType) => {
    onAccept(data.province);
  };

  // Detect country & subcountry to preselect region
  // useEffect(() => {
  //   const autoSelectSubcountry = async () => {
  //     const data = await fetchDetectedCountryAndSubcountry();
  //     if (data?.country === "CA") {
  //       const preselectSubcountry = isValidSubcountry("CA", data.subcountry)
  //         ? data.subcountry
  //         : getClosestAvailableSubcountry("CA", data.subcountry);
  //       setValue("province", preselectSubcountry);
  //     }
  //   };
  //   autoSelectSubcountry();
  // }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%"
      }}
    >
      <Typography variant="h5">{t("welcomeModal.CA.title")}</Typography>

      <Box mt={3} display="flex" alignItems="flex-start">
        {/* Date of birth field */}
        <CustomTextField
          id="birthday"
          name="birthday"
          variant="filled"
          placeholder={t("welcomeModal.CA.dateOfBirthPlaceholder")}
          fullWidth
          inputRef={register({
            required: t("error.required"),
            validate: getLegalAgeValidator(19, t)
          })}
          InputProps={{
            inputComponent: BirthdayInput
          }}
          error={!!errors.birthday}
          helperText={errors.birthday?.message}
        />

        <>
          <Box width={theme.spacing(2)} />

          {/* Province field */}
          <Controller
            labelId="province"
            name="province"
            rules={{
              required: t("error.required")
            }}
            control={control}
            error={!!errors.province}
            defaultValue=""
            render={({ onChange, onBlur, value, name, ref }) => (
              <CustomTextField
                select
                variant="filled"
                name={name}
                inputRef={ref}
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.province}
                helperText={errors.province?.message}
                className={!provinceValue ? "no-value" : "with-value"}
                SelectProps={{
                  native: true,
                  disableUnderline: true,
                  error: !!errors.province,
                  IconComponent: () => (
                    <ArrowDown
                      style={{
                        position: `absolute`,
                        right: theme.spacing(2)
                      }}
                    />
                  )
                }}
              >
                <option value="" disabled>
                  {t("welcomeModal.CA.selectProvince")}
                </option>
                {getVisibleSubcountries("CA").map(subcountry => (
                  <option key={subcountry.value} value={subcountry.value}>
                    {subcountry.label}
                  </option>
                ))}
              </CustomTextField>
            )}
          />
        </>
      </Box>

      <Box
        mt={3}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button variant="contained" fullWidth type="submit">
          {t("go")}
        </Button>
      </Box>

      <Box
        borderTop={`1px solid ${theme.palette.common.black}`}
        paddingTop={2}
        display="flex"
        alignItems="center"
      >
        <Box width="30px" height="30px" marginRight={1}>
          <CanadaFlagIcon />
        </Box>
        <Typography variant="body2">
          <span
            dangerouslySetInnerHTML={{
              __html: t("welcomeModal.CA.footer")
            }}
          />
          &nbsp;
          <SwitchLocaleLink to={getLocalizedPath("en-US", unlocalizedPath)}>
            {t("welcomeModal.CA.footer.switch")}
          </SwitchLocaleLink>
        </Typography>
      </Box>
    </form>
  );
}
