import { Box, Button, styled } from "@material-ui/core";
import React from "react";
import TrashcanIcon from "../../../components/icons/trashcan";

export const QuantitySelector = styled("div")(({ theme }) => ({
  fontFamily: "Roobert, Arial",
  height: "28px",
  borderRadius: "28px",
  border: `1px solid ${theme.palette.grey[200]}`,
  background: theme.palette.common.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2rem",
  lineHeight: "2.4rem",
  fontWeight: 500,
  width: "150px",
  paddingLeft: "1px",
  paddingRight: "1px"
}));

export const QuantityButton = styled(Button)(({ theme }) => ({
  flex: 1,
  fontSize: "2.7rem",
  lineHeight: 1,
  userSelect: "none",
  padding: 0,
  width: "24px",
  height: "24px",
  minWidth: "unset",
  minHeight: "unset",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiButton-label": {
    width: "100%",
    height: "100%",
    lineHeight: "24px",
    transform: "translate(1px, -1px)"
  },
  "&.Mui-disabled": {
    opacity: 0.75,
    color: theme.palette.grey[400]
  }
}));

export const QuantityValue = styled("span")(({ theme }) => ({
  fontSize: "1.7rem",
  lineHeight: "2rem",
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  userSelect: "none"
}));

type Props = {
  quantity: number;
  onDesc: () => void;
  onIncr: () => void;
  maxQuantity?: number;
};

export default function CartItemQuantitySelector({
  quantity,
  onDesc,
  onIncr,
  maxQuantity
}: Props) {
  return (
    <QuantitySelector>
      <QuantityButton
        onClick={onDesc}
        title={quantity === 1 ? "Remove from cart" : "Decrease quantity"}
      >
        {quantity === 1 ? <TrashcanIcon fontSize="small" /> : "-"}
      </QuantityButton>
      <QuantityValue>{quantity}</QuantityValue>
      <QuantityButton
        onClick={onIncr}
        title="Increase quantity"
        disabled={quantity >= maxQuantity}
        data-testid="cart.item.increase-quantity"
      >
        +
      </QuantityButton>
    </QuantitySelector>
  );
}
