// prettier-ignore

export const NETLIFY_FUNCTIONS_BASE_URL = `${(process.env.GATSBY_NETLIFY_FUNCTIONS_BASE_URL || '')}/.netlify/functions`;

export async function fetchNetlifyFunctions(
  endpoint: string,
  init?: RequestInit
) {
  return fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}${
      endpoint.startsWith("/") ? "" : "/"
    }${endpoint}`,
    init
  );
}
