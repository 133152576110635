import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function ShopingCartIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props} style={{ color: "transparent" }}>
      <path
        d="M4.23218 4.875H14.9996C15.1146 4.875 15.228 4.90143 15.3311 4.95224C15.4342 5.00305 15.5243 5.07689 15.5943 5.16805C15.6644 5.25921 15.7125 5.36525 15.7351 5.47798C15.7576 5.59071 15.7539 5.70711 15.7243 5.81819L14.5247 10.3182C14.4821 10.4779 14.388 10.619 14.257 10.7197C14.1259 10.8204 13.9653 10.875 13.8 10.875H5.5179"
        fill="transparent"
      />
      <path
        d="M4.23218 4.875H14.9996C15.1146 4.875 15.228 4.90143 15.3311 4.95224C15.4342 5.00305 15.5243 5.07689 15.5943 5.16805C15.6644 5.25921 15.7125 5.36525 15.7351 5.47798C15.7576 5.59071 15.7539 5.70711 15.7243 5.81819L14.5247 10.3182C14.4821 10.4779 14.388 10.619 14.257 10.7197C14.1259 10.8204 13.9653 10.875 13.8 10.875H5.5179"
        stroke="#323232"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13.125L3.75 2.625H2.25"
        stroke="#323232"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1875 13.125H13.6875"
        stroke="#323232"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 15.75C14.4124 15.75 15 15.1624 15 14.4375C15 13.7126 14.4124 13.125 13.6875 13.125C12.9626 13.125 12.375 13.7126 12.375 14.4375C12.375 15.1624 12.9626 15.75 13.6875 15.75Z"
        fill="transparent"
        stroke="#323232"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1875 15.75C6.91237 15.75 7.5 15.1624 7.5 14.4375C7.5 13.7126 6.91237 13.125 6.1875 13.125C5.46263 13.125 4.875 13.7126 4.875 14.4375C4.875 15.1624 5.46263 15.75 6.1875 15.75Z"
        fill="transparent"
        stroke="#323232"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
