const pluralize = require("pluralize");

const SHOP_BASEPATH = "/shop";
const SHOP_PRODUCTS_PATH = "/shop/products";
const SHOP_PRODUCTS_LIST_PARAM = `list`;
const SHOP_DISCOVER_PATH = "/shop";

function getProductDetailsPath(productType, slug) {
  if (productType === "oil") {
    return `${SHOP_BASEPATH}/pods/${slug}`;
  }
  return `${SHOP_BASEPATH}/${pluralize(productType).toLowerCase()}/${slug}`;
}

function getProductDetailsFullUrl(
  productType,
  slug,
  siteUrl = null,
  localePathPrefix = "/us/en"
) {
  if (!productType || !slug) {
    return;
  }
  return `${
    siteUrl || process.env.SITE_URL
  }${localePathPrefix}${getProductDetailsPath(productType, slug)}`;
}

function getBrandPagePath(brandSlug) {
  return `${SHOP_BASEPATH}/${brandSlug}`;
}

exports.SHOP_BASEPATH = SHOP_BASEPATH;
exports.SHOP_PRODUCTS_PATH = SHOP_PRODUCTS_PATH;
exports.SHOP_PRODUCTS_LIST_PARAM = SHOP_PRODUCTS_LIST_PARAM;
exports.SHOP_DISCOVER_PATH = SHOP_DISCOVER_PATH;
exports.getProductDetailsPath = getProductDetailsPath;
exports.getProductDetailsFullUrl = getProductDetailsFullUrl;
exports.getBrandPagePath = getBrandPagePath;
