import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props} style={{ color: "transparent" }}>
      <g clipPath="url(#clip0_3439_2243)">
        <path
          d="M7.13251 13.5V13.884C7.13251 14.1292 7.18082 14.3719 7.27469 14.5984C7.36856 14.8249 7.50614 15.0307 7.67958 15.204C7.85301 15.3773 8.05889 15.5147 8.28546 15.6084C8.51203 15.7021 8.75483 15.7502 9.00001 15.75C9.24522 15.7501 9.48804 15.7019 9.71461 15.6081C9.94118 15.5143 10.1471 15.3769 10.3205 15.2035C10.4939 15.0302 10.6315 14.8243 10.7253 14.5978C10.8192 14.3713 10.8675 14.1285 10.8675 13.8832V13.5"
          stroke="#323232"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4603 13.5C13.8686 13.5 14.2603 13.3378 14.549 13.049C14.8378 12.7603 15 12.3686 15 11.9602C14.9997 11.5283 14.8281 11.114 14.523 10.8083L13.5 9.786V6.75C13.5 5.55653 13.0259 4.41193 12.182 3.56802C11.3381 2.72411 10.1935 2.25 9 2.25C7.80653 2.25 6.66193 2.72411 5.81802 3.56802C4.97411 4.41193 4.5 5.55653 4.5 6.75V9.786L3.477 10.8083C3.17187 11.114 3.00035 11.5283 3 11.9602C3 12.3686 3.16222 12.7603 3.45098 13.049C3.73974 13.3378 4.13138 13.5 4.53975 13.5H13.4603Z"
          fill="transparent"
          stroke="#323232"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3439_2243">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
