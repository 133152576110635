import React, { useCallback, useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import { useQuery, useQueryClient } from "react-query";
import { useToggle } from "react-use";
import { useLocalStorageState } from "../helpers/localstorage";
import {
  fetchAvailableProviders,
  fetchProviderAvailableProductsData
} from "../helpers/express-delivery/provider";
import {
  ProductAvailabilityType,
  DeliveryProviderProductData,
  DeliveryProviderType
} from "../types/airgraft-express";
import {
  fetchDeliveryAddressByPlaceId,
  getDeliveryAddressMatchingSubcountry
} from "../helpers/express-delivery/address";
import {
  CountryType,
  DeliveryAddressType,
  ProductListItemType,
  ProductType,
  ProductVariantListItemType,
  ProductVariantType
} from "../types/airgraft";
import { getDefaultProductItemVariant } from "../helpers/product";
import {
  analyticsIdentifyDeliveryAddress,
  analyticsTrackEvent
} from "../services/analytics";

type SetAddressOptionsType = {
  saveToLocalStorage?: boolean;
  saveToAnalytics?: boolean;
};

export const XPRESS_DELIVERY_AVAILABILITY_LOCALSTORAGE_KEY =
  "airgraft-express-delivery-place-id";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <ExpressDeliveryProvider>."
  );
};

export interface ExpressDeliveryAvailabilityContextType {
  /**
   * Is AGX delivery address panel open
   */
  isAddressPanelOpen: boolean;

  /**
   * Open/Close AGX delivery address panel
   */
  toggleAvailabilityPanel: (nextValue?: boolean) => void;

  /**
   * Current address entered by user for delivery
   */
  address: DeliveryAddressType;

  /**
   * Is loading previously used Address using localstorage placeId
   */
  isLoadingAddress: boolean;

  /**
   * Change current address. This will fetch a new provider & availableProducts
   */
  setAddress: (
    address: DeliveryAddressType,
    options?: SetAddressOptionsType
  ) => void;

  /**
   * Change current address. This will fetch a new provider & availableProducts
   */
  setAddressByPlaceId: (
    placeId: string,
    options?: SetAddressOptionsType
  ) => void;

  /**
   * Matching country for current delivery address.
   * Used to redirect users to a different country version of the website if
   * no delivery providers is matching.
   */
  addressMatchingCountry: null | CountryType;

  /**
   * Matching subcountry for current delivery address.
   * Used to redirect users to a different state/province version of the website if
   * no delivery providers is matching.
   */
  addressMatchingSubcountry: null | string;

  /**
   * Current provider available for delivery based on address
   */
  availableProvider: DeliveryProviderType;
  isLoadingAvailableProvider: boolean;
  hasNoAvailableProvider: boolean;

  /**
   * Current available product ids for delivery based on current provider
   */
  availableProducts: DeliveryProviderProductData[];
  isLoadingAvailableProducts: boolean;

  /**
   * Helper to get product availability label based on current provider available product variants
   */
  getProductExpressAvailability: (
    product: ProductType | ProductListItemType
  ) => ProductAvailabilityType | null;

  /**
   * Helper to get product variant availability label based on current provider available product variants
   */
  getProductVariantExpressAvailability: (
    product: ProductVariantType | ProductVariantListItemType
  ) => ProductAvailabilityType | null;

  /**
   * Helper to get product price and available quantity for current provider
   */
  getAvailableProviderProductData: (
    product: ProductType | ProductListItemType
  ) => DeliveryProviderProductData | null;
}

export const ExpressDeliveryAvailabilityContext =
  createContext<ExpressDeliveryAvailabilityContextType>({
    // Address/Provider
    isAddressPanelOpen: false,
    toggleAvailabilityPanel: stub,
    address: null,
    setAddress: stub,
    setAddressByPlaceId: stub,
    isLoadingAddress: false,
    addressMatchingCountry: null,
    addressMatchingSubcountry: null,

    // Available provider
    availableProvider: null,
    isLoadingAvailableProvider: false,
    hasNoAvailableProvider: false,

    // Available products
    availableProducts: [],
    isLoadingAvailableProducts: false,
    getProductExpressAvailability: stub,
    getProductVariantExpressAvailability: stub,
    getAvailableProviderProductData: stub
  });

export const ExpressDeliveryAvailabilityProvider = ({ children }) => {
  const queryClient = useQueryClient();

  // Address
  const [isAddressPanelOpen, toggleAvailabilityPanel] = useToggle(false);
  const [address, setAddressValue] = useState<DeliveryAddressType>(null);

  // Save last placeId inside localstorage
  const [localstoragePlaceId, setLocalstoragePlaceId] =
    useLocalStorageState<string>(
      XPRESS_DELIVERY_AVAILABILITY_LOCALSTORAGE_KEY,
      null
    );

  // Change current address with aditional options
  const setAddress = useCallback(
    (address: DeliveryAddressType, options?: SetAddressOptionsType) => {
      setAddressValue(address);
      if (options?.saveToLocalStorage) {
        setLocalstoragePlaceId(address?.placeId || null);
      }
      if (options?.saveToAnalytics) {
        analyticsIdentifyDeliveryAddress(address);
        if (address) {
          analyticsTrackEvent("address-panel.set-address");
        }
      }
    },
    []
  );

  const [isLoadingAddress, setLoadingPreviousAddress] = useState(
    !!localstoragePlaceId
  );

  // Helper to set address by placeId
  const setAddressByPlaceId = useCallback(
    async (placeId: string, options?: SetAddressOptionsType) => {
      if (!placeId) {
        setAddress(null, options);
        return;
      }

      setLoadingPreviousAddress(true);

      let deliveryAddress: DeliveryAddressType = null;
      try {
        deliveryAddress = await queryClient.fetchQuery(
          ["delivery-address-by-id", placeId],
          () => fetchDeliveryAddressByPlaceId(placeId),
          {
            staleTime: 1000 * 60 * 60 * 48 // 48 hours
          }
        );
      } catch (e) {}

      if (deliveryAddress) {
        setAddress(deliveryAddress, options);
      }

      setLoadingPreviousAddress(false);
    },
    []
  );

  // On page load: Prefill "address" using localstorage placeId
  useEffect(() => {
    if (localstoragePlaceId) {
      setAddressByPlaceId(localstoragePlaceId, { saveToAnalytics: true });
    }
  }, []);

  // Fetch available providers everytime "address" changes
  const { data: availableProviders, isLoading: isLoadingAvailableProvider } =
    useQuery(
      ["express-delivery-available-providers", address?.placeId],
      () => fetchAvailableProviders(address?.placeId),
      {
        enabled: !!address?.placeId,
        staleTime: 1000 * 30 // Re-use existing data for 30 seconds
      }
    );
  const availableProvider =
    availableProviders?.length > 0 ? availableProviders[0] : null;

  const hasNoAvailableProvider =
    !!address && !availableProvider && !isLoadingAvailableProvider;

  const {
    country: addressMatchingCountry,
    subcountry: addressMatchingSubcountry
  } = getDeliveryAddressMatchingSubcountry(address);

  // Fetch available products everytime "availableProvider" changes
  const { data: availableProducts, isLoading: isLoadingAvailableProducts } =
    useQuery(
      ["express-delivery-available-products", availableProvider?.id],
      () => fetchProviderAvailableProductsData(availableProvider?.id),
      {
        enabled: !!availableProvider,
        staleTime: 1000 * 30, // Re-use existing data for 30 seconds
        retry: 3
      }
    );

  // Helper to check if a product is available for delivery with current provider
  const getProductVariantExpressAvailability = useCallback(
    (
      productVariant: ProductVariantListItemType | ProductVariantType
    ): ProductAvailabilityType | null => {
      if (!productVariant || !productVariant.originalId) {
        return "UNAVAILABLE";
      }

      const providerProductData = availableProducts?.find(
        v => v.productVariantId === productVariant.originalId
      );

      // Show product as "available" if deliver provider has inventory.
      if (providerProductData?.availableQuantity > 0) {
        return "AVAILABLE";
      }

      // Else show as "Coming soon" if product is marking as Coming Soon inside DatoCMS
      if (productVariant.availability === "coming-soon") {
        return "COMING_SOON";
      }

      // Else show as "Sold Out" if product is marking as Sold Out inside DatoCMS
      if (productVariant.availability === "sold-out") {
        return "SOLDOUT";
      }

      // If no address selected, fallback to showing AGX label for products configured with any delivery providers.
      if (!address) {
        return productVariant.expressDeliveryProvidersCount > 0
          ? "NO_ADDRESS"
          : "UNAVAILABLE";
      }

      // Else show either SOLDOUT or UNAVAILABLE based on inventory
      return providerProductData?.availableQuantity === 0
        ? "SOLDOUT"
        : "UNAVAILABLE";
    },
    [availableProducts, address]
  );

  // Helper to check if a product is available for delivery with current provider
  const getProductExpressAvailability = useCallback(
    (
      product: ProductListItemType | ProductType
    ): ProductAvailabilityType | null => {
      const productVariant = getDefaultProductItemVariant(
        product as ProductListItemType
      );
      return getProductVariantExpressAvailability(productVariant);
    },
    [availableProducts, address]
  );

  // Helper to get product provider data (price, available quantity)
  const getAvailableProviderProductData = useCallback(
    (
      product: ProductListItemType | ProductType
    ): DeliveryProviderProductData | null => {
      if (!availableProducts || availableProducts.length === 0) {
        return null;
      }

      const productVariantId = product.variants[0].originalId;

      const productProviderData = availableProducts.find(
        pv => pv.productVariantId === productVariantId
      );

      return productProviderData || null;
    },
    [availableProducts, address]
  );

  return (
    <ExpressDeliveryAvailabilityContext.Provider
      value={{
        // Address
        isAddressPanelOpen,
        address,
        setAddress,
        setAddressByPlaceId,
        isLoadingAddress,
        addressMatchingCountry,
        addressMatchingSubcountry,

        // Provider
        availableProvider,
        isLoadingAvailableProvider,
        hasNoAvailableProvider,
        toggleAvailabilityPanel,
        isLoadingAvailableProducts,
        availableProducts,
        getProductExpressAvailability,
        getProductVariantExpressAvailability,
        getAvailableProviderProductData
      }}
    >
      {children}
    </ExpressDeliveryAvailabilityContext.Provider>
  );
};
