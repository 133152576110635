import { FeatureFlagsType } from "../../config/feature-flags";
import { getFeatureFlag } from "../helpers/feature-flags";
import useCountry from "./use-country";
import useSubcountry from "./use-subcountry";

export default function useFeatureFlag(flag: FeatureFlagsType): boolean {
  const country = useCountry();
  const { subcountry } = useSubcountry();
  return getFeatureFlag(country, flag, { subcountry });
}
