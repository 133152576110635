import { getLocalizedPath } from "../helpers/path";
import useLocale from "./use-locale";

const useLocalizedLocationPath = () => {
  const locale = useLocale();
  const localizedPath = (path: string) => {
    return getLocalizedPath(locale || "en-US", path);
  };
  return localizedPath;
};

export default useLocalizedLocationPath;
