import React from "react";
import { LayoutProvider } from "../providers/layout";
import AuthenticationProvider from "./authentication";
import { ExpressDeliveryAvailabilityProvider } from "./express-delivery-availability";
import { ExpressDeliveryCartProvider } from "./express-delivery-cart";
import ReactQueryProvider from "./react-query";
import { StoreLocatorAddressProvider } from "./store-locator-address";

export default function Providers({ children }) {
  return (
    <ReactQueryProvider>
      <AuthenticationProvider>
        <LayoutProvider>
          <ExpressDeliveryAvailabilityProvider>
            <ExpressDeliveryCartProvider>
              <StoreLocatorAddressProvider>
                {children}
              </StoreLocatorAddressProvider>
            </ExpressDeliveryCartProvider>
          </ExpressDeliveryAvailabilityProvider>
        </LayoutProvider>
      </AuthenticationProvider>
    </ReactQueryProvider>
  );
}
