import { useTheme } from "@material-ui/core";
import { formatMultilinesText } from "../../helpers/markdown";
import { getProductDetailsPath } from "../../helpers/url";
import { BACKGROUND_TYPE, MOBILE_BREAKPOINT } from "./constants";

/**
 * Check if a modular field points to a image or a video
 */
export const getBackgroundType = (background: {
  model: { apiKey: string };
}): BACKGROUND_TYPE | null => {
  if (!background) {
    return null;
  }
  const mapping = {
    m_module_card_background_image: "image",
    m_module_card_background_video: "video"
  };
  return mapping[background.model.apiKey] || null;
};

/**
 * Build link href based on the model type of the field value
 */
export type LinkType = {
  slug?: string;
  path?: string;
  url?: string;
  productType?: string;
  model?: {
    apiKey: string;
  };
};

export const getLinkHref = (
  link?: LinkType,
  options?: {
    routeParams?: { string: string };
  }
): string => {
  if (!link) {
    return null;
  }
  if (link?.model?.apiKey === "product") {
    return getProductDetailsPath(link.productType, link.slug);
  }
  if (link?.model?.apiKey === "card_external_link") {
    let href = link.url;

    // Replace route parameters like {{referralCode}} for dynamic variables inside external links
    if (options?.routeParams) {
      href = Object.keys(options?.routeParams).reduce(
        (accumulator, replacementKey) => {
          return accumulator.replace(
            `{{${replacementKey}}}`,
            options?.routeParams[replacementKey]
          );
        },
        href
      );
    }

    // Sometimes, DatoCMS link is a external link to our own website. In that case, just remove the domain the make it relative.
    if (
      link.url.startsWith("https://airgraft.com/us/en") ||
      link.url.startsWith("/")
    ) {
      href = href.replace("https://airgraft.com/us/en", "");
    }

    return href;
  }
  if (link.model.apiKey === "story") {
    return `#`;
  }
  return `/${link.path || ""}`;
};

export const getLinkProductSlug = (link?: LinkType): string => {
  if (link?.model?.apiKey === "product") {
    return link.slug;
  }
  return null;
};

/**
 * Convert aspectRatio field string value (Ex: "2.2:1) to padding top css property value (Ex: )
 * If the given aspect ratio is invalid, will fallback to default values
 */
export const getAspectRatioPaddingTop = (
  aspectRatio: string,
  defaultAspectRatio?: string
): string | null => {
  const parts = aspectRatio.split(":");
  const isValid =
    parts.length === 2 &&
    !isNaN(parseFloat(parts[0])) &&
    !isNaN(parseFloat(parts[1]));
  if (!isValid) {
    return defaultAspectRatio
      ? getAspectRatioPaddingTop(defaultAspectRatio)
      : null;
  }
  return `${(parseFloat(parts[1]) / parseFloat(parts[0])) * 100}%`;
};

/**
 * Note: DatoCMS provides a "mimeType" field for uploaded video but it doesnt work when we enter manual cdn url
 */
export const getVideoMediaType = (url: string) =>
  `video/${url.split(`.`).pop()}`;

/**
 * Use "src" field (Uploaded video direcly in DatoCMS) then fallback to "url" field
 */
export const getVideoUrl = (video: Queries.DatoCmsMModuleCardBackgroundVideo["video"]) => {
  return video.url || video.src?.video?.mp4Url;
};

/**
 * Get the breakpoint after the one used for deciding when we use mobile fields:
 * Ex: MOBILE_BREAKPOINT = "sm"? getMobileBreakpointUp() = "md"
 */
export const getMobileBreakpointUp = (): string => {
  const theme = useTheme();
  return theme.breakpoints.keys[
    theme.breakpoints.keys.indexOf(MOBILE_BREAKPOINT) + 1
  ];
};

/**
 * Convert DatoCMS "theme" field value to actual css color
 * Example: titleTheme: "black" -> theme.palette.text.primary
 */
export const getThemeColor = (datoCmsThemeValue: string, fallback?: string) => {
  const theme = useTheme();
  const themeColors = {
    black: theme.palette.text.primary,
    white: "white",
    yellow: theme.palette.primary.main
  };

  if (!themeColors[datoCmsThemeValue] && fallback && themeColors[fallback]) {
    return themeColors[fallback];
  }

  return themeColors[datoCmsThemeValue];
};

/**
 * Convert DatoCMS "theme" field value to button variant value
 * Example: titleTheme: "yellow" -> "primary"
 */
export const getThemeColorButtonVariant = (
  datoCmsThemeValue: string,
  fallback?: string
) => {
  const themeVariants = {
    black: "secondary",
    white: "default",
    yellow: "primary"
  };

  if (
    !themeVariants[datoCmsThemeValue] &&
    fallback &&
    themeVariants[fallback]
  ) {
    return themeVariants[fallback];
  }

  return themeVariants[datoCmsThemeValue];
};
