import { Box, NoSsr, Typography, useTheme } from "@material-ui/core";
import React from "react";
import fastCompare from "react-fast-compare";
import { useContextSelector } from "use-context-selector";
import LocalizedLink from "../../../../components/localized-link";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import useIsAuthenticated from "../../../../hooks/use-is-authenticated";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../hooks/use-translate";
import { LayoutContext } from "../../../../providers/layout";
import { getTargetHref } from "../../utils";
import MobileCountryMenuButton from "../MobileCountryMenu/CountryMenuButton";
import MobileHamburgerButton from "../MobileHamburgerButton";
import { MobileMenuButtonBadge } from "../MobileHamburgerButton/styles";
import MobileLanguageMenuButton from "../MobileLanguageMenu/LanguageMenuButton";
import {
  MobileMenu,
  MobileMenuBackground,
  MobileMenuContent,
  MobileMenuInner,
  MobileMenuItemsList
} from "./styles";

type Props = {
  navigationItems: Queries.HeaderFragmentQuery["datoCmsGeneralHeader"]["_allNavigationLocales"][number]["value"];
};

function MobileDropDownMenu({ navigationItems }: Props) {
  const t = useTranslate();
  const theme = useTheme();
  const getLocalizedPath = useLocalizedLocationPath();

  const isMobileNavigationMenuOpen = useContextSelector(
    LayoutContext,
    c => c.isMobileNavigationMenuOpen
  );
  const toggleMobileNavigationMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileNavigationMenu
  );
  const isAuthenticated = useIsAuthenticated();
  const logout = useAuthContextSelector(c => c.logout);

  // User logout
  const handleLogoutClick = () => {
    logout({
      redirectPath: getLocalizedPath("/")
    });
  };

  return (
    <MobileMenu>
      <MobileMenuButtonBadge
        color="primary"
        variant="dot"
        invisible
        overlap="rectangular"
      >
        <MobileHamburgerButton
          onClick={toggleMobileNavigationMenu}
          isOpen={isMobileNavigationMenuOpen}
          data-testid="header.menu.mobile"
        />
      </MobileMenuButtonBadge>

      <MobileMenuInner className={isMobileNavigationMenuOpen ? "open" : ""}>
        <MobileMenuBackground
          className={isMobileNavigationMenuOpen ? "open" : ""}
        >
          <MobileMenuContent
            className={isMobileNavigationMenuOpen ? "open" : ""}
          >
            <MobileMenuItemsList>
              {/* DatoCms navigation items */}
              {navigationItems.map(({ title, target }, index) => {
                const targetLocalPath = getTargetHref(target);
                return (
                  <LocalizedLink
                    to={targetLocalPath}
                    onClick={() => toggleMobileNavigationMenu()}
                    key={index}
                  >
                    <Typography variant="h5" component="span">
                      {title}
                    </Typography>
                  </LocalizedLink>
                );
              })}

              {/* SignIn, Create Account or Account Settings, Support, Sign Out links */}
              <Box
                borderTop="1px solid rgba(30, 30, 30, 0.4)"
                mt={2.25}
                pt={2}
                display="flex"
                flexDirection="column"
              >
                <NoSsr>
                  {/* Authenticated links */}
                  {isAuthenticated && (
                    <>
                      <LocalizedLink
                        to={`/user/account`}
                        onClick={() => toggleMobileNavigationMenu()}
                      >
                        <Typography variant="h5" component="span">
                          {t("header.accountSettings")}
                        </Typography>
                      </LocalizedLink>
                      <LocalizedLink
                        to="#"
                        onClick={e => {
                          e.preventDefault();
                          toggleMobileNavigationMenu();
                          handleLogoutClick();
                        }}
                      >
                        <Typography variant="h5" component="span">
                          {t("header.signOut")}
                        </Typography>
                      </LocalizedLink>
                    </>
                  )}

                  {/* Unauthenticated links */}
                  {!isAuthenticated && (
                    <>
                      <LocalizedLink
                        to={`/sign-in`}
                        onClick={() => toggleMobileNavigationMenu()}
                      >
                        <Typography variant="h5" component="span">
                          {t("header.signIn")}
                        </Typography>
                      </LocalizedLink>
                      <LocalizedLink
                        to={`/register`}
                        onClick={() => toggleMobileNavigationMenu()}
                      >
                        <Typography variant="h5" component="span">
                          {t("header.createAccount")}
                        </Typography>
                      </LocalizedLink>
                    </>
                  )}
                </NoSsr>
              </Box>
            </MobileMenuItemsList>

            {/* Country & language switcher buttons */}
            <NoSsr>
              <Box
                display="flex"
                alignItems="center"
                borderTop={`1px solid ${theme.palette.common.black}`}
                paddingTop={1.5}
              >
                <MobileCountryMenuButton />
                <MobileLanguageMenuButton />
              </Box>
            </NoSsr>
          </MobileMenuContent>
        </MobileMenuBackground>
      </MobileMenuInner>
    </MobileMenu>
  );
}

export default React.memo(MobileDropDownMenu, fastCompare);
