import { useContext, useContextSelector } from "use-context-selector";
import {
  StoreLocatorAddressContext,
  StoreLocatorAddressContextType
} from "../providers/store-locator-address";

export default function useStoreLocatorAddress() {
  return useContext(StoreLocatorAddressContext);
}

/**
 * Get partial state from StoreLocatorAddressContext. Better performance than using useStoreLocatorAddress
 */
export function useStoreLocatorAddressSelector<Selected>(
  selector: (value: StoreLocatorAddressContextType) => Selected
) {
  return useContextSelector(StoreLocatorAddressContext, selector);
}
