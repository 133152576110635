import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import storage from "local-storage-fallback";

export const REACT_QUERY_LOCALSTORAGE_KEY = "airgraft-query-cache";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 48, // 48 hours
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

const localStoragePersistor = createWebStoragePersistor({
  key: REACT_QUERY_LOCALSTORAGE_KEY,
  storage: storage as Storage
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  buster: "5" // 5: Invalidated query cache after adding new deliveryFreeDeliveryMinimum property
});

export default function ReactQueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
