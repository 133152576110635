import { Button, styled } from "@material-ui/core";
import React from "react";
import { useContextSelector } from "use-context-selector";
import { getAllLanguagesForCountry } from "../../../../helpers/country";
import useCountry from "../../../../hooks/use-country";
import useLang from "../../../../hooks/use-lang";
import useTranslate from "../../../../hooks/use-translate";
import { LayoutContext } from "../../../../providers/layout";
import LanguageIcon from "../../../../components/icons/language";

const CustomButton = styled(Button)(({ theme }) => ({
  "&.compact": {
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1)
    }
  }
}));

export default function MobileLanguageMenuButton() {
  const t = useTranslate();
  const language = useLang();
  const country = useCountry();
  const countryLanguages = getAllLanguagesForCountry(country);

  const label = t(`language.${language.toUpperCase()}`);

  const toggleMobileLanguageMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileLanguageMenu
  );

  if (!countryLanguages || countryLanguages.length <= 1) {
    return null;
  }

  return (
    <CustomButton
      variant="text"
      className="compact"
      startIcon={<LanguageIcon />}
      onClick={() => toggleMobileLanguageMenu(true)}
    >
      {label}
    </CustomButton>
  );
}
