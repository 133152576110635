import { CountryType } from "../src/types/airgraft";

export type FeatureFlagsType =
  | "SHOP_XPRESS" // Enable Shop XPRESS + Checkout
  | "SHOP_DISCOVER" // Enable Shop discover page
  | "SHOP_PLP" // Enable product lists pages
  | "SHOP_PLP_FILTERS" // Enable product lists filters
  | "SHOP_PRICE" // Enable prices everywhere
  | "SHOP_RETAIL_PROVIDERS" // Enable product page retail providers
  | "SHOP_PDP_VARIANTS_SELECTOR" // Enable product variants selector
  | "MY_PODS" // Enable "My Pods" user page
  | "POD_RATING" // Enable Pod rating
  | "UNLOCK_APP_LINKS" // Enable links to unlock pod inside header menu and account settings
  | "UNLOCK_APP" // Enable unlock pod. "This pod information is unavailable in your region"
  | "SHOP_PDP_ONTARIO_OCS_BUTTON" // Enable "Shop on OCS button"
  | "STORE_LOCATOR" // Enable Store locator
  | "HEADER_NOTIFICATIONS"; // Enable header notifications button & pane;

/**
 * Feature flags configuration for each country & subcountries
 * Make sure to restart your development server or redeploy after changing these flags.
 */
const FEATURE_FLAGS: Record<
  CountryType,
  Record<string, Partial<Record<FeatureFlagsType, boolean>>>
> = {
  US: {
    CA: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_XPRESS: true,
      SHOP_PLP_FILTERS: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      MY_PODS: true,
      POD_RATING: true,
      STORE_LOCATOR: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      UNLOCK_APP_LINKS: true,
      UNLOCK_APP: true,
      HEADER_NOTIFICATIONS: true
    },
    OH: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      STORE_LOCATOR: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      HEADER_NOTIFICATIONS: true
    },
    MO: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      STORE_LOCATOR: true,
      HEADER_NOTIFICATIONS: true
    },
    UT: {
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      HEADER_NOTIFICATIONS: true
    },
    WA: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      STORE_LOCATOR: true,
      HEADER_NOTIFICATIONS: true
    },
    CO: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      STORE_LOCATOR: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      HEADER_NOTIFICATIONS: true
    },
    NM: {
      SHOP_DISCOVER: true,
      SHOP_PLP: true,
      SHOP_PRICE: true,
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      MY_PODS: true,
      POD_RATING: true,
      STORE_LOCATOR: true,
      SHOP_PDP_VARIANTS_SELECTOR: true,
      HEADER_NOTIFICATIONS: true
    }
  },
  CA: {
    ON: {
      SHOP_PLP: true,
      SHOP_RETAIL_PROVIDERS: true,
      MY_PODS: true,
      POD_RATING: true,
      UNLOCK_APP: true,
      STORE_LOCATOR: true,
      HEADER_NOTIFICATIONS: true
    },
    AB: {
      SHOP_RETAIL_PROVIDERS: true,
      UNLOCK_APP: true,
      STORE_LOCATOR: true,
      HEADER_NOTIFICATIONS: true
    },
    CA_ALL: {
      SHOP_PLP: true,
      SHOP_RETAIL_PROVIDERS: true,
      MY_PODS: true,
      POD_RATING: true,
      UNLOCK_APP: true,
      HEADER_NOTIFICATIONS: true
    }
  }
};

/**
 * Disabled pages by each feature flags.
 * This means the pages wont be built
 */
export const FEATURE_FLAGS_DISABLED_PAGES: Partial<
  Record<FeatureFlagsType, string[]>
> = {
  SHOP_DISCOVER: ["/shop"],
  SHOP_PLP: ["/shop/products", "/shop/devices", "/shop/pods"],
  SHOP_XPRESS: ["/delivery/checkout*", "/delivery/order*", "/user/orders"],
  MY_PODS: ["/user/pods"],
  POD_RATING: ["/rating*"],
  STORE_LOCATOR: ["/store-locator"]
};

export default FEATURE_FLAGS;
