import { IconButton, IconButtonProps, styled, Theme } from "@material-ui/core";
import React from "react";
import CloseIcon from "../icons/close";

type ModalCloseButtonVariantType = "default" | "dark";

type Props = IconButtonProps & {
  variant?: ModalCloseButtonVariantType;
};

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.grey[200],
  width: "36px",
  height: "30px",
  borderRadius: "24px",
  border: `1px solid ${theme.palette.grey[300]}`,
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px"
  },
  "&:hover": {
    background: theme.palette.grey[300]
  },
  "&.variant--dark": {
    background: theme.palette.grey[300],
    border: `1px solid ${theme.palette.grey[400]}`,
    "&:hover": {
      background: theme.palette.grey[400]
    }
  }
}));

export default function ModalCloseButton(props: Props) {
  return (
    <CustomIconButton
      aria-label="close"
      {...props}
      className={`modal-close-button variant--${props.variant || "default"} ${
        props.className || ""
      }`}
      disableRipple
    >
      <CloseIcon />
    </CustomIconButton>
  );
}
