import React from "react";
import ThemeProvider from "./ThemeProvider";
import type { GatsbyBrowser } from "gatsby";

// Global custom fonts import definitions
import "../../src/theme/fonts/index.css";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
