import { Box, Typography } from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import React, { useRef, useState } from "react";
import { useContextSelector } from "use-context-selector";
import COUNTRIES from "../../../../../config/countries";
import {
  getAllLanguagesForCountry,
  getDefaultSubcountry,
  getSubcountries,
  getVisibleSubcountries
} from "../../../../helpers/country";
import { isPageDisabledByFeatureFlags } from "../../../../helpers/feature-flags";
import { getLocaleFromCountryLang } from "../../../../helpers/locale";
import { getLocalizedPath } from "../../../../helpers/path";
import useCountry from "../../../../hooks/use-country";
import useLang from "../../../../hooks/use-lang";
import useSubcountry from "../../../../hooks/use-subcountry";
import useTranslate from "../../../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../../../hooks/use-unlocalized-location-path";
import { LayoutContext } from "../../../../providers/layout";
import { analyticsIdentifyLanguage } from "../../../../services/analytics";
import { CountryType, LangType } from "../../../../types/airgraft";
import CanadaFlagIcon from "../../../../components/icons/flag-canada";
import UsaFlagIcon from "../../../../components/icons/flag-usa";
import { CustomPopover, HeaderButton, MenuItemLink } from "./styles";
import { PanelTitle } from "../../PanelBase/styles";

export default function DesktopCountryLanguageMenu() {
  const t = useTranslate();
  const unlocalizedPath = useUnlocalizedLocationPath();

  const currentLanguage = useLang();
  const currentCountry = useCountry();
  const { subcountry: selectedSubcountry, setSubcountry } = useSubcountry();
  const currentSubcountry =
    selectedSubcountry || getDefaultSubcountry(currentCountry);

  const countryLanguages = getAllLanguagesForCountry(currentCountry);

  const buttonRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useContextSelector(
    LayoutContext,
    c => [
      c.isDesktopCountryLanguageMenuOpen,
      c.toggleDesktopCountryLanguageMenu
    ]
  );

  const handleSubcountryMenuItemClick = (
    country: CountryType,
    subcountry: string
  ) => {
    setIsDropdownOpen(false);
    setSubcountry(subcountry, country);
  };

  const handleLanguageMenuItemClick = (lang: LangType) => {
    analyticsIdentifyLanguage(lang);
    setIsDropdownOpen(false);
  };

  const getMenuItemLinkTo = (
    country: CountryType,
    subcountry: string,
    language: LangType
  ) => {
    // Fallback to english if country doesnt have current language (Ex USA french)
    const targetLocale =
      getLocaleFromCountryLang(country, language) ||
      getLocaleFromCountryLang(country, "en");

    // Fallback to homepage if current page is disabled by feature flag in the other country
    if (
      isPageDisabledByFeatureFlags(country, unlocalizedPath, { subcountry })
    ) {
      return getLocalizedPath(targetLocale, "/");
    }

    return getLocalizedPath(targetLocale, unlocalizedPath);
  };

  return (
    <>
      {/* Country flag header button */}
      <HeaderButton onClick={() => setIsDropdownOpen(true)} ref={buttonRef}>
        {currentCountry === "US" && <UsaFlagIcon />}
        {currentCountry === "CA" && <CanadaFlagIcon />}
      </HeaderButton>

      {/* Popover menu */}
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        anchorEl={buttonRef?.current}
      >
        <Box mb={2} paddingX={3}>
          <PanelTitle>{t("header.location")}</PanelTitle>
        </Box>

        {/* Countries */}
        {Object.keys(COUNTRIES).map((country: CountryType) => (
          <React.Fragment key={country}>
            <Box paddingX={3}>
              <Typography className="compact" color="textSecondary">
                {t(`country.${country}`)}
              </Typography>
            </Box>

            <Box mb={2}>
              {getVisibleSubcountries(country).map(sc => (
                <MenuItemLink
                  key={`${country}-${sc.value}`}
                  to={getMenuItemLinkTo(country, sc.value, currentLanguage)}
                  onClick={() =>
                    handleSubcountryMenuItemClick(country, sc.value)
                  }
                >
                  {t(`subcountry.${sc.value.toUpperCase()}`)}
                  {currentCountry === country &&
                    currentSubcountry === sc.value && <CheckRoundedIcon />}
                </MenuItemLink>
              ))}
            </Box>
          </React.Fragment>
        ))}

        {/* Languages */}
        {countryLanguages.length > 1 && (
          <>
            <Box width="100%" height="1px" bgcolor="grey.300" />

            <Box mt={3} mb={2} paddingX={3}>
              <Typography variant="h5">{t("header.language")}</Typography>
            </Box>

            <Box mb={2}>
              {countryLanguages.map(language => (
                <MenuItemLink
                  key={language}
                  to={getMenuItemLinkTo(
                    currentCountry,
                    currentSubcountry,
                    language
                  )}
                  onClick={() => handleLanguageMenuItemClick(language)}
                >
                  {t(`language.${language.toUpperCase()}`)}
                  {language === currentLanguage && <CheckRoundedIcon />}
                </MenuItemLink>
              ))}
            </Box>
          </>
        )}
      </CustomPopover>
    </>
  );
}
