import { IconButton, Popover, styled } from "@material-ui/core";
import { Link } from "gatsby";

export const MenuItemLink = styled(Link)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(1, 3, 1, 4.5),
  fontSize: `2rem`,
  lineHeight: `2.4rem`,
  fontFamily: "Reckless, Georgia",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[200]
  }
}));

export const CustomPopover = styled(Popover)(({ theme }) => ({
  "& > .MuiPaper-root": {
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(3.15),
    backgroundColor: theme.palette.common.white,
    minWidth: `335px`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    boxShadow: `6px 3px 10px rgba(0, 0, 0, 0.14)`,
    overflow: `visible`
  }
}));

export const HeaderButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: `36px`,
  height: `36px`,
  borderRadius: `36px`,
  backgroundColor: theme.palette.grey[200],
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: 0,
  marginRight: theme.spacing(1.5),
  "&:hover": {
    backgroundColor: theme.palette.grey[300]
  },
  "& svg": {
    width: "28px",
    height: "28px"
  }
}));
