import React from "react";
import MaskedInput from "react-text-mask";
import moment from "moment-timezone";

export const BIRTHDAY_DATE_FORMAT = "MM/DD/YYYY";

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export default function BirthdayInput(props: Props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      // prettier-ignore
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
}

export function getLegalAgeValidator(
  legalAge: number,
  translate: (key: string, options?: Record<string, string | number>) => string
) {
  return (value: string) => {
    const birthday = moment(value, BIRTHDAY_DATE_FORMAT, true);
    const twentyOneYearsAgo = moment().subtract("years", legalAge);
    if (!birthday.isValid() || birthday.year() <= 1900) {
      return translate("wecomeModal.error.birthdayFormat", {
        dateFormat: BIRTHDAY_DATE_FORMAT
      });
    } else if (twentyOneYearsAgo.isBefore(birthday)) {
      return translate("wecomeModal.error.legalAge", { legalAge });
    }
    return true;
  };
}

export function formatFormBirthdayDateToApiDate(birthday: string) {
  return moment(birthday, BIRTHDAY_DATE_FORMAT, true).format("YYYY-MM-DD");
}

export function formatApiDateToFormBirthdayDate(birthday: string) {
  return moment(birthday, "YYYY-MM-DD", true).format(BIRTHDAY_DATE_FORMAT);
}
