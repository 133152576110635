import React, { useState, useEffect } from "react";
import { isBrowser } from "./platform-detect";
import storage from "local-storage-fallback";

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    let stickyValue = null;
    if (isBrowser()) {
      stickyValue = storage.getItem(key);
    }
    return stickyValue !== null && stickyValue !== undefined
      ? safeJsonParse(stickyValue)
      : defaultValue;
  });

  // For SSR, load localstorage value after page load
  useEffect(() => {
    let stickyValue = null;
    if (isBrowser()) {
      stickyValue = storage.getItem(key);
    }
    if (stickyValue !== null && stickyValue !== undefined) {
      setValue(safeJsonParse(stickyValue));
    }
  }, []);

  // Sync localstorage value with locale state value
  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export function safeJsonParse(text: string) {
  try {
    const value = JSON.parse(text);
    return value;
  } catch (e) {
    return null;
  }
}
