import { stringify } from "query-string";
import COUNTRIES from "../../../config/countries";
import { NETLIFY_FUNCTIONS_BASE_URL } from "../../services/netlify-functions";
import { CountryType, DeliveryAddressType } from "../../types/airgraft";

export function getDeliveryAddressShortName(
  deliveryAddress: DeliveryAddressType
) {
  if (deliveryAddress.addressOne) {
    return shortenAddress(deliveryAddress.addressOne);
  }
  return shortenAddress(getDeliveryAddressLongName(deliveryAddress));
}

export function getDeliveryAddressLongName(
  deliveryAddress: DeliveryAddressType
) {
  // prettier-ignore
  const name = `${deliveryAddress.addressOne ? shortenAddress(deliveryAddress.addressOne) : ''}${deliveryAddress.addressOne && deliveryAddress.city ? ', ' : ''}${deliveryAddress.city}${deliveryAddress.state ? ', ' : ''}${deliveryAddress.state}${deliveryAddress.country ? ', ' : ''}${deliveryAddress.country}`;
  return name;
}

export function shortenAddress(address: string) {
  let shortAddress = address;
  shortAddress = shortAddress.replace(/boulevard/gi, "Blvd");
  shortAddress = shortAddress.replace(/avenue/gi, "Ave");
  shortAddress = shortAddress.replace(/street/gi, "St");
  shortAddress = shortAddress.replace(/road/gi, "Rd");
  return shortAddress;
}

export async function fetchDeliveryAddressByPlaceId(
  placeId: string,
  addressTwo?: string
): Promise<DeliveryAddressType> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-place-to-address?${stringify({
      placeId,
      addressTwo
    })}`
  );
  const data = await response.json();
  if (!data || data.error) {
    return null;
  }
  return data as DeliveryAddressType;
}

export async function fetchDeliveryAddressByGeolocation(position: {
  lat: string | number;
  lng: string | number;
}): Promise<DeliveryAddressType> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-geolocation-to-address?${stringify({
      lat: position.lat,
      lng: position.lng
    })}`
  );
  const data = await response.json();
  if (!data || data.error) {
    return null;
  }
  return data as DeliveryAddressType;
}

/**
 * Get website country & subcountry matching with delivery address
 */
export function getDeliveryAddressMatchingSubcountry(
  address: DeliveryAddressType
): {
  country: CountryType;
  subcountry: string;
} {
  // Detect matching subcountry
  const matchingCountry =
    address?.country &&
    Object.keys(COUNTRIES).includes(address.country.toUpperCase())
      ? (address.country.toUpperCase() as CountryType)
      : null;
  const matchingSubcountry =
    matchingCountry &&
    address?.state &&
    COUNTRIES[matchingCountry].subcountries.reduce<string>(
      (accumulator, subcountryConfig) => {
        if (subcountryConfig.value === address.state.toUpperCase()) {
          return subcountryConfig.value;
        }
        return accumulator;
      },
      null
    );

  return {
    country: matchingCountry,
    subcountry: matchingSubcountry
  };
}
