import {
  Box,
  Button,
  Hidden,
  NoSsr,
  styled,
  Typography
} from "@material-ui/core";
import truncate from "lodash/truncate";
import React, { useRef } from "react";
import { useExpressDeliveryAvailabilitySelector } from "../../../hooks/use-express-delivery-availability";
import ExpressDeliveryAvailabilityPanel from "../ExpressDeliveryAvailabilityPanel";
import ExpressDeliveryLogo from "../../express-delivery/ExpressDeliveryLogo";
import ChevronDownIcon from "../../../components/icons/chevron-down";
import useIsMounted from "../../../hooks/use-is-mounted";
import { getDeliveryAddressShortName } from "../../../helpers/express-delivery/address";
import useTranslate from "../../../hooks/use-translate";

export const HEADER_AVAILABILITY_BUTTON_MOBILE_HEIGHT = "40px";

const AddressButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  background: theme.palette.grey[200],
  minHeight: "36px",
  fontFamily: "Roobert, Arial",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  letterSpacing: "0px",
  paddingTop: "0px",
  paddingBottom: "0px",
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  marginLeft: theme.spacing(2),
  textTransform: "none",
  "& strong": {
    fontWeight: 600
  },
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      background: theme.palette.grey[300]
    },
    "& .MuiButton-startIcon img": {
      height: "11px"
    }
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "&.with-value": {
      justifyContent: "flex-start"
    },
    background: theme.palette.common.white,
    minHeight: HEADER_AVAILABILITY_BUTTON_MOBILE_HEIGHT,
    margin: 0,
    padding: theme.spacing(0, 2.5),
    borderRadius: 0,
    border: 0,
    "&:hover": {
      background: theme.palette.common.white
    },
    "& .MuiButton-startIcon img": {
      height: "14px"
    },
    "& .chrevron-down": {
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(1.5)
    }
  },
  "@media (max-width: 320px)": {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    "& .chrevron-down": {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(1.5)
    }
  }
}));

type Props = {
  hidden?: boolean;
  withPanel?: boolean;
};

function ExpressDeliveryHeaderAvailabilityButton({ withPanel, hidden }: Props) {
  const t = useTranslate();
  const isMounted = useIsMounted(); // Prevent issue with buttonWrapperRef being null if component only renders once

  const buttonWrapperRef = useRef<HTMLDivElement>();

  const toggleAvailabilityPanel = useExpressDeliveryAvailabilitySelector(
    c => c.toggleAvailabilityPanel
  );
  const address = useExpressDeliveryAvailabilitySelector(c => c.address);
  const isLoadingAddress = useExpressDeliveryAvailabilitySelector(
    c => c.isLoadingAddress
  );

  return (
    <>
      {/* Popover is attached to this outer div to match the other desktop header buttons (cart, chat, etc) height and have exact same position */}
      <Box
        ref={buttonWrapperRef}
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid="express-availability-button"
      >
        {!hidden && (
          <AddressButton
            onClick={() => toggleAvailabilityPanel()}
            variant="outlined"
            className={address ? "with-value" : "no-value"}
            disableRipple
            startIcon={<ExpressDeliveryLogo />}
          >
            {/* Desktop & Mobile Orange circle dot */}
            <Box
              bgcolor="expressDelivery"
              width="6px"
              height="6px"
              borderRadius="6px"
              mr={1}
              flexShrink={0}
              display={{ xs: address ? "block" : "none", md: "block" }}
              key={`${!!address}`}
            />

            {/* Current address value */}
            {address && (
              <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                {truncate(getDeliveryAddressShortName(address), { length: 20 })}
              </Typography>
            )}

            {/* Desktop "Add your location" text */}
            {!address && (
              <Hidden smDown implementation="css">
                <Typography
                  className="compact"
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  {isLoadingAddress && t("expressDelivery.loadingLocation")}
                  {!isLoadingAddress && t("expressDelivery.addYourLocation")}
                </Typography>
              </Hidden>
            )}

            {/* Mobile w/o address: "Add your location" button */}
            {!address && !isLoadingAddress && (
              <NoSsr>
                <Hidden mdUp implementation="css">
                  <Box
                    height="24px"
                    borderRadius="24px"
                    bgcolor="expressDelivery"
                    color="white"
                    paddingX="20px"
                  >
                    <Typography
                      className="compact"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("expressDelivery.addYourLocation")}
                    </Typography>
                  </Box>
                </Hidden>
              </NoSsr>
            )}

            {/* Mobile w/ adress: Chevron down icon */}
            {(address || isLoadingAddress) && (
              <Hidden mdUp implementation="css">
                <ChevronDownIcon
                  fontSize="small"
                  color="secondary"
                  className="chrevron-down"
                />
              </Hidden>
            )}
          </AddressButton>
        )}
      </Box>

      {/* Address panel */}
      {!hidden && withPanel && isMounted && (
        <ExpressDeliveryAvailabilityPanel
          anchorEl={buttonWrapperRef?.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        />
      )}
    </>
  );
}

export default React.memo(ExpressDeliveryHeaderAvailabilityButton);
