import { useContext, useContextSelector } from "use-context-selector";
import {
  AuthenticationContext,
  AuthContextType
} from "../providers/authentication";

const useAuth = () => {
  return useContext(AuthenticationContext);
};

/**
 * Get partial state from AuthenticationContext. Better performance than using useAuth
 */
export function useAuthContextSelector<Selected>(
  selector: (value: AuthContextType) => Selected
) {
  return useContextSelector(AuthenticationContext, selector);
}

export default useAuth;
