import { Box, PopoverProps, useTheme } from "@material-ui/core";
import React from "react";
import useNotifications from "../../../hooks/use-notification";
import useTranslate from "../../../hooks/use-translate";
import { PanelTitle } from "../PanelBase/styles";
import EmptyList from "./components/EmptyList";
import NotificationPanelList from "./components/NotificationsList";
import { CustomPanelBase } from "./styles";

function NotificationPanel({
  anchorEl,
  ...props
}: Pick<PopoverProps, "anchorEl" | "anchorOrigin" | "transformOrigin">) {
  const t = useTranslate();
  const theme = useTheme();

  const {
    notifications,
    isNotificationMenuOpen,
    toggleNotificationMenuOpen,
    readNotifications
  } = useNotifications();

  function closePanelAndReadNotifications() {
    toggleNotificationMenuOpen(false);
    readNotifications(notifications);
  }

  return (
    <CustomPanelBase
      open={isNotificationMenuOpen}
      onClose={closePanelAndReadNotifications}
      anchorEl={anchorEl}
      {...props}
    >
      <Box
        paddingY={2}
        minHeight={{
          xs: "calc(var(--100vh) - var(--header-height))",
          md: "0px"
        }}
        maxHeight={{
          xs: "calc(var(--100vh) - var(--header-height))",
          md: "min(820px, calc(var(--100vh) - var(--header-height) - 32px))"
        }}
        display="flex"
        flexDirection="column"
      >
        {/* Header */}
        <Box
          pb={2}
          marginX={{ xs: 2, md: 2.5 }}
          flexShrink={0}
          mb={1}
          borderBottom={`1px solid ${theme.palette.grey[200]}`}
        >
          <PanelTitle>{t("notification.title")}</PanelTitle>
        </Box>

        {/* List */}
        {notifications?.length > 0 && (
          <NotificationPanelList
            notifications={notifications}
            onNotificationClick={closePanelAndReadNotifications}
          />
        )}

        {(!notifications || notifications.length === 0) && (
          <EmptyList onLinkClick={closePanelAndReadNotifications} />
        )}
      </Box>
    </CustomPanelBase>
  );
}

export default React.memo(NotificationPanel);
