export const RATING_BASE_URL = "/rating";

export const RATING_LABELS = {
  1: "Awful",
  2: "Poor",
  3: "Fair",
  4: "Good",
  5: "Excellent"
};

export const RATING_DETAILS = {
  POSITIVE: [
    "Good vapor",
    "Good high",
    "Smooth taste",
    "Tasty flavor",
    "Good value"
  ],
  NEGATIVE: [
    "Low vapor",
    "Inconsistent",
    "Unpleasant high",
    "Leaking",
    "Clogging",
    "Burnt/harsh taste",
    "Bad flavor",
    "Poor value"
  ]
};

export const RATING_EFFECTS = [
  "euphoric",
  "energized",
  "tasty",
  "relaxed",
  "creative",
  "focused",
  "sleepy",
  "uplifted",
  "happy"
];
