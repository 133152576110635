import React from "react";
import { Box, Button, Typography, useTheme } from "@material-ui/core";
import useTranslate from "../../../hooks/use-translate";
import { isGoogleBot } from "../../../helpers/platform-detect";
import BasicModal from "../../../components/basic-modal";
import ExpressDeliveryLogo from "../../express-delivery/ExpressDeliveryLogo";
import useUnlocalizedLocationPath from "../../../hooks/use-unlocalized-location-path";
import { SHOP_BASEPATH } from "../../../helpers/url";
import { useLocalStorageState } from "../../../helpers/localstorage";
import useIsAuthenticated from "../../../hooks/use-is-authenticated";
import useFeatureFlag from "../../../hooks/use-feature-flag";
import { BooleanParam, useQueryParam } from "use-query-params";

export default function XpressLegalModal() {
  const t = useTranslate();
  const pathname = useUnlocalizedLocationPath();
  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const isXpressEnabled = useFeatureFlag("SHOP_XPRESS");

  const [disableModalQuery] = useQueryParam("disable-modal", BooleanParam);

  const [agreedLegalStatement, setAgreedLegalStatement] = useLocalStorageState(
    "airgraft-express-delivery-legal-accepted",
    false
  );

  const handleAgree = () => {
    setAgreedLegalStatement(true);
  };

  const showModal =
    !disableModalQuery &&
    new RegExp(`${SHOP_BASEPATH}(\/)?`).test(pathname) &&
    isXpressEnabled &&
    !isAuthenticated &&
    !agreedLegalStatement &&
    !isGoogleBot();

  return (
    <BasicModal
      open={showModal}
      BoxProps={{
        maxWidth: {
          xs: `calc(100vw - ${theme.spacing(6)}px)`,
          sm: `540px`
        }
      }}
    >
      <>
        <Box mb={2} mt={1.25}>
          <Typography variant="h5" align="center">
            {t("xpressLegalModal.title")}
          </Typography>
        </Box>
        <Box mb={3} textAlign="center">
          <ExpressDeliveryLogo height="24px" />
        </Box>
        <Box mb={2}>
          <Typography variant="body2" align="center">
            {t("xpressLegalModal.line1")}
          </Typography>
        </Box>
        <Box mb={3.5}>
          <Typography variant="body2" align="center">
            {t("xpressLegalModal.line2")}
          </Typography>
        </Box>

        <Box mb={0.75}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleAgree}
          >
            {t("xpressLegalModal.agree")}
          </Button>
        </Box>
      </>
    </BasicModal>
  );
}
