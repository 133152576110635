import React, { useEffect, useState } from "react";
import {
  HeaderLogoContent,
  HeaderContainer,
  HeaderLeftContent,
  HeaderPlaceholder,
  HeaderRightContent,
  HeaderSubContent,
  BaseHeaderWrapper,
  LogoLink
} from "./styles";
import { LayoutContext } from "../../../providers/layout";
import { useContextSelector } from "use-context-selector";
import { HeaderVariantType } from "..";
import HeaderLogo from "./HeaderLogo";

type Props = {
  variant: HeaderVariantType;
  leftChildren?: JSX.Element;
  rightChildren?: JSX.Element;
  subHeader?: JSX.Element;
};

export default function BaseHeader({
  variant,
  leftChildren,
  rightChildren,
  subHeader
}: Props) {
  const headerHeight = 64 + (subHeader ? 50 : 0);
  const isMobileHeaderOverModal = useContextSelector(
    LayoutContext,
    c => c.isMobileHeaderOverModal
  );

  // Show/Hide header when user scroll down the page
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  // Hide/Show header when scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > headerHeight) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrolledDown]);

  return (
    <HeaderPlaceholder hasSubChild={!!subHeader}>
      <BaseHeaderWrapper
        variant={variant}
        isHidden={isScrolledDown && !isMobileHeaderOverModal}
        isOverModal={isMobileHeaderOverModal}
        isScrolledDown={isScrolledDown}
      >
        <HeaderContainer maxWidth="xl" noBackground={variant === "overlaps"}>
          {leftChildren && (
            <HeaderLeftContent>{leftChildren}</HeaderLeftContent>
          )}

          <HeaderLogoContent>
            <LogoLink to="/">
              <HeaderLogo className="header-logo" />
            </LogoLink>
          </HeaderLogoContent>

          <HeaderRightContent>{rightChildren}</HeaderRightContent>
        </HeaderContainer>

        {subHeader && <HeaderSubContent>{subHeader}</HeaderSubContent>}
      </BaseHeaderWrapper>
    </HeaderPlaceholder>
  );
}
