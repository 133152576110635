import { Container, styled, Theme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { HEADER_AVAILABILITY_BUTTON_MOBILE_HEIGHT } from "../ExpressDeliveryAvailabilityButton";
import LocalizedLink from "../../../components/localized-link";

export const BaseHeaderWrapper = styled(
  ({ variant, isHidden, isOverModal, isScrolledDown, className, ...props }) => (
    <div
      className={clsx([
        className,
        "mui-fixed",
        {
          [`variant--${variant}`]: true,
          isHidden: isHidden,
          isOverModal: isOverModal,
          isScrolledDown: isScrolledDown
        }
      ])}
      {...props}
    />
  )
)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.035))",
  backgroundColor: theme.palette.grey[100],
  paddingTop: "env(safe-area-inset-top)",
  boxSizing: "content-box",
  height: "var(--header-height)",
  transform: "translateY(0px)",
  transition: theme.transitions.create(["transform", "background"], {
    duration: 200,
    easing: "ease",
    delay: 0
  }),
  "& .header-logo": {
    width: "auto",
    height: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(2.25)
    }
  },
  // Header on desktop homepage "overlaps" effect
  [theme.breakpoints.up("md")]: {
    "&.variant--overlaps:not(.isScrolledDown)": {
      filter: "none",
      backgroundColor: "transparent",
      transition: theme.transitions.create(["transform", "background"], {
        duration: 150,
        easing: "ease",
        delay: 0
      }),
      "& .header-logo": {
        color: theme.palette.common.white
      }
    }
  },
  // Hide header after scrolling down the page
  "&.isHidden": {
    [theme.breakpoints.down("xs")]: {
      transform:
        "translateY(calc(-1 * var(--header-height) - env(safe-area-inset-top)))"
    },
    "&.variant--homepage": {
      transform:
        "translateY(calc(-1 * var(--header-height) - env(safe-area-inset-top)))"
    }
  },
  // Show header on top of modal backdrop
  "&.isOverModal": {
    [theme.breakpoints.down("sm")]: {
      zIndex: theme.zIndex.modal + 1
    }
  }
}));

export const HeaderPlaceholder = styled(
  ({ hasSubChild, className, ...props }) => (
    <div {...props} className={clsx([className, { hasSubChild }])} />
  )
)({
  height: "var(--header-height)",
  "&.hasSubChild": {
    height: `calc(var(--header-height) + ${HEADER_AVAILABILITY_BUTTON_MOBILE_HEIGHT})`
  }
});

export const HeaderContainer = styled(Container)(
  ({ noBackground, theme }: { noBackground: boolean; theme: Theme }) => ({
    backgroundColor: noBackground ? "transparent" : theme.palette.grey[100],
    zIndex: theme.zIndex.modal,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    height: "100%"
  })
);

export const HeaderSideContent = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  flex: "1"
}));

export const HeaderLeftContent = styled(HeaderSideContent)(({ theme }) => ({
  justifyContent: "flex-start"
}));

export const HeaderRightContent = styled(HeaderSideContent)(({ theme }) => ({
  justifyContent: "flex-end"
}));

export const HeaderLogoContent = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start"
  }
}));

export const HeaderSubContent = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 100%",
  "& > div": {
    width: "100%"
  }
}));

export const LogoLink = styled(LocalizedLink)(({ theme }) => ({
  height: "auto",
  display: "flex",
  alignItems: "center"
}));
