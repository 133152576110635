import { graphql, useStaticQuery } from "gatsby";
import _unique from "lodash/uniq";
import React from "react";
import { useToggle } from "react-use";
import { createContext } from "use-context-selector";
import { useLocalStorageState } from "../helpers/localstorage";
import { getDefaultProductVariant } from "../helpers/product";
import useActivatedPodHistory from "../hooks/use-activated-pod-history";
import useIsAuthenticated from "../hooks/use-is-authenticated";
import useLocale from "../hooks/use-locale";
import useProductQuery from "../hooks/use-product-query";
import useSubcountry from "../hooks/use-subcountry";
import useTranslate from "../hooks/use-translate";
import { LocaleType } from "../types/airgraft";

export const NOTIFICATION_LOCALSTORAGE_KEY = `airgraft-read-notifications`;

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <NotificationProvider>."
  );
};

export type NotificationType =
  Queries.NotificationsFragmentQuery["allDatoCmsNotification"]["nodes"][number] & {
    unread?: boolean;
  };

export interface NotificationContextType {
  /**
   * Is notification menu open? Default: false
   */
  isNotificationMenuOpen: boolean;
  toggleNotificationMenuOpen: (open?: boolean) => void;

  /**
   * Current notifications based on website region and user context
   */
  notifications: NotificationType[];

  /**
   * Unread notifications count for header badge
   */
  unreadNotificationsCount: number;

  /**
   * Mark notification as read
   */
  readNotifications: (notifications: NotificationType[]) => void;
}

const NotificationContext = createContext<NotificationContextType>({
  isNotificationMenuOpen: false,
  toggleNotificationMenuOpen: stub,
  notifications: [],
  unreadNotificationsCount: 0,
  readNotifications: stub
});

const NotificationProvider = ({ children }) => {
  const locale = useLocale();
  const isAuthenticated = useIsAuthenticated();
  const { subcountry } = useSubcountry();

  const [isNotificationMenuOpen, toggleNotificationMenuOpen] = useToggle(false);

  // DatoCMS query for custom notifications
  const {
    allDatoCmsNotification: { nodes: allNotifications }
  } = useStaticQuery(query);

  let notifications = filterNotifications(allNotifications, {
    locale,
    isAuthenticated,
    subcountry
  });

  // Add pod rating notifications and reorder by date
  const podRatingNotifications = usePodRatingNotifications();
  notifications.push(...podRatingNotifications);

  // Read notification ids from localstorage
  const [readNotificationIds, setReadNotificationIds] = useLocalStorageState<
    string[]
  >(NOTIFICATION_LOCALSTORAGE_KEY, []);

  function readNotifications(notifications: NotificationType[]) {
    setReadNotificationIds(
      _unique([...readNotificationIds, ...notifications.map(n => n.originalId)])
    );
  }

  // Unread notifications count
  notifications = notifications.map(notification => {
    notification.unread =
      readNotificationIds &&
      Array.isArray(readNotificationIds) &&
      !readNotificationIds.includes(notification.originalId);
    return notification;
  });
  const unreadNotificationsCount = notifications.filter(n => n.unread).length;

  // Resort notifications to bump newest to the top
  notifications = sortNotifications(notifications);

  return (
    <NotificationContext.Provider
      value={{
        isNotificationMenuOpen,
        toggleNotificationMenuOpen,
        notifications,
        unreadNotificationsCount,
        readNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };

/**
 * Sort notifications
 */
function sortNotifications(notifications: NotificationType[]) {
  return notifications.sort((a, b) => {
    // Prioritize items with "new" set to true
    if (a.unread && !b.unread) {
      return -1;
    } else if (!a.unread && b.unread) {
      return 1;
    }
    const dateA = new Date(a.meta.publishedAt || a.meta.createdAt);
    const dateB = new Date(b.meta.publishedAt || b.meta.createdAt);
    return dateB.getTime() - dateA.getTime();
  });
}

/**
 * Filter notifications that should be visible to the user based on context
 */
function filterNotifications(
  notifications: NotificationType[],
  {
    locale,
    subcountry,
    isAuthenticated
  }: { locale: LocaleType; subcountry: string; isAuthenticated: boolean }
) {
  const today = new Date();

  return notifications.filter(notification => {
    if (notification.locale !== locale) {
      return false;
    }

    if (notification.expiresAfter) {
      const expiresAfterDate = new Date(notification.expiresAfter);
      if (expiresAfterDate < today) {
        return false;
      }
    }

    const visibilitySettings =
      notification?.visibilitySettings &&
      notification.visibilitySettings.length > 0
        ? notification.visibilitySettings[0]
        : null;

    if (
      visibilitySettings?.authentication === "authenticated" &&
      !isAuthenticated
    ) {
      return false;
    }
    if (
      visibilitySettings?.authentication === "unauthenticated" &&
      isAuthenticated
    ) {
      return false;
    }

    if (
      visibilitySettings?.subcountries?.length > 0 &&
      !visibilitySettings?.subcountries.find(s => s.slug === subcountry)
    ) {
      return false;
    }

    return true;
  });
}

/**
 * Helper hook to get automatic pod rating notifications based on pod history
 */
function usePodRatingNotifications(): NotificationType[] {
  const locale = useLocale();
  const isAuthenticated = useIsAuthenticated();
  const t = useTranslate();

  // Query for pod ratings notifications
  const { data: podHistoryData } = useActivatedPodHistory({
    enabled: isAuthenticated
  });

  // Find pods unlocked over 1 hour ago
  const availablePodsForRating =
    podHistoryData?.content.filter(podHistory => {
      if (
        !podHistory?.podKey ||
        !podHistory?.batchId ||
        !!podHistory.userRating
      ) {
        return false; // Ignore pod history without pod key or batch id
      }
      const unlockedAt = new Date(podHistory.createdDate);
      const isUnlockedAfter1Hour =
        new Date().getTime() - unlockedAt.getTime() > 60 * 60 * 1000;
      const isUnlockedBefore30Days =
        new Date().getTime() - unlockedAt.getTime() < 60 * 60 * 1000 * 24 * 30;
      return isUnlockedAfter1Hour && isUnlockedBefore30Days;
    }) || [];

  const lastUnlockedPodHistory =
    availablePodsForRating?.length > 0 ? availablePodsForRating[0] : null;

  const { data: product } = useProductQuery(
    { batchId: lastUnlockedPodHistory?.batchId },
    { enabled: !!lastUnlockedPodHistory }
  );
  const productVariant = product ? getDefaultProductVariant(product) : null;

  const podRatingNotifications: NotificationType[] = [];

  if (lastUnlockedPodHistory && !!productVariant) {
    podRatingNotifications.push({
      originalId: `pod-rating-${lastUnlockedPodHistory.batchId}`,
      locale,
      text: t("notification.podRating.text", {
        title: productVariant.title,
        brand: productVariant.brand.name
      }),
      category: t("notification.podRating.category"),
      expiresAfter: null,
      meta: {
        publishedAt: null,
        createdAt: lastUnlockedPodHistory.createdDate
      },
      link: {
        url: `/rating/${lastUnlockedPodHistory.podKey}`,
        model: {
          apiKey: "card_external_link"
        }
      },
      image: {
        url: productVariant.featuredImage.url
      },
      visibilitySettings: null
    });
  }

  return podRatingNotifications;
}

const query = graphql`
  query NotificationsFragment {
    allDatoCmsNotification(sort: { order: DESC, fields: meta___createdAt }) {
      nodes {
        originalId
        locale
        text
        category
        meta {
          publishedAt
          createdAt
        }
        link {
          ... on DatoCmsProduct {
            slug
            productType
            variants {
              featuredImage {
                url
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsWebPage {
            path
            model {
              apiKey
            }
          }
          ... on DatoCmsCardExternalLink {
            url
            model {
              apiKey
            }
          }
        }
        image {
          url
        }
        visibilitySettings {
          ...ContentModuleVisibilitySettingFragment
        }
        expiresAfter
      }
    }
  }
`;
