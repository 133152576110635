import moment, { Locale } from "moment-timezone";
import {
  CountryType,
  LocaleType,
  ProductListItemType,
  ProductType,
  ProductVariantListItemType,
  ProductVariantType
} from "../types/airgraft";
import {
  getAllProductVisibilityFieldsForCountry,
  getCountryAndLanguageFromLocale,
  getProductVisibilityFieldsForSubcountry,
  getSubcountryLabel
} from "./country";
import COUNTRIES from "../../config/countries";

/**
 * Get the default product variant associated to a product
 */
export function getDefaultProductVariant(
  product: ProductType
): ProductVariantType {
  return product.variants?.length > 0 ? product.variants[0] : null;
}

/**
 * Get the default product variant associated to a product
 */
export function getDefaultProductItemVariant(
  product: ProductListItemType
): ProductVariantListItemType {
  return product.variants?.length > 0 ? product.variants[0] : null;
}

export function filterProductItemsWithDefaultVariant(
  products: ReadonlyArray<ProductListItemType>
) {
  return products.filter(p => !!getDefaultProductItemVariant(p));
}

/**
 * Filter products available for specific country.
 */
export function filterProductsAvailableForSubcountry(
  products: ReadonlyArray<ProductListItemType>,
  country: CountryType,
  subcountry: string
) {
  const visibilityField = getProductVisibilityFieldsForSubcountry(
    country,
    subcountry
  );
  return products.filter(p => !!p[visibilityField]);
}

/**
 * Check if a product is available in any of the subcountries of a country
 * Ex: isProductAvailableForCountry('papaya-slug', 'CA') => true
 */
export function isProductAvailableForCountry(
  product: Record<string, any>,
  country: CountryType
) {
  const visibilityFields = getAllProductVisibilityFieldsForCountry(country);
  return visibilityFields.reduce(
    (accumulator, field) => product[field] === true || accumulator,
    false
  );
}

/**
 * Check if a product is available for a subcountry
 * Ex: isProductAvailableForSubcountry('papaya-slug', 'CA', 'ON') => true
 */
export function isProductAvailableForSubcountry(
  product: Record<string, any>,
  country: CountryType,
  subcountry: string
) {
  const visibilityField = getProductVisibilityFieldsForSubcountry(
    country,
    subcountry
  );
  return !!product[visibilityField];
}

/**
 * Get product weight in grams
 */
export function getProductVariantWeightLabel(
  productVariant: ProductVariantListItemType | ProductVariantType
): string {
  const variantDetail = productVariant?.variantDetail;
  const coaReport = variantDetail?.coaReport;
  if (coaReport?.weightText) {
    return coaReport.weightText;
  }
  if (variantDetail?.grams) {
    return `${variantDetail.grams}g`;
  }
  return null;
}

/**
 * Get "Coming Soon" or Date based on product variant availability and availabilityComingSoonDate fields
 */
export function getProductVariantComingSoonLabel(
  productVariant: ProductVariantListItemType | ProductVariantType,
  options: {
    format: "short" | "long";
    isSSR: boolean;
  }
): string {
  if (productVariant.availability !== "coming-soon") {
    return null;
  }

  const defaultLabel = "Coming Soon";

  // Show default label during first ssr pre-build to prevent hydration issue with logic related to current date
  if (options.isSSR) {
    return defaultLabel;
  }

  const comingSoonDate = productVariant.availabilityComingSoonDate
    ? moment(productVariant.availabilityComingSoonDate)
    : null;

  if (comingSoonDate) {
    // Prevent showing a Coming Soon date in the past in case they forgot to update the date
    const isComingSoonDateInThePast = comingSoonDate
      .startOf("month")
      .isBefore(moment().startOf("month"));
    if (isComingSoonDateInThePast) {
      return defaultLabel;
    }

    if (options.format === "short") {
      return comingSoonDate.format("MMMM ‘YY");
    }
    return `Available ${comingSoonDate.format("MMMM YYYY")}`;
  }

  return defaultLabel;
}

/**
 * Helper for klaviyo product feed. Get product available subcountries as categories
 * Ex: ['USA - California', 'Canada - Ontario']
 */
export function getProductAvailableSubcountriesAsKlaviyoCategories(
  product: Record<string, any>
): string[] {
  const { country } = getCountryAndLanguageFromLocale(
    product.locale as LocaleType
  );

  const subcountries = COUNTRIES[country].subcountries;
  const categories = subcountries.reduce((subaccumulator, subcountry) => {
    if (isProductAvailableForSubcountry(product, country, subcountry.value)) {
      let countryLabel = `${country}`;
      if (countryLabel === "US") {
        countryLabel = "USA";
      }
      if (countryLabel === "CA") {
        countryLabel = "Canada";
      }
      return [
        ...subaccumulator,
        `${countryLabel} (${getSubcountryLabel(subcountry.value)})`
      ];
    }
    return subaccumulator;
  }, []);

  return categories;
}
