import React, { useState } from "react";
import { createContext } from "use-context-selector";
import { useLockBodyScroll, useToggle } from "react-use";

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <LayoutProvider>.");
};

interface LayoutContextType {
  /**
   * Is mobile navigation menu open? Default: false
   */
  isMobileNavigationMenuOpen: boolean;
  toggleMobileNavigationMenu: (open?: boolean) => void;

  /**
   * Is mobile country menu open? Default: false
   */
  isMobileCountryMenuOpen: boolean;
  toggleMobileCountryMenu: (open?: boolean) => void;

  /**
   * Is mobile language menu open? Default: false
   */
  isMobileLanguageMenuOpen: boolean;
  toggleMobileLanguageMenu: (open?: boolean) => void;

  /**
   * Force header to show on top of current modal. This is used with the express delivery panel on mobile.
   */
  isMobileHeaderOverModal: boolean;
  setMobileHeaderOverModal: (isOver: boolean) => void;

  /**
   * Is desktop country & language navigation menu open? Default: false
   */
  isDesktopCountryLanguageMenuOpen: boolean;
  toggleDesktopCountryLanguageMenu: (open?: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType>({
  isMobileNavigationMenuOpen: false,
  toggleMobileNavigationMenu: stub,
  isMobileCountryMenuOpen: false,
  toggleMobileCountryMenu: stub,
  isMobileLanguageMenuOpen: false,
  toggleMobileLanguageMenu: stub,
  isMobileHeaderOverModal: false,
  setMobileHeaderOverModal: stub,
  isDesktopCountryLanguageMenuOpen: false,
  toggleDesktopCountryLanguageMenu: stub
});

const LayoutProvider = ({ children }) => {
  const [isMobileNavigationMenuOpen, toggleMobileNavigationMenu] =
    useToggle(false);
  const [isMobileCountryMenuOpen, toggleMobileCountryMenu] = useToggle(false);
  const [isMobileLanguageMenuOpen, toggleMobileLanguageMenu] = useToggle(false);
  const [isMobileHeaderOverModal, setMobileHeaderOverModal] = useState(false);
  const [isDesktopCountryLanguageMenuOpen, toggleDesktopCountryLanguageMenu] =
    useState(false);

  // Lock body scroll when any mobile menu is open
  useLockBodyScroll(
    isMobileNavigationMenuOpen ||
      isMobileCountryMenuOpen ||
      isMobileLanguageMenuOpen
  );

  return (
    <LayoutContext.Provider
      value={{
        isMobileNavigationMenuOpen,
        toggleMobileNavigationMenu,
        isMobileCountryMenuOpen,
        toggleMobileCountryMenu,
        isMobileLanguageMenuOpen,
        toggleMobileLanguageMenu,
        isMobileHeaderOverModal,
        setMobileHeaderOverModal,
        isDesktopCountryLanguageMenuOpen,
        toggleDesktopCountryLanguageMenu
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
