import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useToggle } from "react-use";
import { createContext } from "use-context-selector";
import {
  fetchDeliveryAddressByPlaceId,
  getDeliveryAddressMatchingSubcountry
} from "../helpers/express-delivery/address";
import { useLocalStorageState } from "../helpers/localstorage";
import { analyticsTrackEvent } from "../services/analytics";
import { CountryType, DeliveryAddressType } from "../types/airgraft";

type SetAddressOptionsType = {
  saveToLocalStorage?: boolean;
  saveToAnalytics?: boolean;
};

export const STORE_LOCATOR_ADDRESS_LOCALSTORAGE_KEY =
  "airgraft-store-locator-place-id";

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <StoreLocatorAddressContextType>."
  );
};

export interface StoreLocatorAddressContextType {
  /**
   * Is AGX store locator address panel open
   */
  isAddressPanelOpen: boolean;

  /**
   * Open/Close AGX store locator address panel
   */
  toggleAddressPanel: (nextValue?: boolean) => void;

  /**
   * Current address entered by user for delivery
   */
  address: DeliveryAddressType;

  /**
   * Is loading previously used Address using localstorage placeId
   */
  isLoadingAddress: boolean;

  /**
   * Change current address. This will fetch a new provider & availableProducts
   */
  setAddress: (
    address: DeliveryAddressType,
    options?: SetAddressOptionsType
  ) => void;

  /**
   * Change current address
   */
  setAddressByPlaceId: (
    placeId: string,
    options?: SetAddressOptionsType
  ) => void;

  /**
   * Matching country for current store locator address.
   * Used to redirect users to a different country version of the website
   */
  addressMatchingCountry: null | CountryType;

  /**
   * Matching subcountry for current store locator address.
   * Used to redirect users to a different state/province version of the website
   */
  addressMatchingSubcountry: null | string;
}

export const StoreLocatorAddressContext =
  createContext<StoreLocatorAddressContextType>({
    // Address/Provider
    isAddressPanelOpen: false,
    toggleAddressPanel: stub,
    address: null,
    setAddress: stub,
    setAddressByPlaceId: stub,
    isLoadingAddress: false,
    addressMatchingCountry: null,
    addressMatchingSubcountry: null
  });

export const StoreLocatorAddressProvider = ({ children }) => {
  const queryClient = useQueryClient();

  // Address
  const [isAddressPanelOpen, toggleAddressPanel] = useToggle(false);
  const [address, setAddressValue] = useState<DeliveryAddressType>(null);

  // Save last placeId inside localstorage
  const [localstoragePlaceId, setLocalstoragePlaceId] =
    useLocalStorageState<string>(STORE_LOCATOR_ADDRESS_LOCALSTORAGE_KEY, null);

  // Change current address with aditional options
  const setAddress = useCallback(
    (address: DeliveryAddressType, options?: SetAddressOptionsType) => {
      setAddressValue(address);
      if (options?.saveToLocalStorage) {
        setLocalstoragePlaceId(address?.placeId || null);
      }
      if (options?.saveToAnalytics) {
        if (address) {
          analyticsTrackEvent("store-locator-address-panel.set-address");
        }
      }
    },
    []
  );

  const [isLoadingAddress, setLoadingPreviousAddress] = useState(
    !!localstoragePlaceId
  );

  // Helper to set address by placeId
  const setAddressByPlaceId = useCallback(
    async (placeId: string, options?: SetAddressOptionsType) => {
      if (!placeId) {
        setAddress(null, options);
        return;
      }

      setLoadingPreviousAddress(true);

      let address: DeliveryAddressType = null;
      try {
        address = await queryClient.fetchQuery(
          ["delivery-address-by-id", placeId],
          () => fetchDeliveryAddressByPlaceId(placeId),
          {
            staleTime: 1000 * 60 * 60 * 48 // 48 hours
          }
        );
      } catch (e) {}

      if (address) {
        setAddress(address, options);
      }

      setLoadingPreviousAddress(false);
    },
    []
  );

  // On page load: Prefill "address" using localstorage placeId
  useEffect(() => {
    if (localstoragePlaceId) {
      setAddressByPlaceId(localstoragePlaceId, { saveToAnalytics: true });
    }
  }, []);

  const {
    country: addressMatchingCountry,
    subcountry: addressMatchingSubcountry
  } = getDeliveryAddressMatchingSubcountry(address);

  return (
    <StoreLocatorAddressContext.Provider
      value={{
        // Address
        toggleAddressPanel,
        isAddressPanelOpen,
        address,
        setAddress,
        setAddressByPlaceId,
        isLoadingAddress,
        addressMatchingCountry,
        addressMatchingSubcountry
      }}
    >
      {children}
    </StoreLocatorAddressContext.Provider>
  );
};
