import React from "react";

const ArrowDown = ({ style = {} }) => (
  <div
    style={{
      width: "24px",
      height: "24px",
      position: "relative",
      ...(style || {})
    }}
  >
    <svg
      fill="currentColor"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 10.2361L12 13.6789L5.75 10.2361V9.84629L11.7587 13.1564L12 13.2893L12.2413 13.1564L18.25 9.84629V10.2361Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  </div>
);

export default ArrowDown;
