import React from "react";
import { LocaleType } from "../types/airgraft";
import en from "../translations/en.json";
import fr from "../translations/fr.json";
import { reportSentryClientError } from "../services/sentry";

// Locale -> translations json files
const translations: Record<LocaleType, Record<string, string>> = {
  "en-US": en,
  "en-CA": en,
  "fr-CA": fr
};

const getTranslate =
  (locale: LocaleType) =>
  (
    translationKey: string,
    replacements: Record<string, string | number>
  ): string => {
    let translation = translations[locale || "en-US"][translationKey] as string;

    // If translation doesnt exists, report it to sentry and fallback to key
    if (!translation) {
      reportSentryClientError(
        new Error(
          `Missing translation: "${translationKey}" for language "${locale}"`
        )
      );
      translation = translationKey;
    }

    if (!replacements || Object.keys(replacements).length === 0) {
      return translation;
    }

    return Object.keys(replacements).reduce((accumulator, replacementKey) => {
      return accumulator.replace(
        `{{${replacementKey}}}`,
        `${replacements[replacementKey]}`
      );
    }, translation);
  };

interface TranslationsContextType {
  locale: LocaleType;
  translate: (
    term: string,
    replacements?: Record<string, string | number>
  ) => string;
}

// Translate will translate terms at build time given `pageContext.locale`
// Fallback to English or the input term.
const TranslationsContext = React.createContext<TranslationsContextType>({
  locale: "en-US" as LocaleType,
  translate: getTranslate(`en-US`)
});

const TranslationsProvider = ({
  locale,
  children
}: {
  locale: LocaleType;
  children: JSX.Element;
}) => {
  const translate = getTranslate(locale);
  return (
    <TranslationsContext.Provider value={{ locale, translate }}>
      {children}
    </TranslationsContext.Provider>
  );
};

export { TranslationsContext, TranslationsProvider };
