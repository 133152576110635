import React from "react";

const LanguageIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.78857C19 14.7591 14.9705 18.7886 10 18.7886C5.02948 18.7886 1 14.7591 1 9.78857C1 4.81805 5.02948 0.788574 10 0.788574C14.9705 0.788574 19 4.81805 19 9.78857Z"
      stroke="black"
      strokeWidth="1.27881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2582 9.78857C13.2582 14.7591 11.7995 18.7886 10.0002 18.7886C8.20085 18.7886 6.74219 14.7591 6.74219 9.78857C6.74219 4.81805 8.20085 0.788574 10.0002 0.788574C11.7995 0.788574 13.2582 4.81805 13.2582 9.78857Z"
      stroke="black"
      strokeWidth="1.27881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.57617 6.90869H18.388"
      stroke="black"
      strokeWidth="1.27881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.57617 12.6689H18.388"
      stroke="black"
      strokeWidth="1.27881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LanguageIcon;
