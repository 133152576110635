import { Box, Typography } from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useTranslate from "../../../hooks/use-translate";

export default function DeliveryCoverageInfo() {
  const t = useTranslate();
  return (
    <Box display="flex" alignItems="center">
      <Box mr={0.5} display="flex" alignItems="center">
        <InfoOutlinedIcon fontSize="small" />
      </Box>
      <Typography variant="caption">
        {t("expressDelivery.fulfilledByLicensedDispensaries")}
      </Typography>
    </Box>
  );
}
