import React from "react";
import {
  Box,
  BoxProps,
  Fade,
  makeStyles,
  Modal,
  ModalProps,
  useTheme
} from "@material-ui/core";
import ModalCloseButton from "./ModalCloseButton";

type Props = ModalProps & {
  BoxProps?: BoxProps;
  hideCloseButton?: boolean;
  disableAnimation?: boolean;
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    backdropFilter: `blur(8px)`,
    backgroundColor: `rgba(0, 0, 0, 0.4)`
  }
}));

const ConditionalFadeWrapper = ({ condition, wrapper, children }) =>
  condition ? children : wrapper(children);

export default function BasicModal({
  BoxProps,
  hideCloseButton,
  disableAnimation,
  ...modalProps
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Modal
      {...modalProps}
      className={`${classes.modal} ${modalProps?.className}`}
    >
      <ConditionalFadeWrapper
        condition={disableAnimation}
        wrapper={children => (
          <Fade in={modalProps.open} style={{ outline: 0 }}>
            {children}
          </Fade>
        )}
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          bgcolor="grey.200"
          borderRadius="8px"
          padding={{ xs: 3, sm: 6 }}
          pt={modalProps.onClose ? { xs: 6, sm: 8 } : undefined}
          maxWidth="450px"
          justifyContent="center"
          {...(BoxProps || {})}
        >
          {/* Close button */}
          {modalProps.onClose && !hideCloseButton && (
            <ModalCloseButton
              onClick={e => modalProps.onClose(e, "escapeKeyDown")}
              style={{
                position: "absolute",
                top: theme.spacing(2),
                right: theme.spacing(2)
              }}
            />
          )}
          {modalProps.children}
        </Box>
      </ConditionalFadeWrapper>
    </Modal>
  );
}
