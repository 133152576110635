import React from "react";
import LOGO_SVG from "./logo.svg";
import WHITE_LOGO_SVG from "./logo.white.svg";
import BLACK_LOGO_SVG from "./logo.black.svg";
import TRUCK_SVG from "./truck.svg";

type Props = {
  width?: string;
  height?: string;
  variant?: "default" | "white" | "black" | "truck";
  style?: React.CSSProperties;
};

function ExpressDeliveryLogo({
  width,
  height = "12px",
  variant,
  style
}: Props) {
  let src = LOGO_SVG;
  if (variant === "white") {
    src = WHITE_LOGO_SVG;
  }
  if (variant === "black") {
    src = BLACK_LOGO_SVG;
  }
  if (variant === "truck") {
    src = TRUCK_SVG;
  }

  return (
    <img
      src={src}
      className="express-delivery-logo"
      alt="Express Delivery"
      style={{
        width: width || undefined,
        height: height || undefined,
        ...(style || {})
      }}
    />
  );
}

export default React.memo(ExpressDeliveryLogo);
