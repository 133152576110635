import { useContext, useContextSelector } from "use-context-selector";
import {
  NotificationContext,
  NotificationContextType
} from "../providers/notification";

const useNotifications = () => {
  const values = useContext(NotificationContext);
  return values;
};

/**
 * Get partial state from ExpressDeliveNotificationContextryAvailabilityContext. Better performance than using useNotifications
 */
export function useNotificationsSelector<Selected>(
  selector: (value: NotificationContextType) => Selected
) {
  return useContextSelector(NotificationContext, selector);
}

export default useNotifications;
