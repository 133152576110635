export function reportSentryClientError(
  error: Error,
  extra?: { [key: string]: any }
) {
  if (typeof window !== "undefined" && window.Sentry) {
    window.Sentry.withScope(scope => {
      scope.setExtras(extra);
      if (window._browsee) {
        scope.setExtra("sessionUrl", window._browsee.getSessionUrl());
      }
      window.Sentry.captureException(error);
    });
  } else {
    console.warn("Sentry report: ", error, extra);
  }
}

export function addSentryClientExtra(key: string, value: any) {
  if (window.Sentry && window.Sentry.configureScope) {
    window.Sentry.configureScope(function (scope) {
      scope.setExtra(key, value);
    });
  }
}
