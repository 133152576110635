import { Box, PopoverProps, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useStoreLocatorAddressSelector } from "../../../hooks/use-store-locator-address";
import useTranslate from "../../../hooks/use-translate";
import CallStoreMessage from "../../store-locator/components/CallStoreMessage";
import HeaderPanelBase from "../PanelBase";
import { PanelTitle } from "../PanelBase/styles";
import AddressAutoCompleteField from "./AddressAutoCompleteField";
import AddressConfirmButton from "./AddressConfirmButton";
import AddressStatusMessage from "./AddressStatusMessage";

type Props = Pick<
  PopoverProps,
  "anchorEl" | "transformOrigin" | "anchorOrigin"
>;

export default function StoreLocatorAddressPanel(popoverProps: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const isAddressPanelOpen = useStoreLocatorAddressSelector(
    c => c.isAddressPanelOpen
  );
  const toggleAddressPanel = useStoreLocatorAddressSelector(
    c => c.toggleAddressPanel
  );

  const [showConfirmButton, setShowConfirmButton] = useState(false);

  return (
    <HeaderPanelBase
      open={isAddressPanelOpen}
      onClose={() => toggleAddressPanel(false)}
      data-testid="store-locator-address-panel"
      {...popoverProps}
    >
      <Box p={{ xs: 2, md: 3 }} pb={{ xs: 4 }}>
        {/* Main panel title */}
        <Box mb={2} pr={{ xs: 6 }}>
          <PanelTitle>{t("storelocator.addressPanel.title")}</PanelTitle>
        </Box>

        {/* Custom address autocomplete field */}
        <AddressAutoCompleteField
          onAddressChange={() => setShowConfirmButton(true)}
        />

        <Box marginY={2}>
          <AddressStatusMessage />
        </Box>

        {/* Confirm and close panel button */}
        {showConfirmButton && (
          <AddressConfirmButton
            onClick={() => {
              setShowConfirmButton(false);
              toggleAddressPanel(false);
            }}
          />
        )}

        {/* Call store ahead message */}
        <Box mt={2}>
          <CallStoreMessage />
        </Box>
      </Box>

      {/* Express Delivery bottom panel border */}
      <Box
        position="absolute"
        left={0}
        bottom={0}
        width="100%"
        height="6px"
        overflow="hidden"
      >
        <Box
          bgcolor="primary.main"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          bottom={0}
        />
      </Box>
    </HeaderPanelBase>
  );
}
