import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function GeolocationIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23 22" {...props}>
      <path
        d="M22.8209 2.75C22.8209 2.02065 22.5311 1.32118 22.0154 0.805456C21.4997 0.289731 20.8002 0 20.0709 0H16.5352V2.35714H20.0709C20.1751 2.35714 20.275 2.39853 20.3487 2.47221C20.4223 2.54588 20.4637 2.64581 20.4637 2.75V6.28571H22.8209V2.75Z"
        fill="white"
      />
      <path
        d="M0.0351562 2.75V6.28571H2.3923V2.75C2.3923 2.64581 2.43369 2.54588 2.50736 2.47221C2.58104 2.39853 2.68096 2.35714 2.78516 2.35714H6.32087V0H2.78516C2.05581 0 1.35634 0.289731 0.840613 0.805456C0.324888 1.32118 0.0351563 2.02065 0.0351562 2.75Z"
        fill="white"
      />
      <path
        d="M0.0351562 15.7142V19.2499C0.0351563 19.9793 0.324888 20.6788 0.840613 21.1945C1.35634 21.7102 2.05581 21.9999 2.78516 21.9999H6.32087V19.6428H2.78516C2.68096 19.6428 2.58104 19.6014 2.50736 19.5277C2.43369 19.4541 2.3923 19.3541 2.3923 19.2499V15.7142H0.0351562Z"
        fill="white"
      />
      <path
        d="M22.8209 15.7142H20.4637V19.2499C20.4637 19.3541 20.4223 19.4541 20.3487 19.5277C20.275 19.6014 20.1751 19.6428 20.0709 19.6428H16.5352V21.9999H20.0709C20.8002 21.9999 21.4997 21.7102 22.0154 21.1945C22.5311 20.6788 22.8209 19.9793 22.8209 19.2499V15.7142Z"
        fill="white"
      />
      <path
        d="M11.428 4.32141C9.86513 4.32141 8.36626 4.94226 7.26113 6.04739C6.15601 7.15251 5.53516 8.65139 5.53516 10.2143C5.53516 13.9307 9.2398 16.9989 9.9823 17.5764L11.428 18.7L12.8737 17.5725C13.6162 16.9989 17.3209 13.9307 17.3209 10.2143C17.3209 8.65139 16.7 7.15251 15.5949 6.04739C14.4898 4.94226 12.9909 4.32141 11.428 4.32141ZM11.428 15.7143C11.428 15.7143 7.8923 12.9643 7.8923 10.2143C7.8923 9.27654 8.26481 8.37722 8.92789 7.71414C9.59096 7.05107 10.4903 6.67855 11.428 6.67855C12.3657 6.67855 13.2651 7.05107 13.9281 7.71414C14.5912 8.37722 14.9637 9.27654 14.9637 10.2143C14.9637 12.9643 11.428 15.7143 11.428 15.7143Z"
        fill="white"
      />
      <path
        d="M11.4286 11.3929C12.0795 11.3929 12.6071 10.8652 12.6071 10.2143C12.6071 9.56343 12.0795 9.03577 11.4286 9.03577C10.7777 9.03577 10.25 9.56343 10.25 10.2143C10.25 10.8652 10.7777 11.3929 11.4286 11.3929Z"
        fill="white"
      />
    </SvgIcon>
  );
}
