import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme
} from "@material-ui/core";
import { navigate } from "gatsby";
import React from "react";
import CheckmarkCircleIcon from "../../../components/icons/checkmark-circle";
import { getSubcountryLabel } from "../../../helpers/country";
import { getLocaleFromCountryLang } from "../../../helpers/locale";
import { getLocalizedPath } from "../../../helpers/path";
import useCountry from "../../../hooks/use-country";
import { useExpressDeliveryAvailabilitySelector } from "../../../hooks/use-express-delivery-availability";
import useSubcountry from "../../../hooks/use-subcountry";
import useTranslate from "../../../hooks/use-translate";
import DeliveryCoverageInfo from "../../express-delivery/DeliveryCoverageInfo";

export default function AddressStatusMessage() {
  const theme = useTheme();
  const t = useTranslate();
  const {
    address,
    availableProvider,
    isLoadingAvailableProvider,
    hasNoAvailableProvider,
    isLoadingAddress,
    addressMatchingCountry,
    addressMatchingSubcountry
  } = useExpressDeliveryAvailabilitySelector(c => ({
    address: c.address,
    availableProvider: c.availableProvider,
    isLoadingAvailableProvider: c.isLoadingAvailableProvider,
    hasNoAvailableProvider: c.hasNoAvailableProvider,
    isLoadingAddress: c.isLoadingAddress,
    addressMatchingCountry: c.addressMatchingCountry,
    addressMatchingSubcountry: c.addressMatchingSubcountry
  }));
  const country = useCountry();
  const { subcountry, setSubcountry } = useSubcountry();

  const isDeliveryAddressSameWebsiteCountryAndSubCountry =
    country === addressMatchingCountry &&
    subcountry === addressMatchingSubcountry;

  const toggleAvailabilityPanel = useExpressDeliveryAvailabilitySelector(
    c => c.toggleAvailabilityPanel
  );

  const handleChangeRegionClick = () => {
    setSubcountry(addressMatchingSubcountry, addressMatchingCountry);
    const redirectLocale = getLocaleFromCountryLang(
      addressMatchingCountry,
      "en"
    );
    const redirectTo = getLocalizedPath(redirectLocale, "/");
    toggleAvailabilityPanel(false);
    navigate(redirectTo);
  };

  return (
    <>
      {/* Success: Matched provider */}
      {availableProvider &&
        !isLoadingAvailableProvider &&
        !isLoadingAddress && (
          <Box mt={1} display="flex" alignItems="center">
            <CheckmarkCircleIcon
              style={{ color: "#00AF4B", marginRight: theme.spacing(1) }}
            />

            <Typography variant="body2">
              {t("expressDelivery.fulfilledBy")}{" "}
              <strong>{availableProvider.publicName}</strong>.
            </Typography>
          </Box>
        )}

      {/* Loading: Fetching addess from id */}
      {isLoadingAddress && (
        <Box mt={1} mb={1} display="flex" alignItems="center">
          <CircularProgress size={14} color="secondary" disableShrink />
          <Typography variant="body2" style={{ marginLeft: theme.spacing(1) }}>
            {t("expressDelivery.loadingDeliveryAddress")}
          </Typography>
        </Box>
      )}

      {/* Loading: Fetching available providers */}
      {isLoadingAvailableProvider && (
        <Box mt={1} mb={1} display="flex" alignItems="center">
          <CircularProgress size={14} color="secondary" disableShrink />
          <Typography variant="body2" style={{ marginLeft: theme.spacing(1) }}>
            {t("expressDelivery.loadingDeliveryDispensary")}
          </Typography>
        </Box>
      )}

      {/* Error: No matching providers */}
      {hasNoAvailableProvider &&
        (!addressMatchingCountry ||
          !addressMatchingSubcountry ||
          isDeliveryAddressSameWebsiteCountryAndSubCountry) && (
          <Box mt={1} mb={2}>
            <Typography variant="body2" color="error">
              {t("cart.error.unavailableAddress")}
            </Typography>
          </Box>
        )}

      {/* Error: No matching providers: Change region */}
      {hasNoAvailableProvider &&
        addressMatchingCountry &&
        addressMatchingSubcountry &&
        !isDeliveryAddressSameWebsiteCountryAndSubCountry && (
          <Box mt={1} mb={2}>
            <Typography variant="body2" color="error" component="span">
              {t("expressDelivery.notAvailable", {
                address: `${address.city}, ${address.state}`
              })}
            </Typography>
            &nbsp;
            <Typography variant="body2" component="span">
              {t("expressDelivery.changeRegion", {
                subcountry: getSubcountryLabel(address.state) || address.state
              })}
            </Typography>
            <Box mt={1.5}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleChangeRegionClick}
              >
                {t("expressDelivery.changeRegionCta", {
                  subcountry: getSubcountryLabel(address.state) || address.state
                })}
              </Button>
            </Box>
          </Box>
        )}

      {/* Empty: no address selected */}
      {!availableProvider && (
        <Box mt={2}>
          <DeliveryCoverageInfo />
        </Box>
      )}
    </>
  );
}
