import React from "react";
import { Link, GatsbyLinkProps } from "gatsby";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";

type Props = GatsbyLinkProps<Record<string, unknown>>;

const LocalizedLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ to, ...props }, ref) => {
    const getLocalizedPath = useLocalizedLocationPath();
    const isExternal = to && to.startsWith("http");
    const isAirgraftUrl = isExternal && to.includes("airgraft.com");
    if (isExternal) {
      return (
        <a
          href={to}
          target={isAirgraftUrl ? "_self" : "_blank"}
          rel="noopener noreferrer"
          onClick={props.onClick}
          style={props.style}
          className={props.className}
          ref={ref}
        >
          {props.children}
        </a>
      );
    }
    const path = getLocalizedPath(to);
    return <Link to={path} ref={ref as any} {...props} />;
  }
);

export default LocalizedLink;
