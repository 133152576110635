import { getProductDetailsPath } from "../../helpers/url";
import { getUnlocalizedPath } from "../../helpers/path";

interface ActiveNavigation {
  defaultPrimaryNavItemId?: string;
  defaultSecondaryNavItemId?: string;
}

export function getActiveNavigationFromLocation(
  menu: Queries.HeaderFragmentQuery["datoCmsGeneralHeader"]["_allNavigationLocales"][number]["value"],
  location: {
    pathname: string;
  }
): ActiveNavigation {
  const currentPath = getUnlocalizedPath(location.pathname);

  const defaultSelected = menu.reduce(
    (accumulator, item) => {
      if (accumulator.defaultSecondaryNavItemId) {
        return accumulator;
      }
      if (item.target && getTargetHref(item.target) === currentPath) {
        accumulator = {
          ...accumulator,
          defaultPrimaryNavItemId: item.originalId
        };
      }
      return accumulator;
    },
    { defaultPrimaryNavItemId: undefined, defaultSecondaryNavItemId: undefined }
  );

  return defaultSelected;
}

/**
 * Build link href based on the model type of the field value
 */
type TargetType = {
  slug?: string;
  url?: string;
  path?: string;
  productType?: string;
  model?: {
    apiKey: string;
  };
};

export const getTargetHref = (target?: TargetType): string => {
  if (!target) {
    return null;
  }
  if (target.model && target.model.apiKey === "product") {
    return getProductDetailsPath(target.productType, target.slug);
  }
  if (target?.url && target.url.startsWith("http")) {
    return target.url;
  }
  if (target?.path && target.path.startsWith("http")) {
    return target.path;
  }
  const startingSlash = target.path?.startsWith("/") ? "" : "/";
  return `${startingSlash}${target.path}`;
};
