import { styled, TextField } from "@material-ui/core";
import { Link } from "gatsby";

export const SwitchLocaleLink = styled(Link)(({ theme }) => ({
  color: `${theme.palette.common.black} !important`,
  textDecoration: "underline"
}));

/**
 * Used by both date of birth textfield and State/Province select field
 * Styles tweaks to look better in the WelcomeModal
 */
export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    borderRadius: "8px",
    height: "40px"
  },

  "& .MuiSelect-filled": {
    paddingLeft: "12px",
    height: "27px",
    background: "transparent"
  },
  "&.no-value .MuiSelect-select": {
    color: theme.palette.grey[700]
  },

  // Error message
  "& .MuiFormHelperText-root.Mui-error": {
    marginBottom: "-4px"
  },

  // Date of birth textfield specific
  "& .MuiFilledInput-input": {
    paddingLeft: "12px",
    height: "27px",
    borderRadius: "8px"
  },
  "& .MuiFilledInput-input::placeholder": {
    color: theme.palette.grey[700]
  },

  "& .MuiFilledInput-underline:after": {
    display: "none"
  }
}));
