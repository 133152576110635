import React from "react";

const Logo = () => (
  <svg fill="none" height="100%" viewBox="0 0 194 23" width="100%">
    <path
      d="M171.721 0.56686H193.085L191.453 3.25226H183.179V22.1093H179.996V3.25226H170.09L171.721 0.56686ZM30.1847 22.1092V2.33504L33.3683 0.56684V22.1092H30.1847ZM8.41673 0.56686H12.0091L25.0937 22.1093H21.5008L19.1086 18.1707H6.65915L4.26725 22.1093H0.763184L11.1323 5.03766L8.41673 0.56686ZM12.8838 7.92206L8.27196 15.5153H17.496L12.8838 7.92206ZM135.222 15.5153L130.61 7.92208L125.998 15.5153H135.222ZM126.142 0.56688H129.735L142.819 22.1093H139.227L136.834 18.1707H124.385L121.993 22.1093H118.489L128.858 5.03768L126.142 0.56688ZM74.5496 13.2774L76.1814 10.592H88.1037V12.8814C88.1037 13.964 87.9531 15.0464 87.5976 16.071C86.1882 20.1324 82.276 22.6762 76.98 22.6762C69.5037 22.6762 64.9753 18.2006 64.9753 11.3382C64.9753 4.4756 69.5037 0 77.0105 0C82.8758 0 87.0185 3.1058 87.774 7.728H84.5432L84.5394 7.715C83.6915 4.6464 80.8655 2.8144 76.9798 2.8144C71.4485 2.8144 68.2348 6.0008 68.2348 11.3382C68.2348 16.6754 71.4598 19.8618 77.0095 19.8618C77.0114 19.8618 77.0308 19.8622 77.0664 19.8622C77.1223 19.8622 77.2181 19.8612 77.3478 19.8562C78.1788 19.8316 78.9773 19.7142 79.7209 19.5074C81.11 19.1536 83.0074 18.3666 84.1549 16.6278C84.7626 15.7066 85.0842 14.593 85.0842 13.408V13.2774H74.5496ZM104.253 10.592C108.591 10.592 110.62 9.86104 110.62 6.93684C110.62 3.98324 108.654 3.25224 104.471 3.25224H97.6999V10.592H104.253ZM113.803 6.92204C113.803 10.4758 111.996 12.4602 108.314 13.0712L113.803 22.1092H110.121L104.757 13.2774H97.6999V22.1092H94.5164V0.56684H105.532C111.088 0.56684 113.803 2.56604 113.803 6.92204ZM49.8971 10.592C54.2353 10.592 56.2642 9.86104 56.2642 6.93684C56.2642 3.98324 54.2978 3.25224 50.1153 3.25224H43.3442V10.592H49.8971ZM59.4476 6.92204C59.4476 10.4758 57.64 12.4602 53.9579 13.0712L59.4476 22.1092H55.7659L50.4015 13.2774H43.3442V22.1092H40.1606V0.56684H51.1764C56.7324 0.56684 59.4476 2.56604 59.4476 6.92204ZM147.436 22.1092V0.56684H166.634L165.002 3.25224H150.619V9.99544H164.201L162.569 12.6806H150.619V22.1092H147.436Z"
      fill="currentColor"
    />
  </svg>
);

export default Logo;
