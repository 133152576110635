import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import LocalizedLink from "../../../../../components/localized-link";
import useIsAuthenticated from "../../../../../hooks/use-is-authenticated";
import useTranslate from "../../../../../hooks/use-translate";
import EMPTY_NOTIFICATION_IMG from "./empty.png";

type Props = {
  className?: string;
  onLinkClick?: () => void;
};

export default function NotificationPanelEmptyList({
  className,
  onLinkClick
}: Props) {
  const t = useTranslate();
  const isAuthenticated = useIsAuthenticated();
  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      paddingX={4}
      flex={1}
      paddingBottom={{ xs: 6, md: 2 }}
    >
      <Box maxWidth="120px" mx="auto">
        <img src={EMPTY_NOTIFICATION_IMG} style={{ width: "100%" }} />
      </Box>
      <Box mt={2} textAlign="center">
        <Typography variant="body1">{t("notification.empty")}</Typography>
      </Box>
      <Box mt={2}>
        {!isAuthenticated && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component={LocalizedLink}
            to="/sign-in"
            onClick={onLinkClick}
          >
            {t("header.signIn")}
          </Button>
        )}
        {isAuthenticated && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component={LocalizedLink}
            to="/user/pods"
            onClick={onLinkClick}
          >
            {t("myPods.title")}
          </Button>
        )}
      </Box>
    </Box>
  );
}
