/**
 * Format price object
 * NOTE: Do not convert this file to typescript, else it cannot be imported inside lamda functions/
 */
export function formatPrice({ amount, currencyCode = null }, options) {
  const defaultOptions = {
    hideCurrencyCode: false,
    hideZeros: false
  };
  const mergedOptions = { ...defaultOptions, ...(options || {}) };

  if (amount === undefined || amount === null) {
    return "";
  }
  let priceText = parseFloat(amount).toFixed(2);
  if (mergedOptions.hideZeros) {
    priceText = priceText.replace(".00", "");
  }
  const suffix =
    mergedOptions.hideCurrencyCode || !currencyCode ? "" : ` ${currencyCode}`;
  return `$${priceText}${suffix}`;
}
