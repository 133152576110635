import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { ACTIVATION_BASE_URL } from "../../containers/activation/constants";
import { RATING_BASE_URL } from "../../containers/rating/constants";
import { getSubcountryLabel } from "../../helpers/country";
import { isPageDisabledByFeatureFlags } from "../../helpers/feature-flags";
import { SHOP_PRODUCTS_PATH } from "../../helpers/url";
import useCountry from "../../hooks/use-country";
import useLocale from "../../hooks/use-locale";
import useSubcountry from "../../hooks/use-subcountry";
import useUnlocalizedLocationPath from "../../hooks/use-unlocalized-location-path";
import Footer from "./footer";

export interface FooterData {
  mainLinks: Queries.FooterFragmentQuery["datoCmsGeneralHeader"]["_allNavigationLocales"][number]["value"];
  additionalLinks: Queries.FooterFragmentQuery["datoCmsGeneralFooter"]["_allLinkListsLocales"][number]["value"];
}

/**
 * Hide footer on these pages
 */
const pagesBlacklist = [
  "/register",
  "/sign-in",
  "/user",
  "/delivery/checkout",
  "/store-locator",
  ACTIVATION_BASE_URL,
  RATING_BASE_URL,
  SHOP_PRODUCTS_PATH
];

const FooterWrapper = () => {
  const locale = useLocale();
  const country = useCountry();
  const { subcountry } = useSubcountry();

  if (!locale) return null;

  const {
    datoCmsGeneralHeader: { _allNavigationLocales: localizedMainLinks },
    datoCmsGeneralFooter: { _allLinkListsLocales: localizedFooterLinks }
  } = useStaticQuery<Queries.FooterFragmentQuery>(query);

  let mainLinks = localizedMainLinks.find(item => item.locale === locale).value;

  // Remove disabled pags by feature flags
  mainLinks = mainLinks.filter(item => {
    const path = (item.target as any)?.path;
    if (!path) return true;
    return !isPageDisabledByFeatureFlags(country, path, { subcountry });
  });

  // Apply subcountry visibility filters
  mainLinks = mainLinks.filter(item => {
    if (subcountry && item.subcountryVisibility.length > 0) {
      const currentSubcountryLabel = getSubcountryLabel(subcountry);
      return item.subcountryVisibility.find(
        item => item.name === currentSubcountryLabel
      );
    }
    return true;
  });

  const additionalLinks = localizedFooterLinks.find(
    item => item && item.locale === locale
  ).value;

  const footerData = {
    mainLinks,
    additionalLinks
  };

  // Hide footer on some pages
  const path = useUnlocalizedLocationPath();
  if (!!pagesBlacklist.find(p => path.startsWith(p))) {
    return null;
  }

  return <Footer data={footerData} />;
};

const query = graphql`
  query FooterFragment {
    datoCmsGeneralHeader {
      _allNavigationLocales {
        locale
        value {
          originalId
          title
          visibility
          authenticationVisibility
          subcountryVisibility {
            name
          }
          target {
            ... on DatoCmsWebPage {
              path
              model {
                apiKey
              }
            }
            ... on DatoCmsProduct {
              slug
              productType
              model {
                apiKey
              }
            }
          }
        }
      }
    }
    datoCmsGeneralFooter {
      _allLinkListsLocales {
        locale
        value {
          links {
            hyperlink
            originalId
            title
            webpage {
              path
            }
          }
        }
      }
    }
  }
`;

export default React.memo(FooterWrapper);
