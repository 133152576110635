import { Badge, NoSsr } from "@material-ui/core";
import React, { useRef } from "react";
import NotificationIcon from "../../../components/icons/notification";
import useNotifications from "../../../hooks/use-notification";
import useUnlocalizedLocationPath from "../../../hooks/use-unlocalized-location-path";
import { ACTIVATION_BASE_URL } from "../../activation/constants";
import { RATING_BASE_URL } from "../../rating/constants";
import NotificationPanel from "../NotificationPanel";
import { NotificationButton } from "./styles";

/**
 * Hide notification on these pages
 */
const pagesBlacklist = [
  "/register",
  "/sign-in",
  "/delivery/checkout",
  ACTIVATION_BASE_URL,
  RATING_BASE_URL
];

function HeaderNotificationButton({ withPanel }: { withPanel?: boolean }) {
  const { unreadNotificationsCount, toggleNotificationMenuOpen } =
    useNotifications();

  const buttonRef = useRef<HTMLButtonElement>(null);

  // Hide footer on some pages
  const path = useUnlocalizedLocationPath();
  if (!!pagesBlacklist.find(p => path.startsWith(p))) {
    return null;
  }

  return (
    <>
      <NotificationButton
        onClick={() => toggleNotificationMenuOpen(true)}
        ref={buttonRef}
      >
        <NoSsr
          fallback={
            <Badge invisible overlap="rectangular">
              <NotificationIcon />
            </Badge>
          }
        >
          <Badge
            badgeContent={unreadNotificationsCount}
            invisible={unreadNotificationsCount === 0}
            overlap="rectangular"
          >
            <NotificationIcon />
          </Badge>
        </NoSsr>
      </NotificationButton>

      {withPanel && (
        <NoSsr>
          <NotificationPanel
            anchorEl={buttonRef?.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          />
        </NoSsr>
      )}
    </>
  );
}

export default React.memo(HeaderNotificationButton);
