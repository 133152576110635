import { getCountryAndLanguageFromLocale } from "../helpers/country";
import useLocale from "./use-locale";

const useLang = () => {
  const locale = useLocale();
  const { lang } = getCountryAndLanguageFromLocale(locale);
  return lang;
};

export default useLang;
