import clsx from "clsx";
import React from "react";
import { MenuButtonLine, MenuButtonWrapper } from "./styles";

const MobileHamburgerButton = ({
  isOpen,
  onClick,
  className,
  ...props
}: {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
  ["data-testid"]?: string;
}) => {
  return (
    <MenuButtonWrapper
      onClick={() => onClick()}
      className={clsx({
        [className]: true,
        open: isOpen
      })}
      data-testid={props["data-testid"]}
    >
      <MenuButtonLine />
      <MenuButtonLine />
    </MenuButtonWrapper>
  );
};

export default React.memo(MobileHamburgerButton);
