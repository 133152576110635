import { getCountryAndLanguageFromLocale } from "../helpers/country";
import useLocale from "./use-locale";

const useCountry = () => {
  const locale = useLocale();
  const { country } = getCountryAndLanguageFromLocale(locale);
  return country;
};

export default useCountry;
