import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      {...props}
      style={{ ...(props.style || {}), fill: "none" }}
    >
      <g clipPath="url(#clip0_3493_69865)">
        <path
          d="M6 6L14.3334 14.3334"
          stroke="#323232"
          strokeWidth="1.56252"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3334 6L6 14.3334"
          stroke="#323232"
          strokeWidth="1.56252"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3493_69865">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
