import { Box, Button, Typography, useTheme } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ModalCloseButton from "../../../components/basic-modal/ModalCloseButton";
import LocalizedLink from "../../../components/localized-link";
import { useLocalStorageState } from "../../../helpers/localstorage";
import { getDefaultProductVariant } from "../../../helpers/product";
import useExpressDeliveryCart from "../../../hooks/use-express-delivery-cart";
import useIsAuthenticated from "../../../hooks/use-is-authenticated";
import useProductQuery from "../../../hooks/use-product-query";

export default function BatterySuggestion() {
  const theme = useTheme();
  const cart = useExpressDeliveryCart();
  const isAuthenticated = useIsAuthenticated();

  const [isSuggestionDismissed, setSuggestionDismissed] = useLocalStorageState(
    "airgraft-battery-suggestion-dismissed",
    false
  );

  const { data: productBattery } = useProductQuery({ slug: "battery" });
  const productVariantBattery = productBattery
    ? getDefaultProductVariant(productBattery)
    : null;

  const handleAddToCartClick = () => {
    if (!productVariantBattery) {
      return null;
    }
    const existingCartItem = cart.getItem(productVariantBattery.originalId);
    if (!existingCartItem?.quantity) {
      cart.addItem(
        {
          productVariantId: productVariantBattery.originalId,
          metadata: {
            name: productVariantBattery.title,
            brand: productVariantBattery.brand.name,
            image: productVariantBattery.featuredImage.url,
            thcGrams:
              productVariantBattery.variantDetail?.coaReport?.weightGrams
          }
        },
        1
      );
      setSuggestionDismissed(true);
    }
  };

  const isBatteryAlreadyInCart =
    productVariantBattery && !!cart.getItem(productVariantBattery.originalId);

  if (
    isSuggestionDismissed ||
    isAuthenticated ||
    cart.isCartEmpty ||
    isBatteryAlreadyInCart
  ) {
    return null;
  }

  return (
    <Box
      mt={3}
      mb={2}
      maxWidth="600px"
      display="flex"
      justifyContent="center"
      marginX={{ xs: 2, md: 2.5 }}
    >
      <AnimatePresence>
        <motion.div
          key="battery-suggestion"
          initial={{ y: "10%", opacity: 0 }}
          animate={{
            y: "0%",
            opacity: 1,
            transition: { duration: 0.4, delay: 0.45 }
          }}
          style={{ width: "100%" }}
        >
          <Box
            borderRadius="8px"
            bgcolor="primary.main"
            paddingX={{ xs: 2 }}
            paddingY={{ xs: 2 }}
            paddingLeft={{ xs: "100px" }}
            position="relative"
            style={{
              backgroundImage:
                "url(https://www.datocms-assets.com/15671/1648570135-battery-suggestion.png?auto=compress,format)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "16px bottom",
              backgroundSize: "60px"
            }}
          >
            <Box mb={{ xs: 0.5 }}>
              <Typography variant="h6">Need a battery?</Typography>
            </Box>
            <Box mb={{ xs: 2 }}>
              <Typography variant="caption" component="div">
                Our pods require an Airgraft 2 Battery.
              </Typography>
              <LocalizedLink
                to="/shop/devices/battery"
                style={{
                  color: "inherit",
                  fontWeight: 600,
                  textDecoration: "underline"
                }}
                onClick={() => cart.toggleCartPanel(false)}
              >
                View product →
              </LocalizedLink>
            </Box>

            {/* Add to cart */}
            <Box display="flex" alignItems="stretch" height="28px">
              <Box
                flex={1}
                bgcolor="common.white"
                fontSize="1.3rem"
                lineHeight="28px"
                fontWeight={600}
                textAlign="center"
                style={{
                  borderTopLeftRadius: "14px",
                  borderBottomLeftRadius: "14px",
                  letterSpacing: "0.5px"
                }}
              >
                $30.00
              </Box>
              <Box flex={1}>
                <Button
                  variant="contained"
                  className="express-delivery"
                  style={{
                    minHeight: "100%",
                    width: "100%",
                    height: "100%",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "14px",
                    borderBottomRightRadius: "14px",
                    fontSize: "1.3rem",
                    lineHeight: "26px",
                    padding: 0,
                    letterSpacing: "0.5px"
                  }}
                  onClick={handleAddToCartClick}
                  data-analytics="cart.battery-suggestion.add"
                >
                  Add to cart
                </Button>
              </Box>
            </Box>

            {/* Close button */}
            <ModalCloseButton
              style={{
                position: "absolute",
                top: theme.spacing(0.5),
                right: theme.spacing(0.5)
              }}
              onClick={() => setSuggestionDismissed(true)}
            />
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}
