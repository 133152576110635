import { Button, styled } from "@material-ui/core";
import React from "react";
import { useContextSelector } from "use-context-selector";
import { getDefaultSubcountry } from "../../../../helpers/country";
import useCountry from "../../../../hooks/use-country";
import useSubcountry from "../../../../hooks/use-subcountry";
import useTranslate from "../../../../hooks/use-translate";
import { LayoutContext } from "../../../../providers/layout";
import CanadaFlagIcon from "../../../../components/icons/flag-canada";
import UsaFlagIcon from "../../../../components/icons/flag-usa";

const CustomButton = styled(Button)(({ theme }) => ({
  "&.compact": {
    marginLeft: theme.spacing(-1),
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1)
    }
  }
}));

export default function MobileCountryMenuButton() {
  const t = useTranslate();
  const country = useCountry();
  const { subcountry } = useSubcountry();

  const label = t(`subcountry.${subcountry || getDefaultSubcountry(country)}`);
  const icon =
    country === "CA" ? <CanadaFlagIcon size={24} /> : <UsaFlagIcon size={24} />;

  const toggleMobileCountryMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileCountryMenu
  );

  return (
    <CustomButton
      variant="text"
      className="compact"
      startIcon={icon}
      onClick={() => toggleMobileCountryMenu(true)}
    >
      {label}
    </CustomButton>
  );
}
