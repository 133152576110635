import {
  Badge,
  Button,
  IconButton,
  Popover,
  styled,
  Typography
} from "@material-ui/core";
import LocalizedLink from "../../../../components/localized-link";

export const CustomUnderlineLink = styled(LocalizedLink)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(1, 3),
  "&:hover": {
    backgroundColor: theme.palette.grey[200]
  }
}));

export const CustomMenuH6 = styled(Typography)(() => ({
  fontSize: `2rem`,
  lineHeight: `2rem`
}));

export const CustomMenuPopover = styled(Popover)(({ theme }) => ({
  "& > .MuiPaper-root": {
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(3.15),
    backgroundColor: theme.palette.common.white,
    minWidth: `335px`,
    boxShadow: `6px 3px 10px rgba(0, 0, 0, 0.14)`,
    overflow: `visible`
  }
}));

export const CustomMenuButton = styled(IconButton)(({ theme }) => ({
  width: `48px`,
  height: `36px`,
  borderRadius: `36px`,
  backgroundColor: theme.palette.grey[200],
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: 0,
  marginRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.grey[300]
  }
}));

export const ShopButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  height: "32px",
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  fontSize: "1.2rem",
  lineHeight: "1.6rem",
  letterSpacing: "1.5px",
  fontWeight: 600,
  border: `1px solid ${theme.palette.grey[300]}`,
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary
  }
}));
