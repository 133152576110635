import React, { useCallback } from "react";
import { createContext } from "use-context-selector";
import countries from "../../config/countries";
import { getDefaultSubcountry, isValidSubcountry } from "../helpers/country";
import { useLocalStorageState } from "../helpers/localstorage";
import useCountry from "../hooks/use-country";
import useIsMounted from "../hooks/use-is-mounted";
import { CountryType } from "../types/airgraft";

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <SubcountryProvider>.");
};

interface SubcountryContextType {
  /**
   * Current selected subcountry
   */
  subcountry: string;

  /**
   * Change selected subcountry
   */
  setSubcountry: (subcountry: string, country?: CountryType) => void;

  /**
   * Has loaded subcountry from localstorage after page is mounted?
   */
  isSubcountryMounted: boolean;
}

const SubcountryContext = createContext<SubcountryContextType>({
  subcountry: null,
  setSubcountry: stub,
  isSubcountryMounted: null
});

type StatePerCountryType = Record<
  CountryType,
  [string, React.Dispatch<React.SetStateAction<string>>]
>;

const SubcountryProvider = ({ children }) => {
  const currentCountry = useCountry();

  // Keep one useLocalStorageState per country. This is to prevent issues when changing localstorage keys.
  const statePerCountry = Object.keys(countries).reduce<StatePerCountryType>(
    (accumulator, country) => ({
      ...accumulator,
      [country]: useLocalStorageState<string>(
        `airgraft-subcountry-${country}`,
        null
      )
    }),
    {} as StatePerCountryType
  );

  const currentSubcountry =
    statePerCountry[currentCountry] &&
    statePerCountry[currentCountry].length > 0
      ? (statePerCountry[currentCountry][0] as string)
      : null;

  const isMounted = useIsMounted();

  const subcountry =
    isMounted &&
    currentSubcountry &&
    isValidSubcountry(currentCountry, currentSubcountry)
      ? currentSubcountry
      : getDefaultSubcountry(currentCountry);

  return (
    <SubcountryContext.Provider
      value={{
        subcountry,
        setSubcountry: useCallback(
          (subcountry, specificCountry?: CountryType) => {
            const setSubcountry = statePerCountry[
              specificCountry || currentCountry
            ][1] as React.Dispatch<React.SetStateAction<string>>;
            setSubcountry(subcountry);
          },
          [currentCountry]
        ),
        isSubcountryMounted: isMounted
      }}
    >
      {children}
    </SubcountryContext.Provider>
  );
};

export { SubcountryContext, SubcountryProvider };
