import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme
} from "@material-ui/core";
import { navigate } from "gatsby";
import React from "react";
import { getSubcountryLabel } from "../../../helpers/country";
import { getFeatureFlag } from "../../../helpers/feature-flags";
import { getLocaleFromCountryLang } from "../../../helpers/locale";
import { getLocalizedPath } from "../../../helpers/path";
import useCountry from "../../../hooks/use-country";
import { useStoreLocatorAddressSelector } from "../../../hooks/use-store-locator-address";
import useSubcountry from "../../../hooks/use-subcountry";
import useTranslate from "../../../hooks/use-translate";

export default function AddressStatusMessage() {
  const theme = useTheme();
  const t = useTranslate();
  const {
    address,
    isLoadingAddress,
    addressMatchingCountry,
    addressMatchingSubcountry
  } = useStoreLocatorAddressSelector(c => ({
    address: c.address,
    isLoadingAddress: c.isLoadingAddress,
    addressMatchingCountry: c.addressMatchingCountry,
    addressMatchingSubcountry: c.addressMatchingSubcountry
  }));
  const country = useCountry();
  const { subcountry, setSubcountry } = useSubcountry();

  const isAddressMatchingCurrentRegion =
    country === addressMatchingCountry &&
    subcountry === addressMatchingSubcountry;

  const toggleAddressPanel = useStoreLocatorAddressSelector(
    c => c.toggleAddressPanel
  );

  const handleChangeRegionClick = () => {
    setSubcountry(addressMatchingSubcountry, addressMatchingCountry);
    const redirectLocale = getLocaleFromCountryLang(
      addressMatchingCountry,
      "en"
    );
    // Redirect to store locator in a different region, or to homepage
    const isStoreLocatorEnabledForMatchingSubcountry = getFeatureFlag(
      addressMatchingCountry,
      "STORE_LOCATOR",
      { subcountry: addressMatchingSubcountry }
    );
    const redirectTo = getLocalizedPath(
      redirectLocale,
      isStoreLocatorEnabledForMatchingSubcountry ? "/store-locator" : "/"
    );
    toggleAddressPanel(false);
    navigate(redirectTo);
  };

  return (
    <>
      {/* Loading: Fetching addess from id */}
      {isLoadingAddress && (
        <Box mt={1} mb={1} display="flex" alignItems="center">
          <CircularProgress size={14} color="secondary" disableShrink />
          <Typography variant="body2" style={{ marginLeft: theme.spacing(1) }}>
            {t("storelocator.loadingAddress")}
          </Typography>
        </Box>
      )}

      {/* Error: Wrong country/subcountry */}
      {address && !isAddressMatchingCurrentRegion && (
        <Box mt={1} mb={2}>
          <Typography
            variant="body2"
            color="error"
            component="span"
            dangerouslySetInnerHTML={{
              __html: t("storelocator.notAvailable", {
                subcountry: getSubcountryLabel(subcountry)
              })
            }}
          />
          &nbsp;
          {addressMatchingSubcountry && (
            <>
              {/* Please switch your region... */}
              <Typography variant="body2" component="span">
                {t("storelocator.changeRegion", {
                  subcountry: getSubcountryLabel(address.state) || address.state
                })}
              </Typography>
              {/* "Change website region" button */}
              <Box mt={1.5}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleChangeRegionClick}
                >
                  {t("storelocator.changeRegionCta", {
                    subcountry:
                      getSubcountryLabel(address.state) || address.state
                  })}
                </Button>
              </Box>
            </>
          )}
          {!addressMatchingSubcountry && (
            <Typography variant="body2" component="span">
              {t("storelocator.useAnotherAddress", {
                subcountry: getSubcountryLabel(subcountry)
              })}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}
