import { LangType, LocaleType, CountryType } from "../types/airgraft";
import { getLocalizedPath } from "./path";
import { navigate } from "gatsby";
import LOCALES from "../../config/locales";

export function getLocaleFromCountryLang(country: CountryType, lang: LangType) {
  return Object.keys(LOCALES).reduce<LocaleType>(
    (accumulator, locale: LocaleType) => {
      if (
        LOCALES[locale].country === country &&
        LOCALES[locale].lang === lang
      ) {
        return locale;
      }
      return accumulator;
    },
    null
  );
}

export function navigateToLocale(locale: LocaleType, unlocalizedPath: string) {
  const redirectPath = getLocalizedPath(locale, unlocalizedPath);
  navigate(redirectPath);
}

export function isValidLocale(locale: string) {
  return !!LOCALES[locale];
}
