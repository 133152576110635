import { Box, styled, Typography } from "@material-ui/core";
import React from "react";
import LocalizedLink from "../../../../../components/localized-link";
import { getLinkHref } from "../../../../../content-modules/card/utils";
import useTranslate from "../../../../../hooks/use-translate";
import { NotificationType } from "../../../../../providers/notification";
import Imgix from "react-imgix";
import SeparatorBullet from "../../../../../components/separator-bullet";

export const NotificationItem = styled(LocalizedLink)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  overflow: "hidden",
  flexShrink: 0,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  "&:hover": {
    background: theme.palette.grey[200]
  },
  padding: theme.spacing(2),
  "& img": {
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
    borderRadius: "3px",
    overflow: "hidden",
    background: theme.palette.grey[200]
  }
}));

const CategoryTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  lineHeight: 1,
  fontWeight: 600,
  textTransform: "uppercase",
  letterSpacing: "0.5px",
  whiteSpace: "nowrap"
}));

const TimeTypography = styled(CategoryTypography)(({ theme }) => ({
  color: theme.palette.grey[600]
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  lineHeight: 1.2
}));

type Props = {
  className?: string;
  notifications: NotificationType[];
  onNotificationClick: (notification: NotificationType) => void;
};

export default function NotificationPanelList({
  className,
  notifications,
  onNotificationClick
}: Props) {
  const t = useTranslate();

  function getNotificationTimeLabel(notification: NotificationType) {
    if (notification.unread) {
      return t("notification.new");
    }
    return getRelativeTimeLabel(
      notification.meta.publishedAt || notification.meta.createdAt
    );
  }

  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      paddingX={0}
      flex={1}
      width="100%"
      overflow="auto"
    >
      {notifications.map(notification => {
        const image = getNotificationImage(notification);
        const time = getNotificationTimeLabel(notification);
        return (
          <NotificationItem
            key={notification.originalId}
            to={getNotificationLink(notification)}
            onClick={() => onNotificationClick(notification)}
            data-analytics="notification.click"
          >
            {image && (
              <Imgix
                src={image}
                width={50}
                height={50}
                imgixParams={{
                  ar: "1",
                  fit: "crop",
                  auto: "format,compress"
                }}
              />
            )}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={0.75}
              >
                <CategoryTypography>{notification.category}</CategoryTypography>
                {time && <SeparatorBullet color="yellow" />}
                {time && <TimeTypography>{time}</TimeTypography>}
              </Box>
              <TextTypography
                dangerouslySetInnerHTML={{
                  __html: notification.text
                }}
              />
            </Box>
          </NotificationItem>
        );
      })}
    </Box>
  );
}

function getNotificationLink(notification: NotificationType) {
  return getLinkHref(notification.link);
}

function getNotificationImage(notification: NotificationType) {
  if (notification.image?.url) {
    return notification.image?.url;
  }
  if (notification.link.model.apiKey === "product") {
    return (notification.link as any).variants[0]?.featuredImage?.url;
  }
  return "https://www.datocms-assets.com/15671/1702489061-notification.png";
}

function getRelativeTimeLabel(dateString: string): string {
  const now = new Date();
  const date = new Date(dateString);
  const timeDifference = now.getTime() - date.getTime();
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days === 0) {
    return "Today";
  } else if (days === 1) {
    return "Yesterday";
  } else if (days > 1 && days <= 7) {
    return `${days} days ago`;
  } else {
    const weeks = Math.floor(days / 7);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  }
}
