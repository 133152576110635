import { Box, Button, Fade } from "@material-ui/core";
import React from "react";
import { useExpressDeliveryAvailabilitySelector } from "../../../hooks/use-express-delivery-availability";
import useTranslate from "../../../hooks/use-translate";

type Props = {
  onClick: () => void;
};

export default function AddressConfirmButton({ onClick }: Props) {
  const t = useTranslate();
  const address = useExpressDeliveryAvailabilitySelector(c => c.address);
  const hasNoAvailableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.hasNoAvailableProvider
  );
  const isLoadingAvailableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.isLoadingAvailableProvider
  );

  if (!address || hasNoAvailableProvider || isLoadingAvailableProvider) {
    return null;
  }

  return (
    <Fade in timeout={250}>
      <Box mt={2}>
        <Button variant="contained" color="primary" fullWidth onClick={onClick}>
          {t("expressDelivery.useAddress")}
        </Button>
      </Box>
    </Fade>
  );
}
