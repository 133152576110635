export const isPhoneNumber = (value: string) => {
  return value.match(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i
  );
};

export function formatPhoneNumberForApi(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, ""); // +1 514 283 2323 => 1514283232
}

export function prettifyPhoneNumber(phoneNumber: string) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}
