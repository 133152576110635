import FEATURE_FLAGS, {
  FeatureFlagsType,
  FEATURE_FLAGS_DISABLED_PAGES
} from "../../config/feature-flags";
import { CountryType } from "../types/airgraft";

/**
 * Helper to check if feature flag is enabled for a locale. Use by useFeatureFlag hook.
 */
export function getFeatureFlag(
  country: CountryType,
  flag: FeatureFlagsType,
  options?: { subcountry?: string }
) {
  // Check any of the subcountries of the specified country has the feature flag enabled
  const subcountries: string[] = options?.subcountry
    ? [options?.subcountry]
    : Object.keys(FEATURE_FLAGS[country] || []);

  return !!subcountries.find(subcountry => {
    if (
      FEATURE_FLAGS[country] === undefined ||
      FEATURE_FLAGS[country][subcountry] === undefined ||
      FEATURE_FLAGS[country][subcountry][flag] === undefined
    ) {
      return false; // Default to false if feature flag is missing
    }
    return !!FEATURE_FLAGS[country][subcountry][flag];
  });
}

/**
 * Helper to check if a page is disabled for a locale. Used by gatsby-node at build time.
 */
export function isPageDisabledByFeatureFlags(
  country: CountryType,
  pagePath: string,
  options?: { subcountry?: string }
): boolean {
  return Object.keys(FEATURE_FLAGS_DISABLED_PAGES).reduce(
    (accumulator, featureFlag) => {
      // Skip if FF is enabled or if no disabled pages specified for feature flag
      if (
        !FEATURE_FLAGS_DISABLED_PAGES[featureFlag] ||
        getFeatureFlag(country, featureFlag as FeatureFlagsType, options)
      ) {
        return accumulator;
      }
      // Is pagePath blacklisted for the disabled flag?
      const disabledPages = FEATURE_FLAGS_DISABLED_PAGES[
        featureFlag
      ] as string[];
      const isPageDisabled = !!disabledPages.find(p => {
        return wildcardToRegExp(addSlashesIfNeeded(p)).test(
          addSlashesIfNeeded(pagePath)
        );
      });
      return isPageDisabled || accumulator;
    },
    false
  );
}

/**
 * Helper used by isPageDisabledByFeatureFlags
 * Source: https://gist.github.com/donmccurdy/6d073ce2c6f3951312dfa45da14a420f#file-wildcard-to-regexp-js
 */
export function wildcardToRegExp(s: string) {
  return new RegExp(
    "^" +
      s
        .split(/\*+/)
        .map(s => s.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&"))
        .join(".*") +
      "$"
  );
}

/**
 * Add prefix and suffix slashes if needed
 * "test" => "/test/"
 * "test*" => "/test*"
 * "/test/*" => /test/*
 */
export function addSlashesIfNeeded(pagePath: string) {
  if (!pagePath) {
    return pagePath;
  }
  // prettier-ignore
  return  `${pagePath[0] === "/" ? "" : "/"}${pagePath}${["/", "*"].includes(pagePath[pagePath.length - 1]) ? "" : "/"}`;
}
