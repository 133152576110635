import { Fade, PopoverProps, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { LayoutContext } from "../../../providers/layout";
import {
  CustomBackdrop,
  CustomModalCloseButton,
  CustomPopover
} from "./styles";

export type ExpressPanelVariantType = "default" | "express-delivery";

type Props = Pick<
  PopoverProps,
  | "open"
  | "onClose"
  | "children"
  | "anchorEl"
  | "anchorOrigin"
  | "transformOrigin"
> & {
  disableMobileHeaderOverModal?: boolean;
};

/**
 * Popover panel used for Express Delivery Cart & Address selector
 */
export default function HeaderPanelBase({
  children,
  disableMobileHeaderOverModal,
  ...props
}: Props) {
  const theme = useTheme();
  const setMobileHeaderOverModal = useContextSelector(
    LayoutContext,
    c => c.setMobileHeaderOverModal
  );

  // Force mobile header to appear on top of current modal
  useEffect(() => {
    if (!disableMobileHeaderOverModal) {
      setMobileHeaderOverModal(props.open);
      return () => {
        setMobileHeaderOverModal(false);
      };
    }
  }, [props.open, disableMobileHeaderOverModal]);

  return (
    <CustomPopover
      {...props}
      TransitionComponent={Fade}
      BackdropComponent={CustomBackdrop}
    >
      <CustomModalCloseButton
        onClick={e => props.onClose(e, "backdropClick")}
      />

      {/* Panel content */}
      {children}
    </CustomPopover>
  );
}
