import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function ChevronDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 16 8"
      {...props}
      style={{ fill: "none", ...(props.style || {}) }}
    >
      <path
        d="M1 1.71509L8 6.71509L15 1.71509"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
