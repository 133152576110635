import React from "react";
import { ButtonBase, Popper, styled, Typography } from "@material-ui/core";
import GoogleMapsAutocompleteField from "../../../components/googlemaps-autocomplete-field";

export const CustomGoogleMapsAutocompleteField = styled(
  GoogleMapsAutocompleteField
)(({ theme }) => ({
  height: "40px",
  background: theme.palette.common.white,
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderTopRightRadius: "0px",
  borderBottomRightRadius: "0px",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRight: "0px",
  "& .MuiInput-underline:after, & .MuiInput-underline:before": {
    display: "none"
  },
  "& .MuiInputBase-root": {
    paddingRight: `${theme.spacing(0)}px !important`
  },
  "& .MuiInputBase-input": {
    height: "26px",
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(3)}px !important`,
    "&::placeholder": {
      color: theme.palette.text.primary
    }
  },
  "& .MuiAutocomplete-clearIndicator": {
    marginRight: "2px"
  }
}));

export const CustomGeolocationButton = styled(ButtonBase)(({ theme }) => ({
  background: theme.palette.common.black,
  borderTopLeftRadius: "0px",
  borderBottomLeftRadius: "0px",
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px",
  width: "50px",
  height: "40px",
  "& svg path": {
    fill: theme.palette.common.white
  },
  "& .MuiCircularProgress-svg": {
    color: theme.palette.common.white
  },
  "&:hover": {
    "& svg path": {
      fill: theme.palette.grey[600]
    }
  }
}));

export const CustomAutocompletePopper = styled(props => (
  <Popper {...props} placement="bottom-start" />
))(({ theme }) => ({
  width: "352px !important",
  zIndex: theme.zIndex.modal + 3,
  [theme.breakpoints.down("sm")]: {
    left: "0px",
    right: "0px",
    width: "calc(100vw - 8px) !important"
  }
}));
