import { graphql, useStaticQuery } from "gatsby";
import { useQuery } from "react-query";
import LOCALES from "../../config/locales";
import { getProductDetailsPath } from "../helpers/url";
import { LocaleType, ProductType } from "../types/airgraft";
import useLocale from "./use-locale";

export type ProductQuerySelectorType = {
  id?: string;
  slug?: string;
  variantId?: string;
  batchId?: string;
};

/**
 * Fetch Product object using product id, slug, variantId or batchId
 */
export default function useProductQuery(
  selector: ProductQuerySelectorType,
  queryOptions?: {
    enabled?: boolean;
  }
) {
  const locale = useLocale();

  if (!selector || Object.keys(selector).length !== 1) {
    throw new Error(
      "Invalid usage of useProduct: Missing or invalid first argument. You must specify a id, slug or batchId"
    );
  }

  // Query all DatoCMS products partial information (id, slug, batchID)
  const {
    allDatoCmsProduct: { nodes: allProducts }
  } = useStaticQuery<Queries.UseProductsQueryDataQuery>(graphql`
    query UseProductsQueryData {
      allDatoCmsProduct {
        nodes {
          originalId
          slug
          productType
          variants {
            originalId
            batchIds
          }
        }
      }
    }
  `);

  // Find partial product from provided selector
  let partialProduct: Queries.UseProductsQueryDataQuery["allDatoCmsProduct"]["nodes"][number] =
    null;
  if (selector.id) {
    partialProduct = allProducts.find(p => p.originalId === selector.id);
  } else if (selector.slug) {
    partialProduct = allProducts.find(p => p.slug === selector.slug);
  } else if (selector.variantId) {
    partialProduct = allProducts.find(p =>
      p.variants.find(pv => pv.originalId === selector.variantId)
    );
  } else if (selector.batchId) {
    partialProduct = allProducts.find(p =>
      p.variants.find(pv => pv.batchIds.includes(selector.batchId))
    );
  }

  // Query product using type & slug
  return useQuery(
    ["products-details", partialProduct?.originalId],
    () =>
      fetchProductFromGatsbyPageData(
        locale,
        partialProduct.productType,
        partialProduct.slug,
        { retryWithOtherLocale: true }
      ),
    {
      retry: 1,
      staleTime: 1000 * 60, // Re-use same data for 60 seconds
      enabled:
        queryOptions?.enabled !== undefined
          ? !!partialProduct && queryOptions.enabled
          : !!partialProduct // Dont query if we cant get productType & slug from selector
    }
  );
}

/**
 * Retreive full product object using Gatsby page-data created for each static PDP page.
 * The reason we need to use productType and productSlug is because
 * the filename follow the same pattern as the product details router path.
 */
export async function fetchProductFromGatsbyPageData(
  locale: LocaleType,
  productType: string,
  productSlug: string,
  options?: {
    retryWithOtherLocale?: boolean;
  }
): Promise<ProductType> {
  const response = await fetch(
    // prettier-ignore
    `/page-data/${LOCALES[locale].path}${getProductDetailsPath(productType, productSlug)}/page-data.json`
  );

  if (response.ok) {
    const json = await response.json();
    const data = json?.result?.data;

    if (!data || !data.datoCmsProduct) {
      throw new Error(
        `Failed to parse product ${productType}/${productSlug} from gatsby page data`
      );
    }

    return data.datoCmsProduct as ProductType;
  }

  // Try to get US product instead if cant find canadian product or vice-versa.
  // This happens inside MY_PODS if user as unlocked pods that are only available in Canada
  if (response.status === 404 && options?.retryWithOtherLocale) {
    return fetchProductFromGatsbyPageData(
      locale === "en-US" ? "en-CA" : "en-US",
      productType,
      productSlug
    );
  }

  throw new Error(
    `Failed to fetch product ${productType}/${productSlug} from gatsby page data`
  );
}
