import { styled, Theme } from "@material-ui/core";

const SeparatorBullet = styled("div")(
  ({ theme, color }: { theme: Theme; color?: "gray" | "yellow" }) => ({
    flexShrink: 0,
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: "100%",
    backgroundColor:
      color === "yellow" ? theme.palette.primary.main : theme.palette.grey[500],
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75)
    }
  })
);

export default SeparatorBullet;
