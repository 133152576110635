import { createTheme } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const defaultTheme = createTheme({});

const palette = {
  primary: {
    light: `#FFF94F`,
    main: `#FFBB00`,
    dark: `#C79600`
  },
  secondary: grey,
  error: {
    light: red[100],
    main: red[500]
  },
  background: {
    default: `#F9F7F1`,
    paper: `#F9F7F1`,
    grey: `#DDD9D2`
  },
  divider: `#EAE5DD`,
  text: {
    primary: `#1E1E1E`,
    secondary: `#ACA9A3`
  },
  grey: {
    [100]: `#F9F7F1`,
    [200]: `#F3F0E9`,
    [300]: `#EAE5DD`,
    [400]: `#DDD9D2`,
    [500]: `#C5C1BA`,
    [600]: `#ACA9A3`
  },
  info: {
    light: "#EBF8F8",
    main: "#35B8BD"
  },
  orange: "#FF8C33",
  expressDelivery: "#FC4E12"
};

export const typography: TypographyOptions = {
  // https://material-ui.com/customization/typography/#html-font-size
  // 1 rem = 10 px
  htmlFontSize: 10,
  fontSize: 12,
  fontFamily: ["Roobert", "Arial"].join(`,`),
  h1: {
    fontWeight: 300,
    fontFamily: "Reckless, Georgia",
    letterSpacing: `-0.3rem`,
    fontSize: `12rem`,
    lineHeight: 1,
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `16rem`,
      letterSpacing: `-0.4rem`
    },
    [defaultTheme.breakpoints.up(`md`)]: {
      fontSize: `20rem`,
      letterSpacing: `-0.5rem`
    }
  },
  h2: {
    fontWeight: 300,
    fontFamily: "Reckless, Georgia",
    letterSpacing: `-0.4rem`,
    fontSize: `8rem`,
    lineHeight: 1.1,
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `10rem`,
      letterSpacing: `-0.4rem`
    },
    [defaultTheme.breakpoints.up(`md`)]: {
      fontSize: `12rem`,
      letterSpacing: `-0.4rem`
    }
  },
  h3: {
    fontWeight: 400,
    fontFamily: "Reckless, Georgia",
    letterSpacing: `-0.1rem`,
    fontSize: `4rem`,
    lineHeight: 1.1,
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `5rem`,
      letterSpacing: `-0.1rem`
    },
    [defaultTheme.breakpoints.up(`md`)]: {
      fontSize: `6.4rem`,
      letterSpacing: `-0.2rem`
    }
  },
  h4: {
    fontWeight: 400,
    fontFamily: "Reckless, Georgia",
    letterSpacing: 0,
    fontSize: `3rem`,
    lineHeight: 1.25,
    "& > strong": {
      fontWeight: 500
    },
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `4rem`,
      letterSpacing: `-0.1rem`
    },
    [defaultTheme.breakpoints.up(`md`)]: {
      fontSize: `5rem`,
      letterSpacing: `-0.1rem`
    }
  },
  h5: {
    fontWeight: 400,
    fontFamily: "Reckless, Georgia",
    letterSpacing: 0,
    fontSize: `2.4rem`,
    lineHeight: 1.1,
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `2.6rem`
    },
    [defaultTheme.breakpoints.up(`md`)]: {
      fontSize: `3rem`
    }
  },
  h6: {
    fontWeight: 400,
    fontFamily: "Reckless, Georgia",
    letterSpacing: 0,
    fontSize: `2rem`,
    lineHeight: 1.1,
    [defaultTheme.breakpoints.up(`sm`)]: {
      fontSize: `2.4rem`
    }
  },
  body1: {
    fontWeight: 400,
    fontFamily: "Roobert, Arial",
    letterSpacing: 0,
    fontSize: `1.6rem`,
    lineHeight: `2.4rem`,
    "& > p": {
      fontSize: `1.6rem`,
      lineHeight: `2.4rem`
    },
    "& > a": {
      fontSize: `1.6rem`,
      lineHeight: `2.4rem`
    }
  },
  body2: {
    fontWeight: 400,
    fontFamily: "Roobert, Arial",
    letterSpacing: 0,
    fontSize: `1.4rem`,
    lineHeight: `2rem`,
    "& > p": {
      fontSize: `1.4rem`,
      lineHeight: `2rem`
    },
    "& > a": {
      fontSize: `1.4rem`,
      lineHeight: `2rem`
    }
  },
  caption: {
    fontWeight: 400,
    fontFamily: "Roobert, Arial",
    letterSpacing: 0,
    lineHeight: `1.6rem`,
    fontSize: `1.2rem`,
    "& a": {
      fontSize: `1.1rem`,
      lineHeight: `1.4rem`
    }
  },
  button: {
    fontWeight: 600,
    fontFamily: "Roobert, Arial",
    letterSpacing: `1.5px`,
    textTransform: "uppercase",
    lineHeight: `1.6rem`,
    fontSize: `1.4rem`
  },
  overline: {
    fontWeight: 400,
    fontFamily: "Roobert, Arial",
    letterSpacing: `0.75px`,
    lineHeight: 1.42,
    fontSize: `0.71rem`
  }
};

const theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: defaultTheme.spacing(2)
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ":root": {
          "--header-height": "64px",
          [defaultTheme.breakpoints.down("sm")]: {
            "--header-height": "50px"
          }
        },
        "*": {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          "box-sizing": "border-box",
          "&::before, &::after": {
            "box-sizing": "border-box"
          }
        },
        html: {
          fontSize: `62.5%`, // 1rem = 10px
          backgroundColor: palette.grey[200]
        },
        body: {
          overflowX: "hidden", // Prevent horizontal scrollbar
          backgroundColor: palette.grey[200]
        },
        "html, body, #___gatsby": {
          height: "100vh" // Fixes issues with Safari 15 not rendering unlock app full height
        },
        // Force main wrappers to expand vertically. Necessary for pages containing only position absolute elements (Ex Store locator map view)
        "#___gatsby, #gatsby-focus-wrapper": {
          display: "flex",
          flex: "1 0 auto",
          flexDirection: "column"
        },
        video: {
          // Prevent weird border around <video> elements
          // https://stackoverflow.com/questions/52508598/html5-video-element-on-iphone-has-border/53779216
          "-webkit-mask-image": "-webkit-radial-gradient(white, black)",
          "-webkit-backface-visibility": "hidden",
          "-moz-backface-visibility": "hidden",
          border: 0,
          outline: 0
        },
        "h1, h2, h3, h4, h5, h6, span, div, a, p, caption": {
          margin: 0,
          padding: 0
        },
        a: {
          color: `inherit` /* blue colors for links too */,
          textDecoration: `inherit` /* no underline */
        },
        button: {
          cursor: "pointer"
        },
        "b, strong": {
          fontWeight: 600
        },
        // Using global styles because SnackbarProvider is parent of the theme provider
        // @ts-ignore
        ".MuiSnackbar-root": {
          // @ts-ignore
          zIndex: "9000 !important",
          "&.MuiSnackbar-anchorOriginTopCenter": {
            top: "calc(var(--header-height) + 8px)"
          }
        },
        ".MuiSnackbar-root .MuiSnackbarContent-root": {
          backgroundColor: defaultTheme.palette.common.white,
          color: defaultTheme.palette.common.black,
          fontSize: "1.4rem",
          fontWeight: 500
        },
        ".MuiAutocomplete-popper .MuiAutocomplete-paper": {
          background: defaultTheme.palette.common.white
        }
      }
    },
    MuiContainer: {
      root: {
        [defaultTheme.breakpoints.up("lg")]: {
          paddingLeft: defaultTheme.spacing(8),
          paddingRight: defaultTheme.spacing(8)
        },
        [defaultTheme.breakpoints.only("md")]: {
          paddingLeft: defaultTheme.spacing(4),
          paddingRight: defaultTheme.spacing(4)
        },
        "@media (max-width: 320px)": {
          paddingLeft: defaultTheme.spacing(1),
          paddingRight: defaultTheme.spacing(1)
        }
      }
    },
    MuiTypography: {
      colorTextSecondary: {
        color: palette.grey[600]
      },
      colorError: {
        color: `#FF4848`,
        "& a:not(.MuiTypography-root)": {
          color: `#FF4848`
        }
      },
      root: {
        "& a:not(.MuiTypography-root)": {
          color: palette.primary.main,
          "&:hover": {
            color: palette.primary.dark
          }
        },
        "&.white-links a:not(.MuiTypography-root)": {
          color: defaultTheme.palette.common.white,
          "&:hover": {
            color: palette.grey[100]
          }
        },
        "&.bold, & .bold": {
          fontWeight: 600
        },
        "&.white, & .white": {
          color: defaultTheme.palette.common.white
        },
        "&.yellow, & .yellow": {
          color: palette.primary.main
        },
        "&.black, & .black": {
          color: palette.text.primary
        },
        "&.express-delivery, & .express-delivery": {
          color: palette.expressDelivery
        },
        "&.center, & .center": {
          textAlign: "center"
        },
        "&.underline, & .underline": {
          textDecoration: "underline",
          textUnderlinePosition: "under"
        },
        "&.compact": {
          fontFamily: "Roobert, Arial",
          fontSize: "1.2rem",
          fontWeight: 600,
          letterSpacing: "1px",
          textTransform: "uppercase"
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: "2.4rem"
      }
    },
    MuiButton: {
      root: {
        minHeight: defaultTheme.spacing(4.5),
        borderRadius: defaultTheme.spacing(4.5),
        boxShadow: `unset !important`,
        "&$disabled": {
          opacity: `1`,
          color: `inherit`
        },
        "&.danger": {
          backgroundColor: `#FF4848`,
          color: "#FFFFFF"
        },
        "&.danger:hover": {
          backgroundColor: `#f24b4b`
        },
        "&.compact": {
          fontFamily: "Roobert, Arial",
          fontSize: "1.2rem",
          fontWeight: 600,
          letterSpacing: "1px",
          textTransform: "uppercase",
          paddingLeft: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(1.5),
          "& .MuiButton-startIcon": {
            marginRight: "4px",
            "& .MuiSvgIcon-root": {
              fontSize: "1.4rem"
            }
          }
        },
        "&.light": {
          fontWeight: 400
        },
        "&.gray": {
          backgroundColor: palette.grey[300],
          "&:hover": {
            backgroundColor: palette.grey[400]
          }
        },
        "&.express-delivery": {
          backgroundColor: palette.expressDelivery,
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#e44711"
          }
        },
        "&.MuiButton-sizeXLarge": {
          minHeight: "48px",
          borderRadius: "36px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04) !important",
          fontSize: "2rem",
          lineHeight: "3rem",
          letterSpacing: "-0.1px",
          fontFamily: "Reckless, Georgia",
          textTransform: "none",
          fontWeight: 500,
          paddingBottom: "8px"
        }
      },
      contained: {
        backgroundColor: defaultTheme.palette.common.white,
        fontWeight: 600
      },
      text: {
        letterSpacing: "1.35px",
        fontSize: "1.3rem",
        "& .MuiButton-startIcon": {
          marginRight: "4px",
          "& .MuiSvgIcon-root": {
            fontSize: "1.6rem"
          }
        },
        "& .MuiButton-endIcon": {
          marginLeft: "4px",
          "& .MuiSvgIcon-root": {
            fontSize: "1.6rem"
          }
        }
      },
      sizeSmall: {
        padding: "0px 24px",
        minHeight: "28px",
        fontSize: "12px",
        borderRadius: "32px",
        "& > span": {
          letterSpacing: "1px",
          fontWeight: 600,
          fontSize: "12px"
        }
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: "10px"
        }
      }
    },
    MuiBottomNavigationAction: {
      root: {
        padding: `16px 12px`,
        "&$selected": {
          paddingTop: `16px`
        }
      },
      iconOnly: {
        paddingTop: `inherit`
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "Roobert, Arial"
      },
      input: { height: defaultTheme.spacing(5) }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderRadius: "3px",
        border: `1px solid ${palette.grey[200]}`,
        backgroundColor: defaultTheme.palette.common.white,
        "&:hover, &.Mui-focused": {
          backgroundColor: `${defaultTheme.palette.common.white} !important`
        }
      },
      input: {
        padding: "6px 6px 7px 6px",
        "&::placeholder": {
          color: defaultTheme.palette.common.black,
          opacity: 0.8
        }
      },
      underline: {
        "&:before": {
          display: "none"
        }
      }
    },
    MuiSelect: {
      outlined: {
        "&:focus": {
          borderRadius: defaultTheme.spacing(1)
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: defaultTheme.spacing(1),
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.grey[600]
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.primary.main,
          borderWidth: `1px`
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: `#FF4848`
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.grey[400]
        },
        "&.MuiInputBase-root.MuiInputBase-adornedStart": {
          paddingLeft: 0
        },
        "&.MuiInputBase-adornedStart .MuiSvgIcon-root": {
          position: `absolute`,
          marginLeft: defaultTheme.spacing(1.75),
          color: palette.grey[600]
        },
        "&.Mui-focused.MuiInputBase-adornedStart .MuiSvgIcon-root": {
          color: palette.primary.main
        },
        "&.Mui-error.MuiInputBase-adornedStart .MuiSvgIcon-root": {
          color: "#FF4848"
        },
        "&.Mui-disabled.MuiInputBase-adornedStart .MuiSvgIcon-root": {
          color: palette.grey[400]
        },
        "&:hover .MuiSvgIcon-root": {
          color: palette.grey[600]
        }
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        padding: defaultTheme.spacing(1.75, 2)
      },
      input: {
        height: `20px`,
        lineHeight: `20px`,
        fontSize: `1.4rem`,
        padding: defaultTheme.spacing(1.75, 2),
        backgroundColor: palette.grey[100],
        borderRadius: defaultTheme.spacing(1),
        "&::placeholder": {
          color: defaultTheme.palette.common.black,
          opacity: 0.6
        },
        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: defaultTheme.spacing(5.75)
        },
        "&.Mui-disabled": {
          color: `rgba(0, 0, 0, 0.2)`
        },
        "&.Mui-disabled::placeholder": {
          color: `rgba(0, 0, 0, 0.2)`
        }
      },
      notchedOutline: {
        borderColor: palette.grey[400]
      }
    },
    MuiInputLabel: {
      outlined: {
        color: `rgba(0, 0, 0, 0.6)`,
        fontSize: `1.4rem`,
        lineHeight: `20px`,
        transform: `translate(16px, 14px) scale(1)`,
        "&.Mui-focused": {
          color: `rgba(0, 0, 0, 0.6)`
        },
        "&.Mui-error": {
          color: `#FF4848`
        },
        "&.Mui-disabled": {
          color: `rgba(0, 0, 0, 0.2)`
        },
        "&.MuiInputLabel-shrink": {
          transform: `translate(14px, -8px) scale(0.79)`
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: palette.text.primary,
        fontSize: `1.1rem`,
        "&$error": {
          color: `#FF4848`,
          margin: defaultTheme.spacing(0.5, 2)
        }
      },
      contained: {
        marginLeft: defaultTheme.spacing(0.5),
        marginRight: defaultTheme.spacing(0.5)
      }
    },
    MuiAutocomplete: {
      root: {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
          padding: defaultTheme.spacing(1.75, 2),
          backgroundColor: palette.grey[100]
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
          {
            padding: 0,
            backgroundColor: `unset`
          },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
          {
            paddingLeft: 0
          }
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: palette.background.paper
      },
      rounded: {
        borderRadius: "3px"
      }
    },
    MuiChip: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        "& > .MuiChip-label": {
          fontSize: "1.4rem",
          lineHeight: "1.4rem"
        },
        "& > .MuiChip-deleteIcon": {
          display: "none"
        }
      },
      outlined: {
        borderColor: palette.grey[600]
      },
      sizeSmall: {
        borderColor: palette.text.primary,
        borderRadius: "3px",
        fontSize: "12px",
        height: "22px",
        "& > span": {
          padding: "0 10px"
        }
      }
    },
    MuiTooltip: {
      popper: {
        marginBottom: "-6px"
      },
      tooltip: {
        fontSize: "12px",
        letterSpacing: "0px",
        borderRadius: "3px",
        color: palette.text.primary,
        backgroundColor: "white",
        padding: "4px 8px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"
      },
      arrow: {
        color: "white"
      }
    },
    MuiBadge: {
      root: {
        "&.express-delivery .MuiBadge-badge": {
          backgroundColor: palette.expressDelivery,
          color: defaultTheme.palette.common.white
        },
        "&.large .MuiBadge-badge": {
          width: "24px",
          height: "24px",
          borderRadius: "24px",
          fontSize: "1.4rem"
        }
      },
      badge: {
        fontWeight: 600,
        height: "16px",
        minWidth: "16px",
        borderRadius: "8px",
        padding: 0
      },
      colorPrimary: {
        backgroundColor: palette.orange,
        color: "#fff"
      }
    },
    MuiSkeleton: {
      text: {
        borderRadius: "10px"
      }
    },
    MuiBreadcrumbs: {
      root: {
        display: "flex",
        justifyContent: "center",
        fontFamily: "Roobert, Arial",
        fontSize: "1.2rem",
        lineHeight: "1.6rem",
        fontWeight: 600,
        letterSpacing: "1.5px",
        textTransform: "uppercase",
        "& a": {
          color: `${palette.grey[600]} !important`,
          fontWeight: 600
        },
        "& .MuiTypography-root": {
          fontSize: "1.2rem",
          lineHeight: "1.6rem",
          fontWeight: 600,
          color: palette.text.primary,
          letterSpacing: "1.5px"
        }
      }
    },
    MuiRating: {
      sizeLarge: {
        fontSize: "5rem"
      }
    },
    // @ts-ignore
    MuiAlert: {
      root: {
        padding: "8px 16px",
        borderRadius: "3px",
        "& .MuiAlert-message": {
          width: "100%",
          wordBreak: "break-word"
        }
      },
      icon: {
        alignItems: `center`
      },
      standardInfo: {
        backgroundColor: "#F1F1F1",
        color: palette.text.primary,
        "& .MuiAlert-icon": {
          color: palette.text.primary
        }
      },
      standardError: {
        color: "#FF4848",
        backgroundColor: "rgb(255 72 72 / 0.1)"
      },
      filledWarning: {
        backgroundColor: `${palette.primary.main}!important`,
        color: palette.text.primary,
        "& .MuiAlert-action": {
          color: defaultTheme.palette.common.white
        }
      },
      filledInfo: {
        backgroundColor: defaultTheme.palette.common.white,
        color: palette.text.primary,
        "& .MuiAlert-action": {
          color: palette.primary.main
        }
      }
    }
  }
});

export default theme;
