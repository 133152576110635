exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delivery-checkout-tsx": () => import("./../../../src/pages/delivery/checkout.tsx" /* webpackChunkName: "component---src-pages-delivery-checkout-tsx" */),
  "component---src-pages-delivery-order-tsx": () => import("./../../../src/pages/delivery/order.tsx" /* webpackChunkName: "component---src-pages-delivery-order-tsx" */),
  "component---src-pages-rating-tsx": () => import("./../../../src/pages/rating.tsx" /* webpackChunkName: "component---src-pages-rating-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-shop-devices-tsx": () => import("./../../../src/pages/shop/devices.tsx" /* webpackChunkName: "component---src-pages-shop-devices-tsx" */),
  "component---src-pages-shop-pods-tsx": () => import("./../../../src/pages/shop/pods.tsx" /* webpackChunkName: "component---src-pages-shop-pods-tsx" */),
  "component---src-pages-shop-products-tsx": () => import("./../../../src/pages/shop/products.tsx" /* webpackChunkName: "component---src-pages-shop-products-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-store-locator-tsx": () => import("./../../../src/pages/store-locator.tsx" /* webpackChunkName: "component---src-pages-store-locator-tsx" */),
  "component---src-pages-unlock-tsx": () => import("./../../../src/pages/unlock.tsx" /* webpackChunkName: "component---src-pages-unlock-tsx" */),
  "component---src-pages-user-account-email-tsx": () => import("./../../../src/pages/user/account/email.tsx" /* webpackChunkName: "component---src-pages-user-account-email-tsx" */),
  "component---src-pages-user-account-index-tsx": () => import("./../../../src/pages/user/account/index.tsx" /* webpackChunkName: "component---src-pages-user-account-index-tsx" */),
  "component---src-pages-user-account-phone-number-tsx": () => import("./../../../src/pages/user/account/phone-number.tsx" /* webpackChunkName: "component---src-pages-user-account-phone-number-tsx" */),
  "component---src-pages-user-orders-tsx": () => import("./../../../src/pages/user/orders.tsx" /* webpackChunkName: "component---src-pages-user-orders-tsx" */),
  "component---src-pages-user-pods-tsx": () => import("./../../../src/pages/user/pods.tsx" /* webpackChunkName: "component---src-pages-user-pods-tsx" */),
  "component---src-templates-brand-page-index-tsx": () => import("./../../../src/templates/BrandPage/index.tsx" /* webpackChunkName: "component---src-templates-brand-page-index-tsx" */),
  "component---src-templates-content-modules-page-index-tsx": () => import("./../../../src/templates/ContentModulesPage/index.tsx" /* webpackChunkName: "component---src-templates-content-modules-page-index-tsx" */),
  "component---src-templates-product-details-page-index-tsx": () => import("./../../../src/templates/ProductDetailsPage/index.tsx" /* webpackChunkName: "component---src-templates-product-details-page-index-tsx" */)
}

