import {
  Box,
  Button,
  CircularProgress,
  PopoverProps,
  Typography,
  useTheme
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AnimatePresence, motion } from "framer-motion";
import { navigate } from "gatsby-link";
import React, { useState } from "react";
import Imgix from "react-imgix";
import LocalizedLink from "../../../components/localized-link";
import { formatPrice } from "../../../helpers/price";
import { useExpressDeliveryAvailabilitySelector } from "../../../hooks/use-express-delivery-availability";
import useExpressDeliveryCart from "../../../hooks/use-express-delivery-cart";
import useIsAuthenticated from "../../../hooks/use-is-authenticated";
import useLocalizedLocationPath from "../../../hooks/use-localized-location-path";
import useTranslate from "../../../hooks/use-translate";
import { CartItemType } from "../../../providers/express-delivery-cart";
import HeaderPanelBase from "../PanelBase";
import { PanelTitle } from "../PanelBase/styles";
import BatterySuggestion from "./BatterySuggestion";
import CartItemQuantitySelector from "./CartItemQuantitySelector";
import EMPTY_CART_LOGO from "../NotificationPanel/components/EmptyList/empty.png";
import {
  ProductItemSecondaryTypography,
  ProductItemTypography,
  SubtotalPriceTypography
} from "./styles";

function ExpressCartPanel({
  anchorEl,
  ...props
}: Pick<PopoverProps, "anchorEl" | "anchorOrigin" | "transformOrigin">) {
  const t = useTranslate();
  const theme = useTheme();
  const getLocalizedPath = useLocalizedLocationPath();
  const isAuthenticated = useIsAuthenticated();
  const availableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.availableProvider
  );
  const availableProducts = useExpressDeliveryAvailabilitySelector(
    c => c.availableProducts
  );
  const isLoadingAvailableProducts = useExpressDeliveryAvailabilitySelector(
    c => c.isLoadingAvailableProducts
  );
  const toggleAvailabilityPanel = useExpressDeliveryAvailabilitySelector(
    c => c.toggleAvailabilityPanel
  );

  const {
    isCartPanelOpen,
    toggleCartPanel,
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
    removeItem,
    isCartEmpty,
    cartError
  } = useExpressDeliveryCart();

  // Detect which/how many cart items are available based on current address availability
  const isCartItemAvailable = (cartItem: CartItemType) => {
    return (
      isLoadingAvailableProducts ||
      availableProducts?.find(
        pv => pv.productVariantId === cartItem.productVariantId
      )
    );
  };
  const availableCartItemsCount = items.filter(item =>
    isCartItemAvailable(item)
  ).length;

  const getCheckoutPath = () => {
    if (isAuthenticated) {
      return "/delivery/checkout";
    }
    return "/delivery/checkout/register";
  };

  // On "Place order" checkout click
  const [isLoadingCheckout, setLoadingCheckout] = useState(false);
  const handleCheckoutClick = () => {
    setLoadingCheckout(true);

    // Redirect after showing "Placeing order..." for half a second
    setTimeout(() => {
      navigate(getLocalizedPath(getCheckoutPath()));

      // Wait one second for redirect to be done before remove loading state
      setTimeout(() => {
        setLoadingCheckout(false);
      }, 1000);
    }, 400);
  };

  // Get max quantity available for cart item
  const getItemMaxQuantity = (item: CartItemType): number => {
    return (
      availableProducts?.find(
        pv => pv.productVariantId === item.productVariantId
      )?.availableQuantity || 100
    );
  };

  return (
    <HeaderPanelBase
      open={isCartPanelOpen}
      onClose={() => toggleCartPanel(false)}
      anchorEl={anchorEl}
      data-testid="express-cart-panel"
      {...props}
    >
      <Box
        paddingY={2}
        minHeight={{
          xs: "calc(var(--100vh) - var(--header-height))",
          md: "400px"
        }}
        maxHeight={{
          xs: "calc(var(--100vh) - var(--header-height))",
          md: "min(820px, calc(var(--100vh) - var(--header-height) - 32px))"
        }}
        display="flex"
        flexDirection="column"
      >
        {/* Cart header */}
        <Box
          pb={2}
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
          marginX={{ xs: 2, md: 2.5 }}
          flexShrink={0}
        >
          <PanelTitle>{t("cart.yourCart")}</PanelTitle>
        </Box>

        {/* Cart items */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          paddingX={{ xs: 2, md: 2.5 }}
          pt={2}
          overflow="hidden auto"
          data-testid="cart.item"
        >
          <AnimatePresence initial={false}>
            {items.map(item => {
              const isItemAvailable = isCartItemAvailable(item);
              const itemMaxQuantity = getItemMaxQuantity(item);
              const showMaxQuantityWarning = item.quantity >= itemMaxQuantity;

              return (
                <motion.div
                  key={item.productVariantId}
                  initial={{ y: "-25%", opacity: 0 }}
                  animate={{
                    y: "0%",
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.15 }
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.15 }
                  }}
                  style={{
                    display: "flex",
                    marginBottom: theme.spacing(2)
                  }}
                >
                  <Box
                    width="80px"
                    height="80px"
                    borderRadius="3px"
                    overflow="hidden"
                    mr={2}
                    bgcolor="grey.200"
                    position="relative"
                  >
                    <Imgix
                      src={item.metadata?.image}
                      width={80}
                      height={80}
                      imgixParams={{
                        ar: "1",
                        fit: "crop",
                        auto: "format,compress"
                      }}
                    />
                    {!isItemAvailable && (
                      <Box
                        width="100%"
                        height="100%"
                        top={0}
                        left={0}
                        position="absolute"
                        bgcolor="common.white"
                        style={{ opacity: 0.7 }}
                      />
                    )}
                  </Box>

                  <Box flex={1} display="flex" flexDirection="column">
                    {/* Item Name + Price */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ProductItemTypography>
                        {item.metadata.name}
                      </ProductItemTypography>

                      {isItemAvailable && (
                        <ProductItemTypography>
                          {formatPrice({ amount: item.price })}
                        </ProductItemTypography>
                      )}

                      {!isItemAvailable && (
                        <Button
                          variant="text"
                          size="small"
                          style={{
                            paddingLeft: `${theme.spacing(1)}px`,
                            paddingRight: `${theme.spacing(1)}px`,
                            marginRight: `${theme.spacing(-1)}px`,
                            minHeight: `${theme.spacing(2)}px`
                          }}
                          onClick={() => removeItem(item.productVariantId)}
                        >
                          {`Remove (${item.quantity})`}
                        </Button>
                      )}
                    </Box>

                    {/* Item Brand */}
                    <ProductItemSecondaryTypography>
                      {t("cart.itemBy", { brandName: item.metadata.brand })}
                    </ProductItemSecondaryTypography>

                    {/* Item quantity selector */}
                    {isItemAvailable && (
                      <Box mt={1}>
                        <CartItemQuantitySelector
                          quantity={item.quantity}
                          onDesc={() =>
                            updateItemQuantity(
                              item.productVariantId,
                              item.quantity - 1
                            )
                          }
                          onIncr={() =>
                            updateItemQuantity(
                              item.productVariantId,
                              item.quantity + 1
                            )
                          }
                          maxQuantity={getItemMaxQuantity(item)}
                        />

                        {showMaxQuantityWarning && (
                          <Box mt={0.5}>
                            <Typography color="error" variant="caption">
                              {t("pdp.quantitySelector.maxReached.short", {
                                maxQuantity: itemMaxQuantity
                              })}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* Error: Item unavailable */}
                    {!isItemAvailable && availableProvider && (
                      <Typography variant="caption" color="error">
                        {t("cart.itemUnavailable")}
                      </Typography>
                    )}
                  </Box>
                </motion.div>
              );
            })}

            {/* Empty cart */}
            {cartError === "EMPTY_CART" && (
              <motion.div
                key="empty-cart"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.25 }
                }}
                exit={{ opacity: 0, transition: { duration: 0 } }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1
                }}
              >
                <img src={EMPTY_CART_LOGO} style={{ width: "120px" }} />
                <Box mt={2}>
                  <PanelTitle>{t("cart.empty")}</PanelTitle>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>

        {/* Add Battery suggestion */}
        <BatterySuggestion />

        {cartError !== "UNDER_MINIMUM_TOTAL" && (
          <Box marginY={{ xs: 2, md: 2.5 }} paddingX={{ xs: 2, md: 2.5 }}>
            {cartError === "EMPTY_CART" && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                component={LocalizedLink}
                to={"/shop/products"}
                onClick={() => toggleCartPanel(false)}
              >
                {t("browseProducts")}
              </Button>
            )}

            {cartError === "NO_DELIVERY_ADDRESS" && (
              <>
                <Box mb={2}>
                  <Alert severity="info">{t("cart.error.noAddress")}</Alert>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    toggleCartPanel(false);
                    toggleAvailabilityPanel(true);
                  }}
                >
                  {t("cart.addDeliveryAddress")}
                </Button>
              </>
            )}

            {cartError === "UNAVAILABLE_DELIVERY_ADDRESS" && (
              <>
                <Box mb={2}>
                  <Alert severity="info">
                    {t("cart.error.unavailableAddress")}
                  </Alert>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    toggleCartPanel(false);
                    toggleAvailabilityPanel(true);
                  }}
                >
                  {t("cart.changeDeliveryAddress")}
                </Button>
              </>
            )}

            {cartError === "OVER_THC_TOTAL_LIMIT" && (
              <Alert severity="error">
                {t("cart.error.maximumThc", {
                  grams: availableProvider.purchaseLimitConcentratedWeight
                })}
              </Alert>
            )}
          </Box>
        )}

        {/* Place order footer */}
        {(!cartError ||
          cartError === "UNDER_MINIMUM_TOTAL" ||
          cartError === "OVER_THC_TOTAL_LIMIT") && (
          <Box
            borderTop={`1px solid ${theme.palette.grey[300]}`}
            paddingY={1}
            paddingX={{ xs: 2, md: 2.5 }}
            flexShrink={0}
          >
            {/* Subtotal */}
            {!isCartEmpty && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" className="bold">
                    {t(
                      totalItems > 1 ? "cart.subtotal.plural" : "cart.subtotal",
                      {
                        totalItems
                      }
                    )}
                  </Typography>

                  <SubtotalPriceTypography data-testid="cart.subtotal">
                    {formatPrice({ amount: cartTotal })}
                  </SubtotalPriceTypography>
                </Box>

                {/* Fullfiled by */}
                <Box mt={1} mb={2}>
                  <Typography variant="caption" color="textSecondary">
                    {t("cart.providerText", {
                      providerName: availableProvider?.publicName
                    })}
                  </Typography>
                </Box>
              </>
            )}

            {cartError === "UNDER_MINIMUM_TOTAL" && (
              <Box mb={2}>
                <Alert severity="warning">
                  {t("cart.error.minimumTotal", {
                    price: availableProvider?.deliveryTotalMinimum
                  })}
                </Alert>
              </Box>
            )}

            {/* Place order button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                availableCartItemsCount === 0 ||
                isLoadingCheckout ||
                !!cartError
              }
              onClick={handleCheckoutClick}
            >
              {!isLoadingCheckout && t("cart.placeOrder")}
              {isLoadingCheckout && (
                <CircularProgress size={16} color="secondary" disableShrink />
              )}
            </Button>
          </Box>
        )}
      </Box>
    </HeaderPanelBase>
  );
}

export default React.memo(ExpressCartPanel);
