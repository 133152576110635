import React from "react";
import { useQueryParam, BooleanParam } from "use-query-params";
import { useLocalStorageState } from "../../helpers/localstorage";
import { isGoogleBot } from "../../helpers/platform-detect";
import useCountry from "../../hooks/use-country";
import useSubcountry from "../../hooks/use-subcountry";
import BasicModal from "../basic-modal";
import WelcomeModalCanadaForm from "./components/CanadaForm";
import WelcomeModalUsaForm from "./components/UsaForm";

export default function WelcomeModal() {
  const country = useCountry();
  const { subcountry, setSubcountry } = useSubcountry();

  const [disableModalQuery] = useQueryParam("disable-modal", BooleanParam);

  const [ageVerificationAccepted, setAgeVerificationAccepted] =
    useLocalStorageState(
      `airgraft-age-verification-accepted-${country}`,
      false
    );

  const handleAccept = (subcountry: string) => {
    if (subcountry) {
      setSubcountry(subcountry);
    }
    setAgeVerificationAccepted(true);
  };

  const handleRefuse = () => {
    window.location.reload();
  };

  // Dont show modal on pages without country page (Ex /404) or for Google Bots or if both age verification and subcountry was selected
  const isModalOpen =
    country &&
    !isGoogleBot() &&
    !disableModalQuery &&
    (!ageVerificationAccepted || !subcountry);

  return (
    <BasicModal open={isModalOpen} BoxProps={{ bgcolor: "primary.main" }}>
      <>
        {country === "US" && (
          <WelcomeModalUsaForm
            onAccept={handleAccept}
            onRefuse={handleRefuse}
          />
        )}
        {country === "CA" && <WelcomeModalCanadaForm onAccept={handleAccept} />}
      </>
    </BasicModal>
  );
}
