import { getToken } from "./user";

type Options = {
  headers?: HeadersInit;
  json?: any;
  body?: any;
};

async function request(
  method: string,
  url: string,
  options?: Options
): Promise<Response> {
  const headers = {
    "Accept-Language": "en",
    "Accept-Encoding": "gzip",
    ...(options?.headers || {})
  };

  if (options?.json || !(options?.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const token = getToken();
  if (token && !headers["Authorization"]) {
    headers[`Authorization`] = token;
  }

  const normalizedUrl = url.startsWith("/") ? url.substring(1) : url;

  return fetch(`${process.env.GATSBY_AIRGRAFT_API_URL}${normalizedUrl}`, {
    method,
    headers,
    body: options?.json ? JSON.stringify(options.json) : options?.body
  });
}

const api = {
  get: async (url: string, options?: Options) => request("get", url, options),
  post: async (url: string, options?: Options) => request("post", url, options),
  put: async (url: string, options?: Options) => request("put", url, options),
  delete: async (url: string, options?: Options) =>
    request("delete", url, options)
};

export default api;
