import { Box, NoSsr, useTheme } from "@material-ui/core";
import React, { useRef, useState } from "react";
import fastCompare from "react-fast-compare";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import useIsAuthenticated from "../../../../hooks/use-is-authenticated";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../hooks/use-translate";
import { getTargetHref } from "../../utils";
import MenuIcon from "./MenuIcon";
import {
  CustomMenuButton,
  CustomMenuH6,
  CustomMenuPopover,
  CustomUnderlineLink
} from "./styles";

type Props = {
  navigationItems: Queries.HeaderFragmentQuery["datoCmsGeneralHeader"]["_allNavigationLocales"][number]["value"];
};

function DesktopDropDownMenu({ navigationItems }: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();
  const buttonRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isAuthenticated = useIsAuthenticated();
  const logout = useAuthContextSelector(c => c.logout);

  // User logout
  const handleLogoutClick = () => {
    logout({
      redirectPath: getLocalizedPath("/")
    });
  };

  const handleItemClick = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <CustomMenuButton onClick={() => setIsDropdownOpen(true)} ref={buttonRef}>
        <MenuIcon />
      </CustomMenuButton>
      <CustomMenuPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        anchorEl={buttonRef?.current}
      >
        <Box
          paddingY={2.5}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {navigationItems.map(({ title, target }, index) => {
            const targetLocalPath = getTargetHref(target);
            return (
              <CustomUnderlineLink
                to={targetLocalPath}
                onClick={() => handleItemClick()}
                key={index}
              >
                <CustomMenuH6 variant="h6">{title}</CustomMenuH6>
              </CustomUnderlineLink>
            );
          })}
        </Box>
        <Box
          paddingY={2.5}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          borderTop={`1px solid ${theme.palette.grey[500]}`}
        >
          <NoSsr>
            {isAuthenticated ? (
              <>
                <CustomUnderlineLink
                  to={`/user/account`}
                  onClick={() => handleItemClick()}
                >
                  <CustomMenuH6 variant="h6">
                    {t("header.accountSettings")}
                  </CustomMenuH6>
                </CustomUnderlineLink>
                <CustomUnderlineLink
                  to="/"
                  onClick={() => {
                    handleItemClick();
                    handleLogoutClick();
                  }}
                >
                  <CustomMenuH6 variant="h6">
                    {t("header.signOut")}
                  </CustomMenuH6>
                </CustomUnderlineLink>
              </>
            ) : (
              <>
                <CustomUnderlineLink
                  to={`/sign-in`}
                  onClick={() => handleItemClick()}
                >
                  <CustomMenuH6 variant="h6">{t("header.signIn")}</CustomMenuH6>
                </CustomUnderlineLink>
                <CustomUnderlineLink
                  to={`/register`}
                  onClick={() => handleItemClick()}
                >
                  <CustomMenuH6 variant="h6">
                    {t("header.createAccount")}
                  </CustomMenuH6>
                </CustomUnderlineLink>
              </>
            )}
          </NoSsr>
        </Box>
      </CustomMenuPopover>
    </>
  );
}

export default React.memo(DesktopDropDownMenu, fastCompare);
