import { Box, CircularProgress, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import {
  fetchDeliveryAddressByGeolocation,
  getDeliveryAddressLongName
} from "../../../helpers/express-delivery/address";
import { useStoreLocatorAddressSelector } from "../../../hooks/use-store-locator-address";
import useTranslate from "../../../hooks/use-translate";
import { GoogleMapsAutocompletePlaceType } from "../../../types/airgraft";
import GeolocationIcon from "./GeolocationIcon";
import {
  CustomAutocompletePopper,
  CustomGeolocationButton,
  CustomGoogleMapsAutocompleteField
} from "./styles";

type Props = {
  onAddressChange: () => void;
};

export default function AddressAutoCompleteField({ onAddressChange }: Props) {
  const t = useTranslate();
  const address = useStoreLocatorAddressSelector(c => c.address);
  const setAddress = useStoreLocatorAddressSelector(c => c.setAddress);
  const setAddressByPlaceId = useStoreLocatorAddressSelector(
    c => c.setAddressByPlaceId
  );

  // Manually update "address" form value on autocomplete change
  const handleAddressAutocompleteChange = async (
    event,
    place: GoogleMapsAutocompletePlaceType
  ) => {
    setAddressByPlaceId(place ? place.place_id : null, {
      saveToLocalStorage: true,
      saveToAnalytics: true
    });

    if (place?.place_id && place?.place_id !== (address && address.placeId)) {
      onAddressChange();
    }
  };

  /**
   * Allow user to prefill address using browser geolocation
   */
  const [isGeolocationLoading, setGeolocationLoading] = useState(false);

  /**
   * Request access to geolocation, and trigger callbacls
   */
  const requestBrowserGeolocation = () => {
    if (isGeolocationLoading) {
      return;
    }

    if (!navigator?.geolocation?.getCurrentPosition) {
      onBrowserGeolocationError(false);
      return;
    }

    setGeolocationLoading(true);

    navigator.geolocation.getCurrentPosition(
      onBrowserGeolocationSuccess,
      () => onBrowserGeolocationError(true),
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 60000 }
    );
  };
  /**
   * After user allow geolocation, fetch delivery address by lat,lng
   */
  const onBrowserGeolocationSuccess = async (position: GeolocationPosition) => {
    const geoLocatedAddress = await fetchDeliveryAddressByGeolocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    });

    if (geoLocatedAddress) {
      setAddress(geoLocatedAddress, {
        saveToLocalStorage: true,
        saveToAnalytics: true
      });

      if (
        geoLocatedAddress?.placeId &&
        geoLocatedAddress?.placeId !== (address && address.placeId)
      ) {
        onAddressChange();
      }
    }

    setGeolocationLoading(false);
  };

  /**
   * Browser doesnt have geolocation or user declined the access
   */
  const onBrowserGeolocationError = (browserHasGeolocation: boolean) => {
    setGeolocationLoading(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ filter: "drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.05))" }}
    >
      <CustomGoogleMapsAutocompleteField
        key={address?.placeId}
        onChange={handleAddressAutocompleteChange}
        defaultValue={
          address
            ? {
                place_id: address.placeId,
                description: getDeliveryAddressLongName(address)
              }
            : null
        }
        placeholder={t("storelocator.addressPanel.enterAddress")}
        TextFieldProps={{
          variant: "standard",
          InputLabelProps: { shrink: true }
        }}
        PopperComponent={CustomAutocompletePopper}
        noOptionsText={t("expressDelivery.enterAddressDescription")}
      />
      <Tooltip
        title={
          isGeolocationLoading
            ? t("expressDelivery.loadingLocation")
            : t("expressDelivery.useMyLocation")
        }
        placement="top"
        arrow
        disableTouchListener
      >
        <CustomGeolocationButton
          onClick={requestBrowserGeolocation}
          disableRipple
          data-analytics="address-panel.use-my-location"
        >
          {!isGeolocationLoading && <GeolocationIcon />}
          {isGeolocationLoading && <CircularProgress size={20} disableShrink />}
        </CustomGeolocationButton>
      </Tooltip>
    </Box>
  );
}
