import { Box, styled, Typography } from "@material-ui/core";
import React from "react";
import useTranslate from "../../../hooks/use-translate";
import StoreLocatorLogoSvg from "./logo.svg";

export const CustomButtonTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  lineHeight: 1,
  fontFamily: "Roobert, Arial",
  fontWeight: 600,
  marginLeft: theme.spacing(1),
  whiteSpace: "nowrap",
  [theme.breakpoints.down("xs")]: {
    marginLeft: theme.spacing(0.5)
  },
  "@media (max-width: 340px)": {
    display: "none"
  }
}));

export default function StoreLocatorLogo() {
  const t = useTranslate();
  return (
    <Box display="flex" alignItems="center">
      <img src={StoreLocatorLogoSvg} width={24} height={24} />
      <CustomButtonTypography>
        {t("storelocator.header.title")}
      </CustomButtonTypography>
    </Box>
  );
}
