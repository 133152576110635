import React from "react";

function MenuIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4002 13.3004C3.84791 13.3004 3.4002 13.7481 3.4002 14.3004C3.4002 14.8527 3.84791 15.3004 4.4002 15.3004L17.6002 15.3004C18.1525 15.3004 18.6002 14.8527 18.6002 14.3004C18.6002 13.7481 18.1525 13.3004 17.6002 13.3004L4.4002 13.3004ZM4.40019 6.70039C3.84791 6.70039 3.40019 7.14811 3.40019 7.70039C3.40019 8.25268 3.84791 8.70039 4.40019 8.70039L17.6002 8.70039C18.1525 8.70039 18.6002 8.25268 18.6002 7.70039C18.6002 7.14811 18.1525 6.70039 17.6002 6.70039L4.40019 6.70039Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}

export default React.memo(MenuIcon);
