import React from "react";
import { ThemeProvider as MaterialUiThemeProvider } from "@material-ui/core/styles";
import MaterialUiCssBaseline from "@material-ui/core/CssBaseline";
import materialUiTheme from "../../src/theme/material-ui-theme";
import { SnackbarProps } from "@material-ui/core";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import CustomSnackbar from "../../src/components/custom-snackbar";

const snackbarProviderProps: Partial<SnackbarProps> = {
  autoHideDuration: 4000,
  anchorOrigin: { vertical: "bottom", horizontal: "center" }
};

/**
 * Material UI theme provider need to be injected before gatsby-plugin-material-ui using a custom plugin to work properly with SSR.
 * See https://galenwong.github.io/blog/2020-02-27-mui-jss-rendering/
 */
export default function ThemeProvider({ children }: { children: JSX.Element }) {
  return (
    <MaterialUiThemeProvider theme={materialUiTheme}>
      <MaterialUiCssBaseline />
      <SnackbarProvider
        SnackbarProps={snackbarProviderProps}
        SnackbarComponent={CustomSnackbar as any}
      >
        {children}
      </SnackbarProvider>
    </MaterialUiThemeProvider>
  );
}
