import React from "react";
import Header, { HeaderVariantType } from "../../containers/header";
import Footer from "../footer";
import { TranslationsProvider } from "../../providers/translations";
import ErrorBoundary from "../error-boundary";
import { LocaleType } from "../../types/airgraft";
import WelcomeModal from "../welcome-modal";
import XpressLegalModal from "../../containers/shop/XpressLegalModal";
import { PageProps } from "gatsby";
import { SubcountryProvider } from "../../providers/subcountry";
import MobileCountryMenu from "../../containers/header/Mobile/MobileCountryMenu";
import MobileLanguageMenu from "../../containers/header/Mobile/MobileLanguageMenu";
import { NotificationProvider } from "../../providers/notification";

export type LayoutPageContextProps = {
  locale: LocaleType;
  headerVariant: string;
};

export type LayoutPageProps = PageProps<unknown, LayoutPageContextProps>;

export default function Layout({ children, pageContext }: LayoutPageProps) {
  return (
    <TranslationsProvider locale={pageContext.locale}>
      <ErrorBoundary>
        <SubcountryProvider>
          <NotificationProvider>
            <Header variant={pageContext.headerVariant as HeaderVariantType} />
          </NotificationProvider>
          {children}
          <Footer />

          {/* Global modals */}
          <XpressLegalModal />
          <WelcomeModal />

          {/* Mobile menus */}
          <MobileCountryMenu />
          <MobileLanguageMenu />
        </SubcountryProvider>
      </ErrorBoundary>
    </TranslationsProvider>
  );
}
