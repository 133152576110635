import moment from "moment-timezone";
import {
  DeliveryProviderType,
  DeliveryProviderOpenCloseDayTimeType,
  DeliveryProviderOpenCloseTimesType,
  DeliveryOrPickupType,
  DeliveryProviderProductData
} from "../../types/airgraft-express";
import { NETLIFY_FUNCTIONS_BASE_URL } from "../../services/netlify-functions";

/**
 * Check if a Google place is inside the delivery area of a provider
 */
export async function fetchAvailableProviders(
  googlePlaceId: string
): Promise<DeliveryProviderType[]> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-available-providers?placeId=${googlePlaceId}&orderType=delivery`
  );
  if (response.ok) {
    const providers = (await response.json()) as DeliveryProviderType[];
    return providers;
  }
  if (response.status === 400) {
    return [];
  }
  throw new Error("Could not fetch available providers for this address");
}

/**
 * Get list of available products for a provider
 */
export async function fetchProviderAvailableProductsData(
  providerId: string
): Promise<DeliveryProviderProductData[]> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-provider-products?providerId=${providerId}`
  );
  if (response.ok) {
    const products: DeliveryProviderProductData[] = await response.json();
    return products;
  }
  throw new Error("Could not fetch available products for this provider");
}

/**
 * Get provider open/close time schedule based on orderType
 */
export const getProviderOpenCloseTimes = (
  provider: DeliveryProviderType,
  orderType: DeliveryOrPickupType
): DeliveryProviderOpenCloseTimesType => {
  return orderType === "pickup" ? provider.pickupTimes : provider.deliveryTimes;
};

/**
 * Check if provider has at least one open weekday of delivery
 */
export const isProviderConfiguredWithOpenCloseTimes = (
  provider: DeliveryProviderType,
  orderType: DeliveryOrPickupType
): boolean => {
  const openCloseTimes = getProviderOpenCloseTimes(provider, orderType);

  if (!openCloseTimes) {
    return false;
  }
  const isOpenAtLeastOneDay = Object.keys(openCloseTimes).find(
    weekday =>
      !!openCloseTimes[weekday] &&
      !!openCloseTimes[weekday].open &&
      !!openCloseTimes[weekday].close
  );
  return !!isOpenAtLeastOneDay;
};

/**
 * Get provider local timezone
 */
export function getProviderLocalTimezone(
  provider: DeliveryProviderType
): string {
  return provider.timezone || "America/Los_Angeles";
}

/**
 * Get local datetime for a provider. Use provider.timezone to determine local time.
 */
export function getProviderLocalDatetime(provider: DeliveryProviderType): {
  now: moment.Moment;
  timezone: string;
} {
  const timezone = getProviderLocalTimezone(provider);
  return {
    now: moment().tz(timezone),
    timezone
  };
}

/**
 * Internal helper to get next available delivery day open datetime for a provider.
 */
function getProviderNextAvailableDeliveryDatetime(
  provider: DeliveryProviderType,
  orderType: DeliveryOrPickupType,
  skipDays = 0 // How many available days to skip? Used to get the second available day inside delivery time dropdown
): moment.Moment {
  const { now, timezone } = getProviderLocalDatetime(provider);
  const providerOpenCloseTimes = getProviderOpenCloseTimes(provider, orderType);

  // Loop over delivery times to get next available opened day
  let nextAvailableWeekday = null; // Next opened weekday (Ex "mon"). Result of this loop
  let cursorWeekdayIndex = now.weekday() === 6 ? 0 : now.weekday(); // Index cursor; Weekday index
  let accumulatorDays = 0; // Accumulator; Number of days from today
  let availableDaysSkipped = 0;
  while (nextAvailableWeekday === null) {
    const weekday = moment.weekdaysShort(cursorWeekdayIndex).toLowerCase();
    const close = providerOpenCloseTimes[weekday]
      ? moment
          .tz(providerOpenCloseTimes[weekday].close, "HH:mm", timezone)
          .add(accumulatorDays, "days")
      : null;
    if (providerOpenCloseTimes[weekday] && close && !now.isSameOrAfter(close)) {
      if (availableDaysSkipped >= skipDays) {
        // End loop: we found the next available day
        nextAvailableWeekday = weekday;
        continue;
      } else {
        // We found a available day, but skip this one for now
        availableDaysSkipped++;
      }
    }
    cursorWeekdayIndex = cursorWeekdayIndex === 6 ? 0 : cursorWeekdayIndex + 1;
    accumulatorDays++;
  }

  // Find open local datetime the next opened day
  const nextOpenWeekdayConfig = providerOpenCloseTimes[
    nextAvailableWeekday
  ] as DeliveryProviderOpenCloseDayTimeType;
  const [hours, minutes] = nextOpenWeekdayConfig.open.split(":");
  const nextOpenDatetime = now
    .add(accumulatorDays, "days")
    .set({ hours: parseInt(hours), minutes: parseInt(minutes), seconds: 0 });

  return nextOpenDatetime;
}

export type DeliveryTimeframeType = {
  label: string;
  value: string;
};

/**
 * Calculate available delivery timeframes based on provider.deliveryTimes, provider.timezone, provider.leadtime & provider.timeframe
 */
export function getProviderAvailableDeliveryTimeframes(
  provider: DeliveryProviderType
): DeliveryTimeframeType[] {
  if (!isProviderConfiguredWithOpenCloseTimes(provider, "delivery")) {
    return [];
  }

  const timeframes = [];
  const { now } = getProviderLocalDatetime(provider);

  const providerOpenCloseTimes = getProviderOpenCloseTimes(
    provider,
    "delivery"
  );

  // Offer user delivery options for the next allowed available days (Ex today & tomorow)
  for (
    let offset = 0;
    offset < (provider.deliveryAllowedDaysInAdvance || 1);
    offset++
  ) {
    const nextOpenDatetime = getProviderNextAvailableDeliveryDatetime(
      provider,
      "delivery",
      offset
    );
    const weekday = moment
      .weekdaysShort(nextOpenDatetime.weekday())
      .toLowerCase();
    const weekdayConfig = providerOpenCloseTimes[weekday];
    const leadtime = weekdayConfig.leadtime || 0;
    const timeframe = weekdayConfig.timeframe || 60;

    const isToday = nextOpenDatetime.isSame(now, "d");
    const isTomorow = nextOpenDatetime.isSame(now.clone().add(1, "days"), "d");
    const [closingHours, closingMinutes] = weekdayConfig.close.split(":");
    const closingDatetime = nextOpenDatetime
      .clone()
      .set({ hours: closingHours, minutes: closingMinutes });

    // Minimum datetime user can order in advance. Based on deliveryMinimumDaysInAdvance and current weekday leadtime
    const minimumDaysInAdvance = provider.deliveryMinimumDaysInAdvance || 0;
    const minimumCutoffDatetime =
      minimumDaysInAdvance > 0
        ? now.clone().add(minimumDaysInAdvance, "days").startOf("day")
        : now.clone().add(leadtime, "minutes");

    // Loop over or the rest of that delivery day and generate time frames
    const cursorDatetime = nextOpenDatetime.clone();

    // prettier-ignore
    while (cursorDatetime.isSameOrBefore(closingDatetime.clone().add(-timeframe, "minutes"))) {
      if (cursorDatetime.isAfter(minimumCutoffDatetime)) {
        // prettier-ignore
        let label = `${cursorDatetime.format("h:mm a")} - ${cursorDatetime.clone().add(timeframe, "minutes").format("h:mm a")}`;

        if (isToday) {
          label = `Today: ${label}`;
        } else if (isTomorow) {
          label = `Tomorow: ${label}`;
        } else {
          // prettier-ignore
          label = `${cursorDatetime.format("dddd")} ${cursorDatetime.format("MMM")} ${cursorDatetime.format("Do")}: ${label}`;
        }

        timeframes.push({
          value: cursorDatetime.clone().set("seconds", 0).format(),
          label
        });
      }
      cursorDatetime.add(timeframe, "minutes");
    }
  }

  return timeframes;
}

export function getProviderDeliveryOrPickupTimeframes(
  provider: DeliveryProviderType,
  orderType: DeliveryOrPickupType
): DeliveryTimeframeType[] {
  // Note: getProviderAvailablePickupTimeframes was removed to simplify the code
  return getProviderAvailableDeliveryTimeframes(provider);
}
