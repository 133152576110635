import React from "react";

const CanadaFlagIcon = ({ size }: { size?: number }) => (
  <svg
    width={size || 31}
    height={size || 31}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ borderRadius: `${size || 31}px`, overflow: "hidden" }}
  >
    <path
      d="M23.2777 2.36404C21.0416 0.954319 18.368 0.12793 15.4999 0.12793C12.6319 0.12793 9.95828 0.954319 7.72217 2.36404V27.0099C9.95828 28.4196 12.6319 29.246 15.4999 29.246C18.368 29.246 21.0416 28.4196 23.2777 27.0099V2.36404Z"
      fill="#F9F9F9"
    />
    <path
      d="M23.2778 2.36377V27.0096C27.3612 24.4818 30.0834 19.8152 30.0834 14.6624C30.0834 9.5096 27.3612 4.94016 23.2778 2.36377Z"
      fill="#D90026"
    />
    <path
      d="M7.7223 2.36377C3.63897 4.94016 0.916748 9.60682 0.916748 14.711C0.916748 19.8152 3.63897 24.4818 7.7223 27.0582V2.36377Z"
      fill="#D90026"
    />
    <path
      d="M20.7987 14.5166C20.6042 14.4194 20.5556 14.2249 20.6042 14.1277L21.0904 12.3777L19.389 12.718C19.3404 12.718 19.1459 12.718 19.0973 12.3777L18.9515 11.7944L17.7848 13.1555C17.7848 13.1555 17.007 13.9819 17.2501 12.718L17.7362 10.0444L16.8126 10.5305C16.764 10.5305 16.5695 10.5791 16.3265 10.093L15.5001 8.3916L14.6251 9.99577C14.382 10.4819 14.1876 10.4333 14.139 10.4333L13.2154 9.94716L13.7015 12.6208C13.9445 13.8847 13.1667 13.0583 13.1667 13.0583L12.0001 11.6972L11.8542 12.2805C11.757 12.6208 11.6112 12.6208 11.5626 12.6208L9.86119 12.2805L10.3473 14.0305C10.3473 14.1763 10.3473 14.3222 10.1529 14.4194L9.66675 14.711C9.66675 14.711 11.6112 16.2666 12.2431 16.8013C12.389 16.8985 12.6806 17.1902 12.5834 17.5305L12.3404 18.211L15.014 17.8222C15.1598 17.8222 15.4515 17.8222 15.4029 18.2597L15.257 21.0305H15.7431L15.5973 18.2597C15.5973 17.8222 15.889 17.8222 15.9862 17.8222L18.6598 18.211L18.4167 17.5305C18.3195 17.1902 18.6112 16.8985 18.757 16.8013C19.389 16.2666 21.3334 14.711 21.3334 14.711L20.7987 14.5166Z"
      fill="#D90026"
    />
  </svg>
);

export default CanadaFlagIcon;
