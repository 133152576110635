import { Backdrop, Popover, styled, Typography } from "@material-ui/core";
import ModalCloseButton from "../../../components/basic-modal/ModalCloseButton";

export const CustomPopover = styled(Popover)(
  // @ts-ignore
  ({ theme }) => ({
    "& > .MuiPaper-root": {
      backgroundColor: theme.palette.common.white
    },
    // Desktop: Override Popver > Paper styles
    [theme.breakpoints.up("md")]: {
      "& > .MuiPaper-root": {
        overflow: "hidden",
        width: "420px",
        borderRadius: "16px",
        transform: `translate(0px, 25px)`,
        boxShadow: "6px 3px 10px rgba(0, 0, 0, 0.14)"
      }
    },
    // Mobile: Override Popver > Paper styles to look like a regular modal
    [theme.breakpoints.down("sm")]: {
      top: "var(--header-height) !important",
      zIndex: `${theme.zIndex.modal + 2} !important`,
      "& > .MuiPaper-root": {
        width: "100%",
        maxWidth: "100%",
        maxHeight: "calc(var(--100vh) - var(--header-height))", // Max full vertical viewport height
        position: "fixed",
        top: "var(--header-height) !important",
        left: "0 !important",
        transformOrigin: "0 !important",
        borderRadius: "0px",
        boxShadow: "none"
      },
      "& .MuiBackdrop-invisible": {
        background: "rgba(0, 0, 0, 0.5)" // Popover backdrop is invisible by default. Make it visible on mobile
      }
    }
  })
);

export const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    background: `transparent!important`,
    "&:after": {
      content: "' '",
      display: "block",
      position: "absolute",
      top: "calc(var(--header-height))", // Move backdrop under the mobile Header
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `rgba(0, 0, 0, 0.5)`
    }
  }
}));

export const CustomModalCloseButton = styled(ModalCloseButton)(({ theme }) => ({
  position: `absolute`,
  top: theme.spacing(2),
  right: theme.spacing(2)
}));

export const PanelTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2.4rem",
  lineHeight: 1.2,
  fontWeight: 400,
  fontFamily: "Reckless, Georgia"
}));
