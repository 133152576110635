import { Button, styled } from "@material-ui/core";

export const NotificationButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  "&:hover": {
    background: theme.palette.grey[300]
  },
  border: `${theme.palette.grey[300]} 1px solid`,
  borderRadius: "24px",
  transition: "background 200ms ease-in-out, border 200ms ease-in-out",
  width: "40px",
  minWidth: "0px",
  height: "32px",
  minHeight: "0px",
  padding: 0,
  marginRight: theme.spacing(1.5),
  [theme.breakpoints.up("md")]: {
    height: "36px"
  },
  "& .MuiButton-label": {
    height: "100%"
  },
  "& .MuiBadge-root": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > .MuiBadge-badge": {
      background: theme.palette.primary.main,
      color: theme.palette.text.primary
    },
    "& > .MuiBadge-anchorOriginTopRightRectangle:not(.MuiBadge-invisible)": {
      transform: "scale(1) translate(25%, -25%)"
    },
    "& svg": {
      width: "20px",
      height: "20px"
    }
  },
  // Hide super small screen
  "@media (max-width: 300px)": {
    display: "none"
  }
}));
