import { Box, Container, Hidden, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FooterData } from ".";
import useIsAuthenticated from "../../hooks/use-is-authenticated";
import useTranslate from "../../hooks/use-translate";
import { getTargetHref } from "../../containers/header/utils";
import Link from "../localized-link";
import MonogramLogo from "./assets/monogram.svg";
import MobileCountryMenuButton from "../../containers/header/Mobile/MobileCountryMenu/CountryMenuButton";
import MobileLanguageMenuButton from "../../containers/header/Mobile/MobileLanguageMenu/LanguageMenuButton";
import useCountry from "../../hooks/use-country";
import useSubcountry from "../../hooks/use-subcountry";

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  primaryFooter: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 0 auto`,
    justifyContent: "center",
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(6, 8),
    [theme.breakpoints.down(`md`)]: {
      padding: theme.spacing(4.5, 5)
    },
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(4.5, 3)
    }
  },
  breadcrumbs: {
    display: `flex`,
    flexDirection: `row`,
    flex: `1 0 auto`,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(`sm`)]: {
      marginBottom: theme.spacing(0)
    }
  },
  breadcrumbCurrent: {
    textTransform: "capitalize",
    ...theme.typography.body2
  },
  breadcrumbLink: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    ...theme.typography.body2
  },
  breadcrumbSeparator: {
    margin: theme.spacing(1.5),
    color: theme.palette.text.primary
  },
  primaryContent: {
    display: "grid",
    gridTemplateRows: `min-content`,
    gridTemplateColumns: `${theme.spacing(25)}px ${theme.spacing(
      22
    )}px  1fr auto`,
    gridTemplateAreas: `"monogram mainLinks additionalLinks localeSelect"`,
    gridRowGap: theme.spacing(4),
    margin: theme.spacing(7, 0, 6),
    [theme.breakpoints.down(`md`)]: {
      marginBottom: 2,
      gridTemplateRows: `min-content auto max-content`,
      gridTemplateColumns: `min-content 1fr`,
      gridTemplateAreas: `
      "monogram monogram"
      "mainLinks additionalLinks"
      "localeSelect localeSelect"
      `,
      gridRowGap: theme.spacing(8),
      gridColumnGap: theme.spacing(2)
    },
    [theme.breakpoints.down(`sm`)]: {
      marginBottom: 1,
      gridTemplateRows: `min-content auto max-content`,
      gridTemplateColumns: `min-content 1fr`
    },
    [theme.breakpoints.down(`xs`)]: {
      marginTop: theme.spacing(4),
      marginBottom: 0,
      gridTemplateRows: `min-content min-content auto max-content`,
      gridTemplateColumns: `1fr`,
      gridTemplateAreas: `
      "monogram"
      "mainLinks"
      "additionalLinks"
      "localeSelect"
      `
    }
  },
  primaryMonogram: {
    gridArea: "monogram",
    display: `flex`,
    flexDirection: `row`,
    flex: `0 0 auto`,
    marginTop: theme.spacing(1)
  },
  monogramImage: {
    height: theme.spacing(5),
    width: theme.spacing(5)
  },
  mainLinks: {
    gridArea: "mainLinks",
    display: "grid",
    gridAutoRows: theme.spacing(3),
    gridAutoFlow: `row`,
    gridTemplateColumns: `${theme.spacing(25)}px`,
    gridRowGap: theme.spacing(2),
    justifySelf: `start`,
    alignItems: `start`
  },
  additionalLinks: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    justifyContent: "center",
    marginLeft: `10%`,
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down(`md`)]: {
      margin: theme.spacing(0, 2)
    },
    [theme.breakpoints.down(`sm`)]: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
      margin: theme.spacing(0, 0.5)
    }
  },
  additionalLinkList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "0 1 auto",
    margin: theme.spacing(0, 2, 2),
    "& $link": {
      margin: theme.spacing(1, 0),
      "&:first-child": {
        marginTop: 0
      },
      "&:last-child": {
        marginBottom: 0
      }
    },
    [theme.breakpoints.down(`md`)]: {
      flex: "1 1 auto",
      margin: theme.spacing(0, 1, 2)
    },
    [theme.breakpoints.down(`sm`)]: {
      alignItems: "flex-start",
      margin: theme.spacing(0, 0, 2),
      flexBasis: "50%"
    }
  },
  localeSelect: {
    gridArea: "localeSelect",
    display: `flex`,
    flexDirection: `row`,
    flex: `0 0 auto`,
    placeSelf: `start end`,
    [theme.breakpoints.down(`md`)]: {
      placeSelf: `start start`
    },
    [theme.breakpoints.down(`sm`)]: {
      marginTop: theme.spacing(3)
    }
  },
  link: {
    display: `flex`,
    flexDirection: `row`,
    flex: `0 0 auto`,
    alignItems: `center`,
    "&:hover": {
      textDecoration: "none",
      "& span": {
        "&:after": {
          width: "100%",
          opacity: 1
        }
      }
    },
    "& span": {
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: theme.spacing(0),
        width: 0,
        height: theme.spacing(0.125),
        margin: theme.spacing(0.125, 0, 0),
        transition: "all 0.2 ease-in-out",
        transitionDuration: "0.3s",
        opacity: 0,
        backgroundColor: theme.palette.common.black
      }
    }
  },
  activeLink: {
    pointerEvents: "unset !important"
  },
  main: {
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    fontFamily: "Reckless, Georgia",
    letterSpacing: "0px",
    fontWeight: "normal"
  },
  additional: {
    fontSize: "1.2rem",
    lineHeight: "2.4rem",
    fontFamily: "Roobert, Arial",
    letterSpacing: "1.5px",
    fontWeight: "normal",
    textTransform: "uppercase"
  },
  secondaryFooter: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 0 auto`,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(3, 8, 2),
    [theme.breakpoints.down(`md`)]: {
      padding: theme.spacing(4.5, 5)
    },
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(4.5, 3)
    }
  },
  secondaryFooterText: {
    lineHeight: "1.6rem",
    fontSize: "1.1rem",
    letterSpacing: "0",
    textAlign: "center",
    color: "rgba(172, 169, 163, 0.8)",
    fontWeight: 400,
    "& a": {
      fontWeight: "inherit",
      fontSize: "inherit",
      letterSpacing: "inherit",
      color: `rgba(172, 169, 163, 0.8) !important`
    }
  },
  secondaryFooterTextFirstLine: {
    [theme.breakpoints.down(`sm`)]: {
      width: theme.spacing(30)
    }
  },
  localeLink: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(0.75),
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.common.black}`,
      textDecoration: "none"
    },
    "&> span": {
      fontSize: "1.2rem",
      lineHeight: "2.4rem",
      letterSpacing: "1.5px",
      textTransform: "uppercase"
    },
    "&> svg": {
      position: "absolute",
      right: 0
    }
  }
}));

type Props = {
  data: FooterData;
};

const Footer = ({ data }: Props) => {
  const classes = useStyles();
  const t = useTranslate();
  const country = useCountry();
  const { subcountry } = useSubcountry();

  const isAuthenticated = useIsAuthenticated();
  const [authVisibleFilter, setAuthVisibleFilter] = useState([
    "unauthenticated",
    "any"
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      setAuthVisibleFilter(["authenticated", "any"]);
    } else {
      setAuthVisibleFilter(["unauthenticated", "any"]);
    }
  }, [isAuthenticated]);

  const renderMainLinks = () => {
    return data.mainLinks.map(
      ({ originalId, title, target, visibility, authenticationVisibility }) => {
        if (
          authenticationVisibility &&
          authVisibleFilter.length > 0 &&
          !authVisibleFilter.includes(authenticationVisibility)
        ) {
          return null;
        }
        const link = (
          <Link
            key={`footer-main-link-${originalId}`}
            className={clsx(classes.link)}
            to={getTargetHref(target)}
          >
            <span className={classes.main}>{title}</span>
          </Link>
        );
        if (visibility) {
          return (
            <Hidden
              key={`footer-main-link-${originalId}`}
              implementation="css"
              smDown={!!visibility}
              mdUp={visibility === "mobile only"}
            >
              {link}
            </Hidden>
          );
        } else {
          return link;
        }
      }
    );
  };

  const renderFooterLinks = () => {
    return data.additionalLinks.map(({ links }, index) => (
      <div
        key={`additionalLinkList-${index}`}
        className={classes.additionalLinkList}
      >
        {links.map(({ originalId, title, hyperlink }) => {
          return (
            <Link
              key={`footer-additional-link-${originalId}`}
              className={clsx(classes.link)}
              to={getTargetHref({ path: hyperlink })}
            >
              <span className={classes.additional}>{title}</span>
            </Link>
          );
        })}
      </div>
    ));
  };

  return (
    <Box
      id="footer"
      display="flex"
      flex="0 0 auto"
      flexDirection="column"
      zIndex={95}
    >
      <div className={classes.primaryFooter}>
        <Container maxWidth="xl">
          <div className={classes.primaryContent}>
            <div className={classes.primaryMonogram}>
              <img
                alt="airgraft-monogram"
                src={MonogramLogo}
                className={classes.monogramImage}
              />
            </div>
            {data.mainLinks?.length > 0 && (
              <div className={classes.mainLinks}>{renderMainLinks()}</div>
            )}
            <div className={classes.additionalLinks}>{renderFooterLinks()}</div>
          </div>
        </Container>

        <Container maxWidth="xl">
          <Box mt={0} display={{ xs: "flex", md: "none" }} alignItems="center">
            <MobileCountryMenuButton />
            <MobileLanguageMenuButton />
          </Box>
        </Container>
      </div>
      <div className={classes.secondaryFooter}>
        {/* Washington legal warning */}
        {country === "US" && subcountry === "WA" && (
          <Box mb={2}>
            <Container
              maxWidth="md"
              style={{ textAlign: "center" }}
              disableGutters
            >
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.secondaryFooterText}
              >
                {t("footer.washingtonWarning")}
              </Typography>
            </Container>
          </Box>
        )}

        {/* Shared legal/trademark footer */}
        <Typography
          variant="caption"
          color="textSecondary"
          className={clsx(
            classes.secondaryFooterText,
            classes.secondaryFooterTextFirstLine
          )}
        >
          {`${t(`footer.trademark1`, {
            year: new Date().getFullYear()
          })}
          ${t(`footer.trademark2`)}`}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          gutterBottom
          className={classes.secondaryFooterText}
        >
          {`${t(`footer.tos1`)} ${t(`footer.tos2`)}`}
        </Typography>

        {/* California legal warning */}
        {country === "US" && subcountry === "CA" && (
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.secondaryFooterText}
          >
            {t("footer.california65Warning")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.p65warnings.ca.gov/"
            >
              P65WARNINGS.CA.GOV
            </a>
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default Footer;
