import api from "../services/api";
import { Paginated } from "../types/airgraft";
import { useQuery } from "react-query";
import { UserPodRatingType } from "../containers/rating/utils";

export type PodHistoryType = {
  createdDate: string;
  podKey: string;
  batchId: string;
  userRating?: UserPodRatingType;
};

type PodHistoryPaginationOptions = {
  pageNumber?: number;
  size?: number;
  startDate?: string;
  endDate?: string;
};

export async function getActivatedPodHistory({
  pageNumber = 0,
  startDate,
  endDate,
  size = 100
}: PodHistoryPaginationOptions): Promise<Paginated<PodHistoryType>> {
  const response = await api.get(
    `/pas/user-activation/history?sort=createdDate,desc&page=${pageNumber}&size=${size}` +
      (startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ``)
  );

  if (response.ok) {
    const json = await response.json();
    return json as Paginated<PodHistoryType>;
  } else if (response.status === 404) {
    return null;
  }

  throw new Error("Could not fetch pod activated history");
}

export default function useActivatedPodHistory(options?: {
  enabled?: boolean;
}) {
  return useQuery(
    "user-activated-pod-history",
    () => getActivatedPodHistory({}),
    {
      staleTime: 1000 * 60, // 60 Seconds
      enabled: options?.enabled === undefined || options?.enabled
    }
  );
}
