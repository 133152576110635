import { styled, Typography } from "@material-ui/core";
import HeaderPanelBase from "../PanelBase";

export const CustomPanelBase = styled(HeaderPanelBase)(({ theme }) => ({
  "& .MuiPaper-root": {
    // backgroundColor: `${theme.palette.grey[100]} !important`
  },
  "& .panel-arrow": {
    // borderColor: `transparent transparent ${theme.palette.grey[100]}`
  }
}));
