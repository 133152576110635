import { Box, PopoverProps, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useExpressDeliveryAvailabilitySelector } from "../../../hooks/use-express-delivery-availability";
import useTranslate from "../../../hooks/use-translate";
import HeaderPanelBase from "../PanelBase";
import { PanelTitle } from "../PanelBase/styles";
import AddressAutoCompleteField from "./AddressAutoCompleteField";
import AddressConfirmButton from "./AddressConfirmButton";
import AddressStatusMessage from "./AddressStatusMessage";

type Props = Pick<
  PopoverProps,
  "anchorEl" | "transformOrigin" | "anchorOrigin"
>;

export default function ExpressDeliveryAvailabilityPanel(popoverProps: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const isAddressPanelOpen = useExpressDeliveryAvailabilitySelector(
    c => c.isAddressPanelOpen
  );
  const toggleAvailabilityPanel = useExpressDeliveryAvailabilitySelector(
    c => c.toggleAvailabilityPanel
  );

  const [showConfirmButton, setShowConfirmButton] = useState(false);

  return (
    <HeaderPanelBase
      open={isAddressPanelOpen}
      onClose={() => toggleAvailabilityPanel(false)}
      data-testid="express-availability-panel"
      {...popoverProps}
    >
      <Box px={{ xs: 2, md: 3 }} pt={{ xs: 2, md: 3 }} pb={{ xs: 4, md: 4 }}>
        {/* Main panel title */}
        <Box mb={2} pr={{ xs: 6, md: 7 }}>
          <PanelTitle>{t("expressDelivery.enterDeliveryAddress")}</PanelTitle>
        </Box>

        {/* Custom address autocomplete field */}
        <AddressAutoCompleteField
          onAddressChange={() => setShowConfirmButton(true)}
        />

        {/* Tips for address request status */}
        <Box marginTop={2}>
          <AddressStatusMessage />
        </Box>

        {/* Confirm and close panel button */}
        {showConfirmButton && (
          <AddressConfirmButton
            onClick={() => {
              setShowConfirmButton(false);
              toggleAvailabilityPanel(false);
            }}
          />
        )}
      </Box>

      {/* Express Delivery bottom panel border */}
      <Box
        position="absolute"
        left={0}
        bottom={0}
        width="100%"
        height="6px"
        overflow="hidden"
      >
        <Box
          bgcolor="expressDelivery"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          bottom={0}
        />
      </Box>
    </HeaderPanelBase>
  );
}
