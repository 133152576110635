import { Box } from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import React from "react";
import { useContextSelector } from "use-context-selector";
import { getAllLanguagesForCountry } from "../../../../helpers/country";
import { getLocaleFromCountryLang } from "../../../../helpers/locale";
import { getLocalizedPath } from "../../../../helpers/path";
import useCountry from "../../../../hooks/use-country";
import useLang from "../../../../hooks/use-lang";
import useTranslate from "../../../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../../../hooks/use-unlocalized-location-path";
import { LayoutContext } from "../../../../providers/layout";
import { CountryType, LangType } from "../../../../types/airgraft";
import { MenuItemLink, Separator } from "./styles";

// Use same modal styles as MobileCountryMenu
import { analyticsIdentifyLanguage } from "../../../../services/analytics";
import { PanelTitle } from "../../PanelBase/styles";
import {
  CustomMobileMenuButton,
  MobileMenu,
  MobileMenuBackground,
  MobileMenuContent,
  MobileMenuInner,
  MobileMenuItemsList
} from "../MobileCountryMenu/styles";

export default function MobileLanguageMenu() {
  const t = useTranslate();
  const unlocalizedPath = useUnlocalizedLocationPath();
  const currentLanguage = useLang();
  const currentCountry = useCountry();
  const countryLanguages = getAllLanguagesForCountry(currentCountry);

  const isMobileLanguageMenuOpen = useContextSelector(
    LayoutContext,
    c => c.isMobileLanguageMenuOpen
  );
  const toggleMobileLanguageMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileLanguageMenu
  );

  const getMenuItemLinkTo = (country: CountryType, language: LangType) => {
    // Fallback to english if country doesnt have current language (Ex USA french)
    const targetLocale =
      getLocaleFromCountryLang(country, language) ||
      getLocaleFromCountryLang(country, "en");

    return getLocalizedPath(targetLocale, unlocalizedPath);
  };

  const handleLanguageMenuItemClick = (lang: LangType) => {
    analyticsIdentifyLanguage(lang);
    toggleMobileLanguageMenu(false);
  };

  if (!countryLanguages || countryLanguages.length <= 1) {
    return null;
  }

  return (
    <MobileMenu>
      <MobileMenuInner className={isMobileLanguageMenuOpen ? "open" : ""}>
        <MobileMenuBackground
          className={isMobileLanguageMenuOpen ? "open" : ""}
        >
          <MobileMenuContent className={isMobileLanguageMenuOpen ? "open" : ""}>
            <CustomMobileMenuButton
              onClick={toggleMobileLanguageMenu}
              isOpen={isMobileLanguageMenuOpen}
            />

            <MobileMenuItemsList>
              <Box mb={3.5}>
                <PanelTitle>{t("header.selectLanguage")}</PanelTitle>
              </Box>

              {/* Languages */}
              {countryLanguages.map((language: LangType, index) => (
                <React.Fragment key={language}>
                  <MenuItemLink
                    to={getMenuItemLinkTo(currentCountry, language)}
                    onClick={() => handleLanguageMenuItemClick(language)}
                  >
                    {t(`language.${language.toUpperCase()}`)}
                    {language === currentLanguage && <CheckRoundedIcon />}
                  </MenuItemLink>
                  {index !== countryLanguages.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </MobileMenuItemsList>
          </MobileMenuContent>
        </MobileMenuBackground>
      </MobileMenuInner>
    </MobileMenu>
  );
}
