import { DeliveryFormDataType } from "../containers/express-delivery/Checkout/containers/CheckoutPage";
import {
  getDefaultProductItemVariant,
  getDefaultProductVariant
} from "../helpers/product";
import { getProductDetailsFullUrl } from "../helpers/url";
import {
  CartItemType,
  CartStateType
} from "../providers/express-delivery-cart";
import {
  DeliveryAddressType,
  LangType,
  ProductListItemType,
  ProductType,
  UserType
} from "../types/airgraft";
import { OrderType } from "../types/airgraft-express";

export function analyticsIdentify(user: UserType) {
  if (!user?.uuid) return;

  analyticsPushToKlaviyoDataLater([
    "identify",
    {
      $id: user.uuid
    }
  ]);

  if (window._browsee) {
    window._browsee("identify", user.uuid, {
      email: user.email,
      phoneNumber: user.phoneNumber,
      givenName: user.givenName,
      familyName: user.familyName
    });
  }
}

export function analyticsIdentifyDeliveryAddress(address: DeliveryAddressType) {
  if (!address) return;

  // https://apidocs.klaviyo.com/reference/track-identify
  analyticsPushToKlaviyoDataLater([
    "identify",
    {
      $city: address.city,
      $region: address.state,
      $country: address.country,
      $zip: address.postalCode,
      $address1: address.addressOne
    }
  ]);
}

export function analyticsIdentifyLanguage(lang: LangType) {
  if (!lang) return;

  analyticsPushToKlaviyoDataLater([
    "identify",
    {
      language: lang
    }
  ]);
}

export function analyticsUnidentify() {}

/**
 * Track event inside Google tag manager. It will be automatically forwarded to Google Analytics
 */
export function analyticsTrackEvent(
  eventName: string,
  eventProperties?: { [key: string]: any }
) {
  const eventData = {
    event: `airgraft.${eventName}`,
    ...eventProperties
  };

  // Push custom event to data layer
  analyticsPushToGoogleDatalayer(eventData);

  // Clear any custom event properties after pushing custom event
  if (eventProperties && Object.keys(eventProperties).length > 0) {
    const clearProperties = Object.keys(eventProperties).reduce(
      (accumulator, current) => {
        accumulator[current] = undefined;
        return accumulator;
      },
      {}
    );
    analyticsPushToGoogleDatalayer(clearProperties);
  }

  // Browsee
  if (window._browsee) {
    window._browsee("logEvent", eventName, eventProperties);
  }
}

/**
 * Push data to Google tag manager datalayer
 * https://developers.google.com/tag-manager/ecommerce-ga4
 */
export function analyticsPushToGoogleDatalayer(data: Record<string, any>) {
  if (!window?.dataLayer) {
    // console.info(`[Analytics] Push to datalayer: `, data);
    return;
  }
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(data);
}

/**
 * Push data to Klaviyo global object
 * https://apidocs.klaviyo.com/reference/javascript-client-library
 */
export function analyticsPushToKlaviyoDataLater(arg: any[]) {
  if (!window._learnq) {
    window._learnq = [];
  }
  window._learnq.push(arg);
}

export function analyticsTrackProductView(product: ProductType) {
  const productVariant = getDefaultProductVariant(product);
  analyticsPushToGoogleDatalayer({
    event: "view_item",
    ecommerce: {
      items: [
        {
          item_name: productVariant.title,
          item_id: product.originalId,
          item_brand: productVariant.brand.name,
          item_category: product.productType,
          price: productVariant.price
        }
      ]
    }
  });

  // https://help.klaviyo.com/hc/en-us/articles/115005082927-Guide-to-Integrating-a-Standard-Ecommerce-Platform#viewed-product3
  analyticsPushToKlaviyoDataLater([
    "track",
    "Viewed Product",
    {
      ProductName: productVariant.title,
      ProductID: productVariant.originalId,
      SKU: productVariant.originalId,
      ImageURL: productVariant.featuredImage.url,
      URL: getProductDetailsFullUrl(product.productType, product.slug),
      Brand: productVariant.brand.name,
      Price: productVariant.price
    }
  ]);

  analyticsPushToKlaviyoDataLater([
    "trackViewedItem",
    {
      Title: productVariant.title,
      ItemId: productVariant.originalId,
      ImageUrl: productVariant.featuredImage.url,
      Url: getProductDetailsFullUrl(product.productType, product.slug),
      Metadata: {
        Brand: productVariant.brand.name,
        Price: productVariant.price
      }
    }
  ]);
}

export function analyticsTrackProductClick(product: ProductListItemType) {
  const productVariant = getDefaultProductItemVariant(product);
  analyticsPushToGoogleDatalayer({
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_name: productVariant.title,
          item_id: product.originalId,
          item_brand: productVariant.brand.name,
          item_category: product.productType,
          price: productVariant.price
          // item_variant: productVariant.variantTitle,
          // item_list_name: productObj.list_name,
          // item_list_id: productObj.list_id,
          // index: productObj.index,
          // quantity: productObj.quantity,
        }
      ]
    }
  });
}

export function analyticsTrackCartAdd(
  cartItem: CartItemType,
  allCartItems?: CartItemType[]
) {
  // Browsee
  if (window._browsee) {
    window._browsee("logEvent", "add_to_cart", [
      {
        item_id: cartItem.productVariantId,
        item_name: cartItem.metadata.name,
        item_category: cartItem.metadata.productType,
        item_brand: cartItem.metadata.brand,
        price: cartItem.price,
        quantity: 1
      }
    ]);
  }

  analyticsPushToGoogleDatalayer({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          item_id: cartItem.productVariantId,
          item_name: cartItem.metadata.name,
          item_category: cartItem.metadata.productType,
          item_brand: cartItem.metadata.brand,
          price: cartItem.price,
          quantity: 1
        }
      ]
    }
  });

  if (allCartItems) {
    analyticsPushToKlaviyoDataLater([
      "track",
      "Added to Cart",
      {
        $value: cartItem.price,
        AddedItemProductName: cartItem.metadata.name,
        AddedItemProductID: cartItem.productVariantId,
        AddedItemSKU: cartItem.productVariantId,
        AddedItemImageURL: cartItem.metadata.image,
        AddedItemURL: getProductDetailsFullUrl(
          cartItem.metadata.productType,
          cartItem.metadata.slug
        ),
        AddedItemPrice: cartItem.price,
        AddedItemQuantity: cartItem.quantity,
        ItemNames: allCartItems.map(item => item.metadata?.name),
        CheckoutURL: `${process.env.SITE_URL}/us/en/delivery/checkout/`,
        Items: allCartItems.map(item => ({
          ProductID: item.productVariantId,
          SKU: item.productVariantId,
          ProductName: item.metadata.name,
          Quantity: item.quantity,
          ItemPrice: item.price,
          RowTotal: item.price * item.quantity,
          ImageURL: item.metadata.image,
          ProductURL: getProductDetailsFullUrl(
            item.metadata.productType,
            item.metadata.slug
          )
        }))
      }
    ]);
  }
}

export function analyticsTrackCartRemove(cartItem: CartItemType) {
  analyticsPushToGoogleDatalayer({
    event: "remove_from_cart",
    ecommerce: {
      items: [
        {
          item_id: cartItem.productVariantId,
          item_name: cartItem.metadata.name,
          item_category: cartItem.metadata.productType,
          item_brand: cartItem.metadata.brand,
          price: cartItem.price,
          quantity: 1
        }
      ]
    }
  });
}

export function analyticsTrackBeginCheckout(cart: CartStateType) {
  if (window._browsee) {
    window._browsee("logEvent", "begin_checkout", [
      {
        items: cart.items.map(cartItem => ({
          item_id: cartItem.productVariantId,
          item_name: cartItem.metadata.name,
          item_category: cartItem.metadata.productType,
          item_brand: cartItem.metadata.brand,
          price: cartItem.price,
          quantity: cartItem.quantity
        }))
      }
    ]);
  }

  analyticsPushToGoogleDatalayer({
    event: "begin_checkout",
    ecommerce: {
      items: cart.items.map(cartItem => ({
        item_id: cartItem.productVariantId,
        item_name: cartItem.metadata.name,
        item_category: cartItem.metadata.productType,
        item_brand: cartItem.metadata.brand,
        price: cartItem.price,
        quantity: cartItem.quantity
      }))
    }
  });

  // https://help.klaviyo.com/hc/en-us/articles/115005082927-Guide-to-Integrating-a-Standard-Ecommerce-Platform#started-checkout5
  analyticsPushToKlaviyoDataLater([
    "track",
    "Started Checkout",
    {
      $event_id: `${new Date().getTime()}`,
      $value: cart.cartTotal,
      ItemNames: cart.items.map(
        item => item.metadata.name || item.productVariantId
      ),
      CheckoutURL: `${process.env.SITE_URL}/us/en/delivery/checkout/`,
      Items: cart.items.map(item => ({
        ProductID: item.productVariantId,
        SKU: item.productVariantId,
        ProductName: item.metadata.name,
        Quantity: item.quantity,
        ItemPrice: item.price,
        RowTotal: item.price * item.quantity,
        ImageURL: item.metadata.image,
        ProductURL: item.metadata.slug
          ? getProductDetailsFullUrl(
              item.metadata.productType,
              item.metadata.slug
            )
          : null
      }))
    }
  ]);
}

export function analyticsTrackPurchase({
  cart,
  order,
  deliveryFormData
}: {
  cart: CartStateType;
  order: OrderType;
  deliveryFormData: DeliveryFormDataType;
}) {
  if (window._browsee) {
    window._browsee("logEvent", "purchase", [
      {
        transaction_id: order.orderLogId,
        affiliation: "XPRESS",
        value: order.total.amount,
        tax: order.taxTotal.amount,
        shipping: order.deliveryFeeTotal.amount,
        currency: order.total.currencyCode,
        items: cart.items.map(cartItem => ({
          item_id: cartItem.productVariantId,
          item_name: cartItem.metadata.name,
          item_category: cartItem.metadata.productType,
          item_brand: cartItem.metadata.brand,
          price: cartItem.price,
          quantity: cartItem.quantity
        }))
      }
    ]);
  }

  analyticsPushToGoogleDatalayer({
    event: "purchase",
    ecommerce: {
      transaction_id: order.orderLogId,
      affiliation: "XPRESS",
      value: order.total.amount,
      tax: order.taxTotal.amount,
      shipping: order.deliveryFeeTotal.amount,
      currency: order.total.currencyCode,
      items: cart.items.map(cartItem => ({
        item_id: cartItem.productVariantId,
        item_name: cartItem.metadata.name,
        item_category: cartItem.metadata.productType,
        item_brand: cartItem.metadata.brand,
        price: cartItem.price,
        quantity: cartItem.quantity
      }))
    }
  });

  // https://help.klaviyo.com/hc/en-us/articles/115005082927-Guide-to-Integrating-a-Standard-Ecommerce-Platform#placed-order7
  analyticsPushToKlaviyoDataLater([
    "track",
    "Placed Order",
    {
      $event_id: order.orderLogId,
      $value: order.total.amount,
      OrderId: order.orderLogId,
      ItemNames: cart.items.map(item => item.metadata.name),
      Brands: cart.items.map(item => item.metadata.brand),
      Items: cart.items.map(cartItem => ({
        item_id: cartItem.productVariantId,
        item_name: cartItem.metadata.name,
        item_category: cartItem.metadata.productType,
        item_brand: cartItem.metadata.brand,
        price: cartItem.price,
        quantity: cartItem.quantity
      })),
      BillingAddress: {
        FirstName: deliveryFormData.givenName,
        LastName: deliveryFormData.familyName,
        Phone: deliveryFormData.phoneNumber,
        Address1: order.deliveryAddress.addressOne,
        Address2: order.deliveryAddress.addressTwo,
        City: order.deliveryAddress.city,
        Region: order.deliveryAddress.state,
        RegionCode: order.deliveryAddress.state,
        Country: order.deliveryAddress.country,
        CountryCode: order.deliveryAddress.country,
        Zip: order.deliveryAddress.postalCode
      },
      ShippingAddress: {
        FirstName: deliveryFormData.givenName,
        LastName: deliveryFormData.familyName,
        Phone: deliveryFormData.phoneNumber,
        Address1: order.deliveryAddress.addressOne,
        Address2: order.deliveryAddress.addressTwo,
        City: order.deliveryAddress.city,
        Region: order.deliveryAddress.state,
        RegionCode: order.deliveryAddress.state,
        Country: order.deliveryAddress.country,
        CountryCode: order.deliveryAddress.country,
        Zip: order.deliveryAddress.postalCode
      }
    }
  ]);

  cart.items.forEach(cartItem => {
    analyticsPushToKlaviyoDataLater([
      "track",
      "Ordered Product",
      {
        $event_id: `${order.orderLogId}_${cartItem.productVariantId}`,
        $value: cartItem.price,
        OrderId: order.orderLogId,
        ProductID: cartItem.productVariantId,
        SKU: cartItem.productVariantId,
        ProductName: cartItem.metadata.name,
        Quantity: cartItem.quantity,
        ProductURL: getProductDetailsFullUrl(
          cartItem.metadata.productType,
          cartItem.metadata.slug
        ),
        ImageURL: cartItem.metadata.image,
        ProductBrand: cartItem.metadata.brand
      }
    ]);
  });
}
