/**
 * Type of background supported by card
 */
export type BACKGROUND_TYPE = "image" | "video";

/**
 * Use this breakpoint to decide when to start using "Mobile override" card fields (Ex: backgroundMobile, aspectRatioMobile, columnsMobile)
 * `theme.down(MOBILE_BREAKPOINT)`
 */
export const MOBILE_BREAKPOINT = "xs";

/**
 * Spacing around each cards in theme unit (8px)
 */
export const SPACING_AROUND = 4;
export const SPACING_AROUND_MOBILE = 2;

/**
 * Border radius around each card
 */
export const DEFAULT_BORDER_RADIUS = "8px";
export const DEFAULT_BORDER_RADIUS_MOBILE = "3px";

/**
 * Default aspect ratio if the value coming from Dato is invalid
 */
export const DEFAULT_ASPECT_RATIO = "2.2:1";
export const DEFAULT_ASPECT_RATIO_MOBILE = "1:1";

/**
 * Default font size for title
 */
export const DEFAULT_TITLE_FONT_SIZE = "h5";

/**
 * Default font size for subtitle
 */
export const DEFAULT_SUBTITLE_FONT_SIZE = "h5";
