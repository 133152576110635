import React, { useEffect } from "react";
import { useToggle } from "react-use";
import { useLocalStorageState } from "../helpers/localstorage";
import { useExpressDeliveryAvailabilitySelector } from "../hooks/use-express-delivery-availability";
import {
  analyticsTrackCartAdd,
  analyticsTrackCartRemove
} from "../services/analytics";

export const CART_LOCALSTORAGE_KEY = `airgraft-express-delivery-cart-shop-shop-v1`; // Bump this version to auto invalidate past client storage if the format changes.

/**
 * @file Reference https://github.com/notrab/react-use-cart
 */

export interface CartItemType {
  productVariantId: string;
  price?: number;
  quantity?: number;
  itemTotal?: number;
  metadata?: {
    name: string;
    slug?: string;
    productType?: string;
    brand?: string;
    image?: string;
    thcGrams?: number; // Used to calculate max 8g california limit
  };
}

/**
 * Properties for a cart. This is what is being saved in localstorage
 */
export type CartStateType = {
  items: CartItemType[];
  isCartEmpty: boolean;
  totalItems: number;
  totalUniqueItems: number;
  cartTotal: number;
};

export type CartErrorType =
  | "NO_DELIVERY_ADDRESS"
  | "UNAVAILABLE_DELIVERY_ADDRESS"
  | "EMPTY_CART"
  | "UNDER_MINIMUM_TOTAL"
  | "ONLY_REWARDS"
  | "OVER_THC_TOTAL_LIMIT";

/**
 * Properties returned by the context
 */
type CartContextState = CartStateType & {
  toggleCartPanel: (nextValue?: boolean) => void;
  addItem: (item: CartItemType, quantity?: number) => void;
  removeItem: (id: string) => void;
  updateItemQuantity: (id: string, quantity: number) => void;
  emptyCart: () => void;
  getItem: (id: string) => CartItemType | undefined;
  inCart: (id: string) => boolean;
  isCartPanelOpen: boolean;
  cartError: CartErrorType;
};

export const initialCartState: CartStateType = {
  items: [],
  isCartEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  cartTotal: 0
};

export type CartStateReducerActions =
  | { type: "SET_ITEMS"; payload: CartItemType[] }
  | { type: "ADD_ITEM"; payload: CartItemType }
  | { type: "REMOVE_ITEM"; id: string }
  | {
      type: "UPDATE_ITEM";
      id: string;
      payload: CartItemType;
    }
  | { type: "EMPTY_CART" };

export const ExpressDeliveryCartContext = React.createContext<
  CartContextState | undefined
>(initialCartState as any);

function reducer(state: CartStateType, action: CartStateReducerActions) {
  switch (action.type) {
    case "SET_ITEMS":
      return generateCartState(state, action.payload);

    case "ADD_ITEM": {
      const items = [...state.items, action.payload];
      const newState = generateCartState(state, items);
      analyticsTrackCartAdd(action.payload, newState.items);
      return newState;
    }

    case "UPDATE_ITEM": {
      const items = state.items.map((item: CartItemType) => {
        if (item.productVariantId !== action.id) return item;

        const isAdding = item.quantity < action.payload.quantity;
        if (isAdding) {
          analyticsTrackCartAdd(action.payload);
        } else {
          analyticsTrackCartRemove(action.payload);
        }

        return {
          ...item,
          ...action.payload
        };
      });

      return generateCartState(state, items);
    }

    case "REMOVE_ITEM": {
      const removedItem = state.items.find(
        (i: CartItemType) => i.productVariantId === action.id
      );

      const items = state.items.filter(
        (i: CartItemType) => i.productVariantId !== action.id
      );

      if (removedItem) {
        analyticsTrackCartRemove(removedItem);
      }

      return generateCartState(state, items);
    }

    case "EMPTY_CART":
      return initialCartState;

    default:
      return state;
  }
}

const generateCartState = (state = initialCartState, items: CartItemType[]) => {
  const totalUniqueItems = calculateUniqueItems(items);
  const isCartEmpty = totalUniqueItems === 0;

  return {
    ...initialCartState,
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    cartTotal: calculateTotal(items),
    isCartEmpty
  };
};

const calculateItemTotals = (items: CartItemType[]) =>
  items.map(item => ({
    ...item,
    itemTotal: item.price * item.quantity
  }));

const calculateTotal = (items: CartItemType[]) =>
  items.reduce((total, item) => total + item.quantity * item.price, 0);

const calculateTotalItems = (items: CartItemType[]) =>
  items.reduce((sum, item) => sum + item.quantity, 0);

const calculateUniqueItems = (items: CartItemType[]) => items.length;

export const ExpressDeliveryCartProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const address = useExpressDeliveryAvailabilitySelector(c => c.address);
  const availableProducts = useExpressDeliveryAvailabilitySelector(
    c => c.availableProducts
  );
  const availableProvider = useExpressDeliveryAvailabilitySelector(
    c => c.availableProvider
  );

  const [isCartPanelOpen, toggleCartPanel] = useToggle(false);

  const [savedCart, saveCart] = useLocalStorageState(CART_LOCALSTORAGE_KEY, {
    ...initialCartState
  } as CartStateType);

  const [state, dispatch] = React.useReducer(reducer, savedCart);

  // On cart change: Save to localstorage
  useEffect(() => {
    saveCart(state);
  }, [state, saveCart]);

  const addItem = (item: CartItemType, quantity = 1) => {
    if (!item.productVariantId)
      throw new Error("You must provide an `productVariantId` for items");
    if (quantity <= 0) return;

    const currentItem = state.items.find(
      (i: CartItemType) => i.productVariantId === item.productVariantId
    );

    // Use price from current provider products data
    const price =
      availableProducts?.find(p => p.productVariantId === item.productVariantId)
        ?.price?.amount || 0;

    if (!currentItem) {
      const payload = { ...item, price, quantity };
      dispatch({ type: "ADD_ITEM", payload });
      return;
    }

    const payload = {
      ...item,
      price,
      quantity: currentItem.quantity + quantity
    };

    dispatch({
      type: "UPDATE_ITEM",
      id: item.productVariantId,
      payload
    });
  };

  const updateItemQuantity = (id: string, quantity: number) => {
    if (quantity <= 0) {
      dispatch({ type: "REMOVE_ITEM", id });

      return;
    }

    const currentItem = state.items.find(
      (item: CartItemType) => item.productVariantId === id
    );

    if (!currentItem) throw new Error("No such item to update");

    const payload = { ...currentItem, quantity };

    dispatch({
      type: "UPDATE_ITEM",
      id,
      payload
    });
  };

  const removeItem = (id: string) => {
    if (!id) return;

    dispatch({ type: "REMOVE_ITEM", id });
  };

  const emptyCart = () =>
    dispatch({
      type: "EMPTY_CART"
    });

  const setItems = (items: CartItemType[]) => {
    dispatch({
      type: "SET_ITEMS",
      payload: items
    });
  };

  const getItem = (id: string) =>
    state.items.find((i: CartItemType) => i.productVariantId === id);

  const inCart = (id: string) =>
    state.items.some((i: CartItemType) => i.productVariantId === id);

  // On available products change (Address or Page reload): Update cart prices
  useEffect(() => {
    updateCartItemsPricesWithAvailableProvider();
  }, [availableProducts]);

  const updateCartItemsPricesWithAvailableProvider = () => {
    // Update cart items prices
    const updatedItems: CartItemType[] = (state?.items || []).map(item => {
      const itemProviderData = (availableProducts || []).find(
        p => p.productVariantId === item.productVariantId
      );
      const updatedPrice = itemProviderData?.price?.amount || 0; // Set price to 0$ if item is not available with current provider
      return {
        ...item,
        price: updatedPrice
      } as CartItemType;
    });
    setItems(updatedItems);
  };

  // Check if cart total is under minimum amount for current provider
  const isCartTotalUnderMinimumAmount = !(
    state.cartTotal >= (availableProvider?.deliveryTotalMinimum || 0)
  );

  // Check if cart total THC is over limit for current provider
  const cartThcTotal: number = state.items.reduce(
    (accumulator, item) =>
      (accumulator += (item.metadata?.thcGrams || 0) * item.quantity),
    0
  );

  const isCartThcTotalOverLimit =
    availableProvider?.purchaseLimitConcentratedWeight &&
    cartThcTotal > availableProvider?.purchaseLimitConcentratedWeight;

  // Detect any error with the cart
  let cartError = null as CartErrorType;
  if (!address) {
    cartError = "NO_DELIVERY_ADDRESS";
  }
  if (address && !availableProvider) {
    cartError = "UNAVAILABLE_DELIVERY_ADDRESS";
  }
  if (
    !state.isCartEmpty &&
    availableProvider &&
    isCartTotalUnderMinimumAmount
  ) {
    cartError = "UNDER_MINIMUM_TOTAL";
  }
  if (isCartThcTotalOverLimit) {
    cartError = "OVER_THC_TOTAL_LIMIT";
  }
  if (state.isCartEmpty) {
    cartError = "EMPTY_CART";
  }

  return (
    <ExpressDeliveryCartContext.Provider
      value={{
        ...state,
        isCartPanelOpen,
        toggleCartPanel,
        getItem,
        inCart,
        addItem,
        updateItemQuantity,
        removeItem,
        emptyCart,
        cartError
      }}
    >
      {children}
    </ExpressDeliveryCartContext.Provider>
  );
};
