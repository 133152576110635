import { Badge, NoSsr } from "@material-ui/core";
import React, { useRef } from "react";
import ShopingCartIcon from "../../../components/icons/shopping-cart";
import useExpressDeliveryCart from "../../../hooks/use-express-delivery-cart";
import ExpressCartPanel from "../CartPanel";
import { CartButton as CartButton } from "./styles";

const cartPanelProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  }
};

function HeaderExpressDeliveryCartButton({
  hidden,
  withPanel
}: {
  hidden?: boolean;
  withPanel?: boolean;
}) {
  const buttonRef = useRef<HTMLButtonElement>();
  const { toggleCartPanel, totalItems } = useExpressDeliveryCart();

  const icon = <ShopingCartIcon color="secondary" />;

  return (
    <>
      <CartButton
        onClick={() => toggleCartPanel()}
        ref={buttonRef}
        style={{ display: hidden ? "none" : "flex" }}
        data-testid="express-cart-button"
      >
        <NoSsr fallback={icon}>
          <Badge
            badgeContent={totalItems || 0}
            className="express-delivery"
            invisible={!totalItems || totalItems === 0}
            overlap="rectangular"
          >
            {icon}
          </Badge>
        </NoSsr>
      </CartButton>

      {!hidden && withPanel && (
        <ExpressCartPanel
          anchorEl={buttonRef?.current}
          {...(cartPanelProps as any)}
        />
      )}
    </>
  );
}

export default React.memo(HeaderExpressDeliveryCartButton);
