import "whatwg-fetch";
import "url-search-params-polyfill";
import "proxy-polyfill";
import { GatsbyBrowser } from "gatsby";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import Layout from "./src/components/layout";
import Providers from "./src/providers";
import injectVhCssVariables from "./src/helpers/vh-css-variables";
import { LayoutPageContextProps } from "./src/components/layout";

/**
 * Allow a plugin to wrap the root element.
 * This is useful to set up any Provider components that will wrap your application.
 */
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => <Providers>{element}</Providers>;

/**
 * Allow a plugin to wrap the page element.
 * This is useful for setting wrapper components around pages that won’t get unmounted on page changes.
 */
export const wrapPageElement: GatsbyBrowser<
  unknown,
  LayoutPageContextProps
>["wrapPageElement"] = ({ element, props }) => (
  <Layout {...props}>{element as any}</Layout>
);

/**
 * Called when the Gatsby browser runtime first starts
 */
export const onClientEntry: GatsbyBrowser["onClientEntry"] = async () => {
  // Inject global css variables --100vh and --initial-100vh
  injectVhCssVariables();

  // Polyfill IntersectionObserver
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  // Polyfill window.scrollTo({ scroll-behavior: "smooth" }) for iPhone Safari
  smoothscroll.polyfill();
};

/**
 * Allows a plugin to influence scrolling behavior on navigation.
 * Default behavior is persisting last known scrolling positions and scrolling back to them on navigation.
 */
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = () => {
  return [0, 0]; // Always force page to be scrolled top
};
