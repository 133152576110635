import api from "./api";
import { isBrowser } from "../helpers/platform-detect";
import {
  UserType,
  UserExpressDataType,
  ProviderUserDataType
} from "../types/airgraft";
import { addSentryClientExtra } from "./sentry";
import { formatPhoneNumberForApi } from "../helpers/phone-number";
import { REACT_QUERY_LOCALSTORAGE_KEY } from "../providers/react-query";
import { CART_LOCALSTORAGE_KEY } from "../providers/express-delivery-cart";

export const TOKEN = "token";

/**
 * Get user authentication token from localstorage
 */
export function getToken(): string | null {
  if (!isBrowser()) {
    return null;
  }
  let token;
  try {
    token = window.localStorage.getItem(TOKEN);
  } catch (error) {
    token = window.TOKEN;
  }
  return token;
}

/**
 * Set user authentication token in localstorage
 */
export function setToken(token: string) {
  if (token) {
    try {
      window.localStorage.setItem(TOKEN, token);
    } catch (error) {
      window.TOKEN = token;
    }
  } else {
    try {
      window.localStorage.removeItem(TOKEN);
    } catch (error) {
      window.TOKEN = null;
    }
  }
}

/**
 * Check if user is logged in
 */
export function isAuthenticated(): boolean {
  const token = getToken();
  return !!token;
}

/**
 * Get current logged-in user
 */
export async function getCurrentUser(): Promise<UserType | null> {
  if (!isAuthenticated()) {
    return null;
  }
  const response = await api.get(`v1/app/user/me`);

  if (!response?.ok) {
    return null;
  }
  const data = await response.json();
  const user = data as UserType;

  return user;
}

export async function logout() {
  await api.delete(`v1/app/user/logout`);
  setToken(null);
  try {
    window.localStorage?.removeItem(REACT_QUERY_LOCALSTORAGE_KEY);
    window.localStorage?.removeItem(CART_LOCALSTORAGE_KEY);
  } catch (error) {}
}

export type UserRegisterDataType = {
  verificationCode: string;
  email: string;
  password: string;
  familyName?: string;
  givenName?: string;
  emailMarketingOptin?: boolean;
  airgraftExpressData?: UserExpressDataType;
};

/**
 * Send sign-in otp via email
 */
export async function sendUserAuthenticationEmailOtp(
  email: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/auth/email`, {
    json: {
      email,
      link: false
    }
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data?.message || "Something went wrong");
  }
  return true;
}

/**
 * Verify otp sent out inside email
 */
export async function verifyUserAuthenticationEmailOtp(
  email: string,
  otp: string
): Promise<UserType> {
  const response = await api.post(`v1/app/user/auth/email/login`, {
    json: { email, otp }
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data?.message || "Something went wrong");
  }

  if (!data.appToken) {
    throw new Error("Missing user token");
  }

  return data as UserType;
}

/**
 * Send otp via email to modify email
 */
export async function sendUserAuthenticationEmailOtpForUpdate(
  email: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/task-to-change-email`, {
    json: {
      email
    }
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data?.message || "Something went wrong");
  }
  return true;
}

/**
 * Verify otp for modifing email
 */
export async function verifyUserAuthenticationEmailOtpForUpdate(
  otp: string
): Promise<UserType> {
  const response = await api.post(`v1/app/user/execute-change-email`, {
    json: { otp }
  });
  const data = await response.json();
  if (!response.ok) {
    if (response.status >= 400 && response.status < 500) {
      throw new Error(data?.message || "Invalid verification code");
    }
    throw new Error(data?.message || "Something went wrong");
  }

  return data as UserType;
}

/**
 * Send sign-in code to phone number via SMS
 */
export async function sendUserAuthenticationPhoneNumberOtp(
  phoneNumber: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/auth/phone`, {
    json: { phoneNumber: formatPhoneNumberForApi(phoneNumber) }
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data?.message || "Something went wrong");
  }
  return true;
}

/**
 * Verify sign-in code sent via SMS
 */
export async function verifyUserAuthenticationPhoneNumberOtp(
  phoneNumber: string,
  verificationCode: string
): Promise<UserType> {
  const response = await api.post(`v1/app/user/auth/phone/login`, {
    json: {
      phoneNumber: formatPhoneNumberForApi(phoneNumber),
      otp: verificationCode
    }
  });
  const data = await response.json();
  if (!response.ok) {
    if (response.status === 400) {
      throw new Error(data?.message || "Invalid verification code");
    }
    throw new Error(data?.message || "Something went wrong");
  }
  return data as UserType;
}

/**
 * Send otp code to phone number for update
 */
export async function sendUserAuthenticationPhoneNumberOtpForUpdate(
  phoneNumber: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/task-to-change-phone`, {
    json: { phoneNumber: formatPhoneNumberForApi(phoneNumber) }
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data?.message || "Something went wrong");
  }
  return true;
}

/**
 * Verify otp code for updating phone number
 */
export async function verifyUserAuthenticationPhoneNumberOtpForUpdate(
  verificationCode: string
): Promise<UserType> {
  const response = await api.post(`v1/app/user/execute-change-phone`, {
    json: {
      otp: verificationCode
    }
  });
  const data = await response.json();
  if (!response.ok) {
    if (response.status >= 400 && response.status < 500) {
      throw new Error("Invalid verification code");
    }
    throw new Error(
      data?.message || "Something went wrong, please try again later."
    );
  }
  return data as UserType;
}

/**
 * Check if a user is already registered with a email address
 * @param email
 */
export async function isEmailAlreadyRegistered(
  email: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/v2/email-available`, {
    json: {
      email
    }
  });
  if (!response.ok) {
    return false;
  }
  const data = await response.json();
  return !data.emailAvailable;
}

/**
 * Check if a user is already registered with a phone number
 * @param email
 */
export async function isPhoneNumberAlreadyRegistered(
  phoneNumber: string
): Promise<boolean> {
  const response = await api.post(`v1/app/user/v2/phone-available`, {
    json: {
      phoneNumber: formatPhoneNumberForApi(phoneNumber)
    }
  });
  if (!response.ok) {
    return false;
  }
  const data = await response.json();
  return !data.phoneAvailable;
}

/**
 * Update existing user data
 */
export async function updateUser(data: {
  givenName?: string;
  familyName?: string;
  email?: string;
}): Promise<UserType> {
  const response = await api.put("v1/app/user/v2", {
    json: data
  });
  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.message);
  }

  return json as UserType;
}

/**
 * Update existing user express delivery data
 */
export async function updateUserDeliveryData(
  data: Partial<UserExpressDataType>
): Promise<UserExpressDataType> {
  const response = await api.put("v1/app/user/airgraftExpressData", {
    json: data
  });
  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.message);
  }

  return json as UserExpressDataType;
}

/**
 * Link existing airgraft user to a delivery provider
 */
export async function linkUserToProvider(
  providerId: string
): Promise<ProviderUserDataType> {
  const response = await api.post("v1/app/user/link-to-delivery-provider", {
    json: { providerId }
  });
  if (response.ok) {
    const json = await response.json();
    return json as ProviderUserDataType;
  } else {
    const text = response.text();
    addSentryClientExtra("linkUserToProvider.response", text);
    return null;
  }
}

/**
 * Upload user identification image
 */
export async function uploadUserIdentificationImage(
  providerId: string,
  file: File
): Promise<UserType | null> {
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post(
    `v1/app/user/upload-id?providerId=${providerId}`,
    {
      body: formData
    }
  );

  if (response.ok) {
    const json = await response.json();
    return json as UserType;
  }

  return null;
}

/**
 * Check if user has already updated identification image
 */
export async function hasAlreadyUploadedIdentificationImage(
  providerId: string
): Promise<boolean> {
  try {
    const response = await api.get(
      `v1/app/user/uploaded-id?providerId=${providerId}`
    );
    if (response.ok) {
      const json = await response.json();
      return json.documentStatus === "PRESENT";
    }
  } catch (e) {}
  return false;
}
