import { CountryType, LangType, LocaleType } from "../src/types/airgraft";

export type LocaleConfig = {
  path: string;
  country: CountryType;
  lang: LangType;
};

const locales: Record<LocaleType, LocaleConfig> = {
  "en-US": {
    path: "us/en",
    country: "US",
    lang: "en"
  },
  "en-CA": {
    path: "ca/en",
    country: "CA",
    lang: "en"
  },
  "fr-CA": {
    path: "ca/fr",
    country: "CA",
    lang: "fr"
  }
};

export const DEFAULT_LOCALE: LocaleType = "en-US";

export default locales;
