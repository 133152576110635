/**
 * Provide the --100vh and --initial-100vh css variables to the whole application
 * Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export default function injectVhCssVariables() {
  let lastVh100 = null;
  let lastVw100 = null;

  function updateVhVariables() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh100 = window.innerHeight || document.documentElement.clientHeight;
    const vw100 = window.innerWidth || document.documentElement.clientWidth;

    // Update --100vh to the real time value of viewport height
    document.documentElement.style.setProperty("--100vh", `${vh100}px`);

    const hasRotated = vw100 !== lastVw100 && vh100 !== lastVh100;

    // Update --initial-100vh if 100vh become smaller.
    if (lastVh100 === null || hasRotated) {
      document.documentElement.style.setProperty(
        "--initial-100vh",
        `${vh100}px`
      );
      lastVh100 = vh100;
      lastVw100 = vw100;
    } else {
      const minVh100 = Math.min(vh100, lastVh100);
      if (minVh100 !== lastVh100 && Math.abs(minVh100 - lastVh100) > 50) {
        document.documentElement.style.setProperty(
          "--initial-100vh",
          `${minVh100}px`
        );
        lastVh100 = minVh100;
      }
    }
  }

  window.addEventListener("resize", () => updateVhVariables());
  updateVhVariables();
}

/**
 * Get custom vh css rule based on --100vh
 */
export function getCssVh(percentage: number) {
  return `calc((var(--100vh) / 100) * ${percentage})`;
}
