import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function TrashcanIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 19 19"
      style={{ fill: "none", ...(props.style || {}) }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21094 5.06896C2.21094 4.74712 2.47185 4.48621 2.7937 4.48621L4.5868 4.48621L6.24542 4.48621L6.24542 3.83331C6.24542 2.88177 7.00757 2.09538 7.96611 2.09538L10.242 2.09538C11.2005 2.09538 11.9627 2.88177 11.9627 3.83331L11.9627 4.48621L13.6213 4.48621L15.4144 4.48621C15.7362 4.48621 15.9971 4.74712 15.9971 5.06896C15.9971 5.39081 15.7362 5.65172 15.4144 5.65172L14.204 5.65172L14.204 15C14.204 15.817 13.5417 16.4793 12.7247 16.4793L5.48335 16.4793C4.66635 16.4793 4.00404 15.817 4.00404 15L4.00404 5.65172L2.7937 5.65172C2.47185 5.65172 2.21094 5.39081 2.21094 5.06896ZM5.16956 5.65172L5.16956 15C5.16956 15.1733 5.31005 15.3138 5.48335 15.3138L12.7247 15.3138C12.898 15.3138 13.0385 15.1733 13.0385 15L13.0385 5.65172L5.16956 5.65172ZM10.7971 4.48621L10.7971 3.83331C10.7971 3.50889 10.5404 3.2609 10.242 3.2609L7.96611 3.2609C7.66773 3.2609 7.41094 3.50889 7.41094 3.83331L7.41094 4.48621L10.7971 4.48621Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
