import locales from "../../config/locales";
import { stringify } from "query-string";
import { LocaleType } from "../types/airgraft";
import { OrderType } from "../types/airgraft-express";

export function getLocalizedPath(locale: LocaleType, to: string) {
  const isIndex = to === `/`;

  if (!locale) {
    return `/${isIndex ? `/` : `${to}`}`;
  }

  const path = `/${locales[locale].path}${isIndex ? `/` : `${to}`}`;
  return path;
}

/**
 * Get pathname without region & locale prefix
 * /us/en/devices -> /devices
 */
export function getUnlocalizedPath(localizedPathname: string) {
  const parts = localizedPathname.split("/");
  const unlocalizedPath = parts.slice(3).join("/");
  return `/${unlocalizedPath}`;
}

/**
 * Get sign-in page path with redirect query parameter
 */
export function getSignInPathWithRedirect(redirect: string) {
  return `/sign-in?${stringify({ redirect })}`;
}

/**
 * Add trailing slash to string if missing
 */
export const addTrailingSlash = path => {
  return path.replace(/\/?$/, "/");
};

/*
 * Get order details path (unlocalized)
 */
export function getOrderDetailsPath(order: OrderType) {
  if (!order) return null;
  // Legacy support for deprecated detailsPagePath property
  if (!order.detailsId && (order as any).detailsPagePath) {
    const parts = (order as any).detailsPagePath.split("/us/en");
    return parts[parts.length - 1];
  }
  return `/delivery/order/${order.provider.id}/${order.detailsId}`;
}
