import { styled, Typography } from "@material-ui/core";

export const SubtotalPriceTypography = styled(Typography)(() => ({
  fontFamily: "Reckless, Georgia",
  fontWeight: 400,
  fontSize: `2.4rem`,
  lineHeight: `3rem`
}));

export const ProductItemTypography = styled(Typography)(() => ({
  fontFamily: "Reckless, Georgia",
  fontWeight: 400,
  fontSize: `1.7rem`,
  lineHeight: `2rem`
}));

export const ProductItemSecondaryTypography = styled(Typography)(
  ({ theme }) => ({
    fontFamily: "Reckless, Georgia",
    fontWeight: 400,
    fontSize: `1.7rem`,
    lineHeight: `2.4rem`,
    color: theme.palette.text.secondary
  })
);
