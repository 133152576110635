import { Box, Typography } from "@material-ui/core";
import React from "react";
import useTranslate from "../../../../hooks/use-translate";
import PhoneIconSvg from "./phone-icon.svg";

export default function CallStoreMessage() {
  const t = useTranslate();
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <img src={PhoneIconSvg} width={24} height={24} />
      </Box>
      <Typography variant="body2">{t("storelocator.callAhead")}</Typography>
    </Box>
  );
}
