import { Box, Button, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  fetchDetectedCountryAndSubcountry,
  getClosestAvailableSubcountry,
  getDefaultSubcountry,
  getSubcountries,
  getVisibleSubcountries,
  isValidSubcountry
} from "../../../helpers/country";
import { getLocalizedPath } from "../../../helpers/path";
import useSubcountry from "../../../hooks/use-subcountry";
import useTranslate from "../../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../../hooks/use-unlocalized-location-path";
import ArrowDown from "../../icons/arrow-down";
import UsaFlagIcon from "../../icons/flag-usa";
import { CustomTextField, SwitchLocaleLink } from "../styles";

export type UsaFormDataType = {
  state: string;
};

type Props = {
  onAccept: (state: string) => void;
  onRefuse: () => void;
};

export default function WelcomeModalUsaForm({ onAccept, onRefuse }: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const unlocalizedPath = useUnlocalizedLocationPath();
  const { subcountry: previouslySelectedSubcountry } = useSubcountry();

  const { handleSubmit, errors, control, watch, setValue } =
    useForm<UsaFormDataType>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        state: previouslySelectedSubcountry || getDefaultSubcountry("US")
      }
    });
  const stateValue = watch("state");

  const onSubmit = (data: UsaFormDataType) => {
    onAccept(data.state);
  };

  // Detect country & subcountry to preselect region
  useEffect(() => {
    const autoSelectSubcountry = async () => {
      const data = await fetchDetectedCountryAndSubcountry();
      if (data?.country === "US") {
        const preselectSubcountry = isValidSubcountry("US", data.subcountry)
          ? data.subcountry
          : getClosestAvailableSubcountry("US", data.subcountry);
        setValue("state", preselectSubcountry);
      }
    };
    autoSelectSubcountry();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%"
      }}
    >
      <Typography variant="h5">{t("welcomeModal.US.title")}</Typography>

      <Box mt={3}>
        {/* State field */}
        <Controller
          labelId="state"
          name="state"
          rules={{
            required: t("error.required")
          }}
          control={control}
          error={!!errors.state}
          defaultValue=""
          render={({ onChange, onBlur, value, name, ref }) => (
            <CustomTextField
              select
              variant="filled"
              name={name}
              inputRef={ref}
              value={value}
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.state}
              helperText={errors.state?.message}
              className={!stateValue ? "no-value" : "with-value"}
              SelectProps={{
                native: true,
                disableUnderline: true,
                error: !!errors.state,
                IconComponent: () => (
                  <ArrowDown
                    style={{
                      position: `absolute`,
                      right: theme.spacing(2)
                    }}
                  />
                )
              }}
            >
              <option value="" disabled>
                {t("welcomeModal.US.selectState")}
              </option>
              {getVisibleSubcountries("US").map(subcountry => (
                <option key={subcountry.value} value={subcountry.value}>
                  {subcountry.label}
                </option>
              ))}
            </CustomTextField>
          )}
        />
      </Box>

      <Box
        mt={3}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button variant="contained" fullWidth type="submit">
          {t("welcomeModal.yes")}
        </Button>
        <Box width={theme.spacing(2)} />
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={onRefuse}
        >
          {t("welcomeModal.no")}
        </Button>
      </Box>

      <Box
        borderTop={`1px solid ${theme.palette.common.black}`}
        paddingTop={2}
        display="flex"
        alignItems="center"
      >
        <Box width="30px" height="30px" marginRight={1}>
          <UsaFlagIcon />
        </Box>
        <Typography variant="body2">
          <span
            dangerouslySetInnerHTML={{
              __html: t("welcomeModal.US.footer")
            }}
          />
          &nbsp;
          <SwitchLocaleLink to={getLocalizedPath("en-CA", unlocalizedPath)}>
            {t("welcomeModal.US.footer.switch")}
          </SwitchLocaleLink>
        </Typography>
      </Box>
    </form>
  );
}
