import { useContext, useContextSelector } from "use-context-selector";
import {
  ExpressDeliveryAvailabilityContext,
  ExpressDeliveryAvailabilityContextType
} from "../providers/express-delivery-availability";

export default function useExpressDeliveryAvailability() {
  return useContext(ExpressDeliveryAvailabilityContext);
}

/**
 * Get partial state from ExpressDeliveryAvailabilityContext. Better performance than using useExpressDeliveryAvailability
 */
export function useExpressDeliveryAvailabilitySelector<Selected>(
  selector: (value: ExpressDeliveryAvailabilityContextType) => Selected
) {
  return useContextSelector(ExpressDeliveryAvailabilityContext, selector);
}
