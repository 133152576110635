import { Box, Typography } from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import React from "react";
import { useContextSelector } from "use-context-selector";
import COUNTRIES from "../../../../../config/countries";
import {
  getDefaultSubcountry,
  getVisibleSubcountries
} from "../../../../helpers/country";
import { isPageDisabledByFeatureFlags } from "../../../../helpers/feature-flags";
import { getLocaleFromCountryLang } from "../../../../helpers/locale";
import { getLocalizedPath } from "../../../../helpers/path";
import useCountry from "../../../../hooks/use-country";
import useLang from "../../../../hooks/use-lang";
import useSubcountry from "../../../../hooks/use-subcountry";
import useTranslate from "../../../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../../../hooks/use-unlocalized-location-path";
import { LayoutContext } from "../../../../providers/layout";
import { CountryType, LangType } from "../../../../types/airgraft";
import { PanelTitle } from "../../PanelBase/styles";
import {
  CustomMobileMenuButton,
  MenuItemLink,
  MobileMenu,
  MobileMenuBackground,
  MobileMenuContent,
  MobileMenuInner,
  MobileMenuItemsList,
  Separator
} from "./styles";

export default function MobileCountryMenu() {
  const t = useTranslate();
  const unlocalizedPath = useUnlocalizedLocationPath();
  const currentLanguage = useLang();

  const currentCountry = useCountry();
  const { subcountry: selectedSubcountry, setSubcountry } = useSubcountry();
  const currentSubcountry =
    selectedSubcountry || getDefaultSubcountry(currentCountry);

  const toggleMobileNavigationMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileNavigationMenu
  );

  const isMobileCountryMenuOpen = useContextSelector(
    LayoutContext,
    c => c.isMobileCountryMenuOpen
  );
  const toggleMobileCountryMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileCountryMenu
  );

  const getMenuItemLinkTo = (country: CountryType, language: LangType) => {
    // Fallback to english if country doesnt have current language (Ex USA french)
    const targetLocale =
      getLocaleFromCountryLang(country, language) ||
      getLocaleFromCountryLang(country, "en");

    // Fallback to homepage if current page is disabled by feature flag in the other country
    if (isPageDisabledByFeatureFlags(country, unlocalizedPath)) {
      return getLocalizedPath(targetLocale, "/");
    }

    return getLocalizedPath(targetLocale, unlocalizedPath);
  };

  const handleSubcountryMenuItemClick = (
    country: CountryType,
    subcountry: string
  ) => {
    toggleMobileCountryMenu(false);
    toggleMobileNavigationMenu(false);
    setSubcountry(subcountry, country);
  };

  return (
    <MobileMenu>
      <MobileMenuInner className={isMobileCountryMenuOpen ? "open" : ""}>
        <MobileMenuBackground className={isMobileCountryMenuOpen ? "open" : ""}>
          <MobileMenuContent className={isMobileCountryMenuOpen ? "open" : ""}>
            <CustomMobileMenuButton
              onClick={toggleMobileCountryMenu}
              isOpen={isMobileCountryMenuOpen}
            />

            <MobileMenuItemsList>
              <Box mb={3.5}>
                <PanelTitle>{t("header.selectLocation")}</PanelTitle>
              </Box>

              {/* Countries */}
              {Object.keys(COUNTRIES).map((country: CountryType) => (
                <React.Fragment key={country}>
                  <Box mb={1.5}>
                    <Typography className="compact">
                      {t(`country.${country}`)}
                    </Typography>
                  </Box>

                  <Box mb={2}>
                    {getVisibleSubcountries(country).map((sc, index) => (
                      <React.Fragment key={sc.value}>
                        <MenuItemLink
                          to={getMenuItemLinkTo(country, currentLanguage)}
                          onClick={() =>
                            handleSubcountryMenuItemClick(country, sc.value)
                          }
                        >
                          {t(`subcountry.${sc.value.toUpperCase()}`)}
                          {currentCountry === country &&
                            currentSubcountry === sc.value && (
                              <CheckRoundedIcon />
                            )}
                        </MenuItemLink>
                        {index !==
                          getVisibleSubcountries(country).length - 1 && (
                          <Separator />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </React.Fragment>
              ))}
            </MobileMenuItemsList>
          </MobileMenuContent>
        </MobileMenuBackground>
      </MobileMenuInner>
    </MobileMenu>
  );
}
