import { Badge, styled } from "@material-ui/core";

export const MenuButtonWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  width: "38px",
  height: "32px",
  position: "relative",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  border: `${theme.palette.grey[300]} 1px solid`,
  borderRadius: "24px",
  transition: "background 200ms ease-in-out, border 200ms ease-in-out",
  transitionDelay: "200ms",
  "&.open": {
    justifyContent: "center",
    backgroundColor: "#F1B103",
    borderColor: "#DCA100",
    transitionDelay: "0ms"
  },
  "&.open > div": {
    width: "13px"
  },
  "&.open > div:first-child": {
    transform: "rotate(45deg) translateX(1px)",
    marginBottom: "0px"
  },
  "&.open > div:last-child": {
    marginTop: "0px",
    transform: "rotate(-45deg) translateX(1px)"
  }
}));

export const MenuButtonLine = styled("div")(({ theme }) => ({
  width: "16px",
  height: "2px",
  backgroundColor: theme.palette.text.primary,
  borderRadius: "2px",
  transition: theme.transitions.create(["width", "transform"], {
    duration: "300ms",
    delay: "0ms",
    easing: "ease"
  }),
  "&:first-child": {
    marginBottom: "4px"
  },
  "&:last-child": {
    width: "12px"
  }
}));

export const MobileMenuButtonBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-colorPrimary": {
    height: "10px",
    width: "10px",
    borderRadius: "10px",
    right: "4px",
    top: "4px",
    backgroundColor: theme.palette.primary.main
  }
}));
